// import react & packages
import React from 'react'
// import LogRocket from 'logrocket'

import { render } from 'react-dom'
import history from './history'
import { Provider } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'

// import redux store configuration
import configureStore from './settings/configureStore'

// import semantic-ui css framework
import '../lib/semantic/dist/semantic.css'

// import styling for react-select component
import '../node_modules/react-select/dist/react-select.css'

// import root component
import Root from './Root'
// import { VERSION } from '../../../../settings/_settings'

// require('logrocket-react')(LogRocket)

// configure sentry
if (process.env.SENTRY_DSN && process.env.SENTRY_DSN !== '') {
  const Raven = require('raven-js')

  Raven.config(process.env.SENTRY_DSN, {
    release: process.env.VERSION,
    environment: process.env.SENTRY_ENV,
  }).install()

  // configure logrocket
  // LogRocket.init('qaovro/jobeye')

  // integrate sentry with logrocket
  // Raven.setDataCallback(data =>
  // data.extra.sessionURL = LogRocket.sessionURL
  // data)
}

// create the redux store and sync it with react-router
// hashHistory is a singleton!
export const store = configureStore()
syncHistoryWithStore(history, store)

// wrap the root component in a redux provider and render it in #root div
render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
)
