import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
  BasicInput,
  BasicTextarea,
  BasicSubmit,
  CountrySelect,
  DividingHeader,
} from '../../../common/Helpers'
import classNames from 'classnames'
import { compose } from 'recompose'
import CSSModules from 'react-css-modules'
import { _isInvalid } from '../../../../utils'
import rest from '../../../../settings/rest'
import { FILE_URL } from '../../../../settings/_settings'
import Dropzone from 'react-dropzone'

import styles from './CompanyGeneral.scss'

const validation = require('validate.js')

const formName = 'accountCompanyGeneral'
const formFields = [
  'name',
  'url',
  'description',
  'street',
  'plz',
  'city',
  'country',
]

const validate = values =>
  validation(values, {
    name: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    url: {
      url: {
        message: '^formError.urlFormat',
      },
    },
    street: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    plz: {
      presence: {
        message: '^formError.fieldRequired',
      },
      numericality: {
        message: '^formError.plzFormat',
        onlyInteger: true,
        greaterThanOrEqualTo: 1000,
        lessThanOrEqualTo: 99999,
      },
    },
    city: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    country: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
  })

class CompanyGeneral extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  state = {
    file: null,
  }

  _uploadLogo = files => {
    const body = new FormData()
    body.append('file', files[0])

    this.setState({
      file: files[0],
    })
    this.props.dispatch(
      rest.actions.uploadLogo(
        {},
        {
          body,
        }
      )
    )
  }

  render() {
    const {
      displaySuccess,
      displayError,
      message,
      success,
      loading,
      fields,
      handleSubmit,
      fields: { name, url, street, plz, city, country },
    } = this.props
    const invalid = _isInvalid(fields)

    const logoSrc = this.state.file
      ? this.state.file.preview
      : `${FILE_URL}/img/logo-${this.props.company.id ||
          this.props.login.companyId}.png`

    return (
      <div className="ui clearing segment" styleName="container">
        <form
          className={classNames('ui form', {
            error: !loading && invalid,
            loading,
            success,
          })}
          onSubmit={handleSubmit}
        >
          <DividingHeader>
            <FormattedMessage id={`${formName}.string.title`} />
          </DividingHeader>
          {message && (
            <div className={classNames('ui message', { success })}>
              {message}
            </div>
          )}
          <div className="fields">
            <div className="twelve wide field">
              <BasicInput required formName={formName} reduxForm={name} />
              <br />
              <BasicInput
                common
                placeholder
                formName={formName}
                reduxForm={url}
              />
              <br />
              <BasicInput
                common
                required
                formName={formName}
                reduxForm={street}
              />
            </div>
            <div className="four wide field">
              <Dropzone
                onDrop={this._uploadLogo}
                className={styles.dropzone}
                activeClassName={styles.dropzoneActive}
              >
                <h4>Logo</h4>
                <img className="ui fluid image" alt="Logo" src={logoSrc} />
              </Dropzone>
            </div>
          </div>
          <div className="two fields">
            <BasicInput common required formName={formName} reduxForm={plz} />
            <BasicInput common required formName={formName} reduxForm={city} />
          </div>
          <CountrySelect required reduxForm={country} />
          {displaySuccess && !loading && (
            <div styleName="success">
              <i className="green checkmark icon" />{' '}
              <FormattedMessage id="accountCompanyGeneral.string.updateSuccess" />
            </div>
          )}
          {displayError && !loading && (
            <div styleName="error">
              <i className="red flag icon" />{' '}
              <FormattedMessage id="accountCompanyGeneral.string.updateError" />
            </div>
          )}
          <BasicSubmit
            primary
            floated="right"
            disabled={loading || invalid}
            loading={loading}
          />
        </form>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate,
    },
    state => ({
      login: state.global.login,
      company: state.account2.companyData.general.data,
      initialValues: {
        ...state.account2.companyData.general.data,
      },
    })
  ),
  injectIntl,
  CSSModules(styles)
)(CompanyGeneral)
