import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { thesesFilter } from '../../../actions'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { _translateOptions, _parseToIdArray } from '../../../utils'
import * as Settings from '../../../settings/_settings'

import {
  SidebarWrapper,
  Search,
  Section,
  SubscriptionButton,
  SelectSection,
} from '../../common/Helpers'

class Sidebar extends React.Component {
  _updateQuery = event => {
    const query = event.target.value

    this._filter({ ...this.props.theses.filters, query })
  }

  _updateFilters = (section, values) => {
    const filters = this.props.theses.filters
    filters[section] = _parseToIdArray(values)

    this._filter(filters)
  }

  _filter = (filters = this.props.theses.filters) => {
    this.props.dispatch(thesesFilter(filters))
  }

  render() {
    const { theses, intl } = this.props

    return (
      <SidebarWrapper>
        <Search
          value={theses.filters.query}
          updateQuery={this._updateQuery}
          intl={this.props.intl}
          intlId="sidebar.field.search.placeholder"
        />
        <SelectSection
          multi
          intlId="thesisTypes.name.plural"
          placeholderId="sidebar.string.selectPlaceholder"
          options={_translateOptions(
            intl,
            'thesisTypes',
            Settings.thesisTypeItems
          )}
          value={theses.filters.types}
          onChange={values => this._updateFilters('types', values)}
        />
        <SelectSection
          intlId="withJob.name.plural"
          placeholderId="sidebar.string.selectPlaceholder"
          options={[
            {
              value: '0',
              label: intl.formatMessage({ id: 'common.string.no' }),
            },
            {
              value: '1',
              label: intl.formatMessage({ id: 'common.string.yes' }),
            },
          ]}
          value={theses.filters.withJob}
          onChange={value =>
            this.props.dispatch(
              thesesFilter({
                ...this.props.theses.filters,
                withJob: value ? value.value : null,
              })
            )
          }
        />
        {/* <SubscriptionButton /> */}
      </SidebarWrapper>
    )
  }
}

export default compose(
  connect(state => ({
    theses: state.theses,
  })),
  injectIntl
)(Sidebar)
