import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './SegmentTitle.scss'

const SegmentTitle = ({ bordered, children }) => (
  <div styleName={bordered ? 'segment-title-bordered' : 'segment-title'}>
    {children}
  </div>
)

export default CSSModules(SegmentTitle, styles)
