import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

const TranslatedSelect = ({
  autoFocus, intl, className, options, reduxForm,
}) => (
  <select autoFocus={autoFocus} className={className} {...reduxForm}>
    {options.map((opt) => (
      <option key={opt.id} value={opt.id}>
        {intl.formatMessage({ id: opt.name })}
        {' '}
        {((opt.min || opt.min === 0)
          && opt.max
          && (opt.min === opt.max
            ? `(${opt.max}%)`
            : `(${opt.min}-${opt.max}%)`))
          || ''}
      </option>
    ))}
  </select>
)

TranslatedSelect.propTypes = {
  autoFocus: PropTypes.bool,
  type: PropTypes.string, // the type of the input field, defaults to text
}

TranslatedSelect.defaultProps = {
  autoFocus: false,
}

export default injectIntl(TranslatedSelect)
