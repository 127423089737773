import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import {
  BasicInput,
  BasicSelect,
  BasicSubmit,
  CountrySelect,
  DividingHeader,
} from '../../../common/Helpers'
import classNames from 'classnames'
import { compose } from 'recompose'
import { _isInvalid } from '../../../../utils'

import styles from './CompanyGeneral.scss'

const validation = require('validate.js')

const formName = 'accountCompanyInvoice'
const formFields = [
  'gender',
  'name',
  'email',
  'phone',
  'street',
  'plz',
  'city',
  'country',
]

const validate = values =>
  validation(values, {
    name: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    email: {
      presence: {
        message: '^formError.fieldRequired',
      },
      email: {
        message: '^formError.emailFormat',
      },
    },
    street: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    plz: {
      presence: {
        message: '^formError.fieldRequired',
      },
      numericality: {
        message: '^formError.plzFormat',
        onlyInteger: true,
        greaterThanOrEqualTo: 1000,
        lessThanOrEqualTo: 99999,
      },
    },
    city: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
    country: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
  })

class CompanyInvoice extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    type: PropTypes.string,
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  render() {
    const {
      displaySuccess,
      displayError,
      message,
      success,
      loading,
      fields,
      handleSubmit,
      fields: { gender, name, email, phone, street, plz, city, country },
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="ui clearing segment" styleName="container">
        <form
          className={classNames('ui form', {
            error: !loading && invalid,
            loading,
            success,
          })}
          onSubmit={handleSubmit}
        >
          <DividingHeader>
            <FormattedMessage id={`${formName}.string.title`} />
          </DividingHeader>
          {message && (
            <div className={classNames('ui message', { success })}>
              {message}
            </div>
          )}
          <div className="fields">
            <BasicSelect
              common
              required
              formName={formName}
              reduxForm={gender}
              options={['M', 'F']}
              width="five wide"
            />
            <BasicInput
              common
              required
              formName={formName}
              reduxForm={name}
              width="eleven wide"
            />
          </div>
          <BasicInput common required formName={formName} reduxForm={email} />
          <BasicInput common formName={formName} reduxForm={phone} />
          <BasicInput common required formName={formName} reduxForm={street} />
          <div className="two fields">
            <BasicInput common required formName={formName} reduxForm={plz} />
            <BasicInput common required formName={formName} reduxForm={city} />
          </div>
          <CountrySelect required reduxForm={country} />
          {displaySuccess && !loading && (
            <div styleName="success">
              <i className="green checkmark icon" />{' '}
              <FormattedMessage id="accountCompanyInvoice.string.updateSuccess" />
            </div>
          )}
          {displayError && !loading && (
            <div styleName="error">
              <i className="red flag icon" />{' '}
              <FormattedMessage id="accountCompanyInvoice.string.updateError" />
            </div>
          )}
          <BasicSubmit
            primary
            floated="right"
            disabled={loading || invalid}
            loading={loading}
          />
        </form>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate,
    },
    state => ({
      initialValues: {
        ...state.account2.companyData.general.data,
        ...state.account2.companyData.contact.data,
        ...state.account2.companyData.invoice.data,
      },
    })
  ),
  injectIntl,
  CSSModules(styles)
)(CompanyInvoice)
