import PropTypes from 'prop-types';
import React from 'react'
import Tag from './Tag'
import CSSModules from 'react-css-modules'

import styles from './Tags.scss'

const Tags = props => (
  <div className="ui equal width grid" styleName="tags">
    {props.tags.map((tag, id) => (
      <Tag key={id} text={tag.text} min={tag.min} max={tag.max} />
    ))}
  </div>
)

Tags.propTypes = {
  tags: PropTypes.array.isRequired, // the array of all tags
}

export default CSSModules(Tags, styles)
