import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'

const BasicInput = ({
  common,
  type = 'text',
  formName = 'form',
  fieldName,
  reduxForm,
  required,
  disabled,
  width,
  intl,
  placeholder,
}) => (
  <div
    className={classNames('field', width, { required, error: reduxForm.error })}
  >
    <label>
      <FormattedMessage
        id={`${(common && 'common') || formName}.field.${fieldName ||
          reduxForm.name}.label`}
      />
    </label>
    <input
      type={type}
      required={required}
      disabled={disabled}
      placeholder={intl.formatMessage({
        id: placeholder
          ? `${(common && 'common') || formName}.field.${
              reduxForm.name
            }.placeholder`
          : `${(common && 'common') || formName}.field.${reduxForm.name}.label`,
      })}
      {...reduxForm}
    />
    {reduxForm.error && (
      <div className="ui error message">
        {intl.formatMessage({ id: reduxForm.error })}
      </div>
    )}
  </div>
)

BasicInput.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  formName: PropTypes.string,
  reduxForm: PropTypes.object.isRequired,
  width: PropTypes.string,
}

export default injectIntl(BasicInput)
