import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'

import styles from './SidebarWrapper.scss'

const SidebarWrapper = ({ children }) => (
  <div className="ui basic segment" styleName="container">
    {children}
  </div>
)

export default CSSModules(SidebarWrapper, styles)
