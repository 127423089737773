import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import classNames from 'classnames'
import CSSModules from 'react-css-modules'
import { compose } from 'recompose'
import ReactGA from 'react-ga'
import rest from '../../../settings/rest'
import _map from 'lodash/map'
import _get from 'lodash/get'
import { BasicInput } from '../../common/Helpers'
import { _translateOptions, _isInvalid } from '../../../utils'
import * as Settings from '../../../settings/_settings'

import styles from './Subscriptions.scss'

const validation = require('validate.js')

const formName = 'editSubscription'
const formFields = [
  'name',
  'query',
  'categories',
  'education',
  'types',
  'min_rate',
  'max_rate',
]

const validate = values =>
  validation(values, {
    name: {
      length: {
        maximum: 250,
      },
    },
    query: {
      length: {
        maximum: 250,
      },
    },
    min_rate: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100,
      },
    },
    max_rate: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: 100,
      },
    },
  })

class Subscriptions extends React.Component {
  static propTypes = {}

  state = {
    activeSub: null,
    activeSubIndex: null,
    createMode: false,
    editMode: false,
  }

  componentWillMount() {
    // dynamically load content
    this.props.dispatch(rest.actions.userSubscriptions())
  }

  componentWillUnmount() {
    this.props.dispatch(rest.actions.subscription.reset())
  }

  _loadSub = (id, index) => {
    this.setState({
      activeSub: id,
      activeSubIndex: index,
      createMode: false,
      editMode: true,
    })
    this.props.dispatch(rest.actions.subscription.get({ id }))
  }

  _createMode = () => {
    this.props.dispatch(rest.actions.subscription.reset())
    this.setState({ activeSub: null, activeSubIndex: null, createMode: true })
  }

  _addSub = values => {
    ReactGA.event({
      category: 'Subscription',
      action: 'Created from Account',
    })
    this.props.dispatch(
      rest.actions.subscription.post(
        {},
        {
          body: JSON.stringify({
            ...values,
            categories: this._parseToIdArray(values.categories),
            education: this._parseToIdArray(values.education),
            types: this._parseToIdArray(values.types),
          }),
        }
      )
    )
  }

  _editSub = values => {
    this.props.dispatch(
      rest.actions.subscription.put(
        { id: this.state.activeSub },
        {
          body: JSON.stringify({
            ...values,
            categories: this._parseToIdArray(values.categories),
            education: this._parseToIdArray(values.education),
            types: this._parseToIdArray(values.types),
          }),
        }
      )
    )
  }

  _deleteSub = e => {
    e.preventDefault()

    ReactGA.event({
      category: 'Subscription',
      action: 'Deleted',
    })

    this.props.dispatch(
      rest.actions.subscription.delete({ id: this.state.activeSub })
    )
    this.setState({
      activeSub: null,
      activeSubIndex: null,
      createMode: false,
      editMode: false,
    })
  }

  _parseToIdArray = objectArray =>
    JSON.stringify(_map(objectArray, object => object.value || object))

  render() {
    const {
      subscriptions: { sync, loading, data },
      subscription,
      fields,
      fields: { name, query, categories, education, types, min_rate, max_rate },
      handleSubmit,
      intl,
    } = this.props

    const categoryOptions = _translateOptions(
      intl,
      'category',
      Settings.categoryItems
    )
    const educationOptions = _translateOptions(
      intl,
      'education',
      Settings.educationItems
    )
    const typeOptions = _translateOptions(intl, 'type', Settings.typeItems)

    const invalid = _isInvalid(fields)

    return (
      <div className="ui grid" styleName="container">
        <div className="stackable row">
          <div className="sixteen wide column">
            <div className="ui info message" styleName="description">
              <FormattedMessage id="accountSubs.string.description" />
            </div>
          </div>
        </div>
        <div className="stackable row">
          <div className="five wide column">
            <div className="ui top attached segment" styleName="titleSegment">
              <h2>
                <FormattedMessage id="accountSubs.string.title" />
              </h2>
            </div>
            <div
              className={classNames('ui attached segment', {
                loading,
              })}
              styleName="subscriptions"
            >
              <div className="ui divided list">
                {sync &&
                  data.map((sub, index) => (
                    <div
                      className="item"
                      styleName="subscription"
                      onClick={() => this._loadSub(sub.id, index)}
                    >
                      <div className="ui padded grid">
                        <div
                          className="row"
                          styleName={classNames({
                            activeSub: sub.id === this.state.activeSub,
                          })}
                        >
                          {sub.name ||
                            intl.formatMessage(
                              { id: 'accountSubs.string.defaultName' },
                              { id: index + 1 }
                            )}
                          <div className="one wide right floated column">
                            <i
                              aria-hidden
                              role="presentation"
                              className="write icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div
                  className="item"
                  styleName="subscription"
                  onClick={() => this._createMode()}
                >
                  <div className="ui padded grid">
                    <div
                      className="row"
                      styleName={classNames({
                        activeSub: this.state.createMode,
                      })}
                    >
                      <FormattedMessage id="accountSubs.string.newSub" />
                      <div className="one wide right floated column">
                        <i
                          aria-hidden
                          role="presentation"
                          className="plus icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="eleven wide column">
            <div className="ui top attached segment" styleName="titleSegment">
              <h2>
                {this.state.activeSubIndex !== null
                  ? intl.formatMessage(
                      { id: 'accountSubs.string.editSub' },
                      { id: this.state.activeSubIndex + 1 }
                    )
                  : this.state.createMode
                  ? intl.formatMessage({ id: 'accountSubs.string.newSub' })
                  : intl.formatMessage({ id: 'accountSubs.string.editor' })}
              </h2>
            </div>
            <div
              className={classNames('ui attached segment', {
                loading: subscription.loading,
              })}
            >
              {this.state.createMode || this.state.editMode ? (
                <form
                  className="ui form"
                  onSubmit={handleSubmit(
                    this.state.createMode ? this._addSub : this._editSub
                  )}
                >
                  <BasicInput reduxForm={name} formName="accountSubs" />
                  <BasicInput reduxForm={query} formName="accountSubs" />
                  <div className="ui field">
                    <label>
                      <FormattedMessage id="category.name.plural" />
                    </label>
                    <Select
                      multi
                      searchable={false}
                      placeholder={intl.formatMessage({
                        id: 'sidebar.string.selectPlaceholder',
                      })}
                      options={categoryOptions}
                      value={categories.value}
                      onChange={categories.onChange}
                    />
                  </div>
                  <div className="ui field">
                    <label>
                      <FormattedMessage id="education.name.plural" />
                    </label>
                    <Select
                      multi
                      searchable={false}
                      placeholder={intl.formatMessage({
                        id: 'sidebar.string.selectPlaceholder',
                      })}
                      options={educationOptions}
                      value={education.value}
                      onChange={education.onChange}
                    />
                  </div>
                  <div className="ui field">
                    <label>
                      <FormattedMessage id="type.name.plural" />
                    </label>
                    <Select
                      multi
                      searchable={false}
                      placeholder={intl.formatMessage({
                        id: 'sidebar.string.selectPlaceholder',
                      })}
                      options={typeOptions}
                      value={types.value}
                      onChange={types.onChange}
                    />
                  </div>
                  <div className="ui two fields">
                    <div
                      className={classNames('ui required field', {
                        error: min_rate.error,
                      })}
                    >
                      <label>
                        <FormattedMessage id="common.field.minRate.label" />
                      </label>
                      <input type="number" min="0" max="100" {...min_rate} />
                    </div>
                    <div
                      className={classNames('ui required field', {
                        error: max_rate.error,
                      })}
                    >
                      <label>
                        <FormattedMessage id="common.field.maxRate.label" />
                      </label>
                      <input type="number" min="0" max="100" {...max_rate} />
                    </div>
                  </div>
                  <button
                    className={classNames('ui secondary button', {
                      disabled: this.state.createMode,
                    })}
                    onClick={e => this._deleteSub(e)}
                  >
                    <i className="trash icon" />
                    <FormattedMessage id="common.button.delete" />
                  </button>
                  <button
                    type="submit"
                    disabled={invalid}
                    className="ui right floated primary button"
                  >
                    <FormattedMessage id="common.button.save" />
                  </button>
                </form>
              ) : (
                <span>
                  <FormattedMessage id="accountSubs.string.noSelection" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate,
    },
    state => ({
      // connect to redux state
      subscriptions: state.account2.userSubscriptions,
      subscription: state.subscription,
      initialValues: {
        min_rate: 0,
        max_rate: 100,
        ...state.subscription.data.data,
        categories:
          (_get(state, 'subscription.data.data.categories') &&
            JSON.parse(state.subscription.data.data.categories)) ||
          [],
        education:
          (_get(state, 'subscription.data.data.education') &&
            JSON.parse(state.subscription.data.data.education)) ||
          [],
        types:
          (_get(state, 'subscription.data.data.types') &&
            JSON.parse(state.subscription.data.data.types)) ||
          [],
      },
    })
  ),
  injectIntl,
  CSSModules(styles)
)(Subscriptions)
