export const UPDATE_RATES = 'UPDATE_RATES'
export const updateRates = rate => ({ type: UPDATE_RATES, rate })

export const JOBS_FILTER = 'JOBS_FILTER'
export const jobsFilter = filters => ({ type: JOBS_FILTER, filters })

export const JOBS_REQUEST = 'JOBS_REQUEST'
export const jobsRequest = () => ({ type: JOBS_REQUEST })

export const JOBS_RECEIVE = 'JOBS_RECEIVE'
export const jobsReceive = jobs => ({ type: JOBS_RECEIVE, jobs })

export const JOBS_PAGINATE = 'JOBS_PAGINATE'
export const jobsPaginate = ({ pageNumber }) => ({
  type: JOBS_PAGINATE,
  pageNumber,
})

export const JOB_CREATE = 'JOB_CREATE'
export const jobCreate = job => ({ type: JOB_CREATE, job })
