import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import CSSModules from 'react-css-modules'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import { BASE_URL, FILE_URL } from '../../../settings/_settings'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  SegmentBigHeader,
  InfoBar,
  InfoBarItem,
  InfoBarLocationElement,
  InfoBarContactElement,
  InfoBarDetailsElement,
  PDFDisplay,
  SocialButtons,
} from '../../common/Helpers'
import _get from 'lodash/get'
import InfoBarAdmin from '../../Jobs/JobManage/common/InfoBarAdmin'

import styles from './ThesisSingle.scss'

class ThesisSingle extends React.Component {
  static propTypes = {}

  componentWillMount() {
    const id = this.props.params.id

    if (id) {
      this.props.dispatch(rest.actions.thesis({ id }))
    }
  }

  _toggleActive = isActive => {
    const id = this.props.params.id

    if (id) {
      this.props.dispatch(
        rest.actions.thesis.put(
          { id },
          {
            body: JSON.stringify({
              active: !isActive,
            }),
          }
        )
      )
    }
  }

  render() {
    const {
      login,
      thesis: {
        loading,
        data: {
          id,
          title,
          contact,
          company,
          type,
          withJob,
          onlineFrom,
          onlineUntil,
          active,
          owner,
        },
      },
      intl,
    } = this.props

    const pageUrl = `${BASE_URL}/theses/${this.props.params.id}`

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        {owner && !active && (
          <div className="row" styleName="globalMessage">
            <div className="ui error message">
              <i className="flag icon" />
              {!active && (
                <FormattedMessage id="thesisSingle.string.deactivatedThesisPreview" />
              )}
            </div>
          </div>
        )}
        <SegmentBigHeader
          companyId={_get(company, 'id')}
          loading={loading}
          h1={title}
          h2={_get(company, 'name')}
          h2Link={`companies/${_get(company, 'id')}`}
        />
        <div className="reversed row">
          <PDFDisplay
            url={`pdf/thesis-${id}.pdf`}
            className="eleven wide computer only column"
            styleName="main"
          />
          <div
            className="five wide computer sixteen wide tablet sixteen wide mobile column"
            styleName="infobar"
          >
            <InfoBar sync>
              <InfoBarItem
                loading={loading}
                title="jobSingle.infobar.details.title"
              >
                <InfoBarDetailsElement
                  thesis
                  type={type === 'BA' ? 'bachelor' : 'master'}
                  withJob={withJob ? 'yes' : 'no'}
                  downloadLink={!loading && `${FILE_URL}/pdf/thesis-${id}.pdf`}
                />
              </InfoBarItem>
              <InfoBarItem
                loading={loading}
                title="common.infobar.contact.title"
              >
                <InfoBarContactElement
                  name={_get(contact, 'name')}
                  email={_get(contact, 'email')}
                  phone={_get(contact, 'phone')}
                />
              </InfoBarItem>
              {owner && (
                <InfoBarAdmin
                  id={id}
                  thesisMode
                  approved
                  admin={login.role === 'admin'}
                  onlineFrom={onlineFrom}
                  onlineUntil={onlineUntil}
                  active={active}
                  toggleActiveHandler={() => this._toggleActive(active)}
                  prolongHandler={() => console.log('prolong')}
                />
              )}
            </InfoBar>
            <SocialButtons
              url={pageUrl}
              title={title}
              description="Spannende Thesis auf Jobeye.ch!"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    login: state.global.login,
    thesis: state.thesis,
  })),
  injectIntl,
  CSSModules(styles)
)(ThesisSingle)
