import PropTypes from 'prop-types';
import React from 'react';
import { Link, IndexLink } from 'react-router'
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import LangChanger from './LangChanger'

import styles from './Nav.scss'

const Nav = props => (
  <div className="one column row" styleName="navWrapper">
    <div className="column" styleName="menuWrapper">
      <nav className="ui menu">
        <IndexLink className="item" activeClassName="active" to="/">
          <FormattedMessage id="nav.button.jobs" defaultMessage="Jobs" />
        </IndexLink>
        {/* <Link className="item" activeClassName="active" to="theses">
          <FormattedMessage id="nav.button.theses" defaultMessage="Theses" />
        </Link> */}
        <LangChanger locales={['DE', 'EN']} changeLocale={props.changeLocale} />
      </nav>
    </div>
  </div>
)

Nav.propTypes = {
  changeLocale: PropTypes.func.isRequired,
}

export default CSSModules(Nav, styles)
