import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl'
import Select from 'react-select'
import Section from './Section'

const SelectSection = ({
  intl,
  multi,
  intlId,
  placeholderId,
  options,
  value,
  onChange,
}) => (
  <Section intlId={intlId}>
    <Select
      multi={multi}
      placeholder={intl.formatMessage({ id: placeholderId })}
      options={options}
      value={value}
      onChange={onChange}
      searchable={false}
    />
  </Section>
)

SelectSection.propTypes = {
  intlId: PropTypes.string.isRequired,
  placeholderId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default injectIntl(SelectSection)
