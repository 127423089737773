import PropTypes from 'prop-types';
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
} from 'react-share'
import CSSModules from 'react-css-modules'

import styles from './SocialButtons.scss'

const SocialButtons = ({
  title = 'Jobeye - Stellenplattform des IBF',
  description = '',
  url,
}) => (
  <div className="ui icon buttons" styleName="container">
    <FacebookShareButton url={url} title={title} quote={description}>
      <FacebookIcon size={40} />
    </FacebookShareButton>
    <TwitterShareButton url={url} title={title}>
      <TwitterIcon size={40} />
    </TwitterShareButton>
    <GooglePlusShareButton url={url}>
      <GooglePlusIcon size={40} />
    </GooglePlusShareButton>
    <LinkedinShareButton url={url} title={title} description={description}>
      <LinkedinIcon size={40} />
    </LinkedinShareButton>
  </div>
)

SocialButtons.propTypes = {
  url: PropTypes.string.isRequired,
}

export default CSSModules(SocialButtons, styles)
