import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FILE_URL, categories } from '../../../settings/_settings'
import moment from 'moment'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import {
  InfoBar,
  TranslatedSelect,
  PDFDisplay,
  SegmentTitle,
} from '../../common/Helpers'
import { Tab, Icon } from 'semantic-ui-react'
import {
  InfoBarLocation,
  InfoBarContact,
  InfoBarSettings,
  TagInputs,
  validateDatesEdit,
} from './common'
import { _isInvalid } from '../../../utils'
import { setFile, resetFile } from '../../../actions'

import styles from './common/JobManage.scss'

const formName = 'jobEdit'
const formFields = [
  'title',
  'category',
  'education',
  'rate',
  'minRate',
  'maxRate',
  'type',
  'openFrom',
  'openFromDate',
  'contactGender',
  'contactName',
  'contactEmail',
  'contactPhone',
  'locationStreet',
  'locationPlz',
  'locationCity',
  'locationCountry',
  'onlineFrom',
  'onlineUntil',
]

class JobEdit extends React.Component {
  static propTypes = {}

  state = {
    preview: null,
    rate: {
      initialized: false,
      min: 0,
      max: 100,
    },
  }

  componentWillMount() {
    this.props.dispatch(
      rest.actions.companyJobEdit({ id: this.props.params.id })
    )
    this._updateRates([
      this.props.fields.minRate.value || 40,
      this.props.fields.maxRate.value || 60,
    ])
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.state.rate.initialized &&
      nextProps.fields.minRate &&
      nextProps.fields.maxRate
    ) {
      this.setState({
        rate: {
          initialized: true,
          min: nextProps.fields.minRate.value,
          max: nextProps.fields.maxRate.value,
        },
      })
      this._updateRates([
        nextProps.fields.minRate.value,
        nextProps.fields.maxRate.value,
      ])
    }
  }

  _updateJob = values => {
    const id = this.props.params.id

    if (id) {
      this.props.dispatch(
        rest.actions.job.put(
          { id },
          {
            body: JSON.stringify({
              category_id: values.category,
              education_id: values.education,
              rate: values.rate,
              min_rate: this.state.rate.min,
              max_rate: this.state.rate.max,
              type_id: values.type,
              title: values.title,
              online_until: moment(values.onlineUntil, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
              open_from:
                (values.openFrom === 'date'
                  ? moment(values.openFromDate, 'YYYY-MM-DD').format(
                      'YYYY-MM-DD'
                    )
                  : values.openFrom) || 'now',
              contact_gender: values.contactGender,
              contact_name: values.contactName,
              contact_email: values.contactEmail,
              contact_phone: values.contactPhone,
              contact_street: values.locationStreet,
              contact_plz: values.locationPlz,
              contact_city: values.locationCity,
              contact_country: values.locationCountry,
            }),
          }
        )
      )
    }
  }

  _updateRates = values => {
    const options = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

    const min = options.filter(option => option <= values[1])
    const max = options.filter(option => option >= values[0])

    const minOptions = min.map(option => (
      <option value={option}>{option} %</option>
    ))
    const maxOptions = max.map(option => (
      <option value={option}>{option} %</option>
    ))

    this.setState({
      rate: {
        min: values[0],
        max: values[1],
        minOptions,
        maxOptions,
      },
    })
  }

  onChangeFile = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onload = ({ target }) => {
      this.props.dispatch(setFile(file))
      this.setState({
        preview: target.result,
      })
    }

    reader.onerror = () => {
      console.error('Failure while loading PDF.')
    }

    reader.readAsDataURL(file)
  }

  render() {
    const {
      intl,
      job,
      company,
      fields,
      fields: {
        title,
        category,
        education,
        rate,
        minRate,
        maxRate,
        type,
        openFrom,
        openFromDate,
        contactGender,
        contactName,
        contactEmail,
        contactPhone,
        locationStreet,
        locationPlz,
        locationCity,
        locationCountry,
        onlineFrom,
        onlineUntil,
      },
      handleSubmit,
      submitting,
    } = this.props
    const invalid = _isInvalid(fields)

    const panes = [
      {
        menuItem: intl.formatMessage({
          id: 'jobEdit.string.currentPDF',
        }),
        render: () => (
          <Tab.Pane>
            <PDFDisplay url={job.sync && `pdf/job-${job.data.id}.pdf`} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: intl.formatMessage({
          id: 'jobEdit.string.replacePDF',
        }),
        render: () => (
          <Tab.Pane>
            <h1>
              {global.file ? (
                <div>
                  <FormattedMessage id="common.string.preview" />
                </div>
              ) : (
                <FormattedMessage id="common.string.pdfUpload" />
              )}
            </h1>
            <input type="file" onChange={this.onChangeFile} />
            {(() => {
              // if we are in IE, special handling
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return (
                  <div className="ui icon message">
                    <i className="internet explorer icon" />
                    <FormattedMessage
                      defaultMessage="Unable to render file previews in Internet Explorer."
                      id="jobManage.string.noIEpreview"
                    />
                  </div>
                )
              }

              // construct a message to be shown if preview is not possible
              const noPreviewMessage = (
                <FormattedMessage
                  defaultMessage="No file preview available."
                  id="jobManage.string.noPreview"
                />
              )

              // if the preview state is set, render it
              if (this.state.preview) {
                return (
                  <object data={this.state.preview} type="application/pdf">
                    <div className="ui message">{noPreviewMessage}</div>
                  </object>
                )
              }

              return <div className="ui message">{noPreviewMessage}</div>
            })()}
          </Tab.Pane>
        ),
      },
    ]

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <SegmentTitle>
          <h1>
            <FormattedMessage id="jobEdit.string.title" />
          </h1>
        </SegmentTitle>
        <div className="row ui segment" styleName="bigHeader">
          <img
            className="ui three wide computer four wide tablet sixteen wide mobile column middle aligned image"
            styleName="logo"
            src={
              company.sync
                ? `${FILE_URL}/img/logo-${this.props.global.login.companyId}.png`
                : ''
            }
          />
          <div
            className="thirteen wide computer twelve wide tablet sixteen wide mobile middle aligned column"
            styleName="mainContent"
          >
            <div>
              <TagInputs
                education={education}
                rate={rate}
                type={type}
                min={this.state.rate.min}
                max={this.state.rate.max}
              />
              <h1>
                <input
                  styleName="inputTitle"
                  className={classNames({ error: title.error })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'jobManage.field.jobTitle.placeholder',
                  })}
                  {...title}
                />
              </h1>
              <h2>{company.sync && company.data.company.name}</h2>
              <h3 styleName="selectCategory">
                <TranslatedSelect options={categories} reduxForm={category} />
              </h3>
              {rate.value == 2 && (
                <div className="ui segment" styleName="selectRates">
                  <h4 className="ui dividing header">
                    <FormattedMessage id="jobManage.field.rate.title" />
                  </h4>
                  <span className="ui labeled input" styleName="selectMinRate">
                    <label className="ui label">
                      <FormattedMessage id="jobManage.field.rate.min" />
                    </label>
                    <select
                      className={classNames('ui dropdown', {
                        disabled: !(rate.value == 2),
                      })}
                      disabled={!(rate.value == 2)}
                      {...minRate}
                    >
                      {this.state.rate.minOptions}
                    </select>
                  </span>
                  <span className="ui labeled input" styleName="selectMaxRate">
                    <label className="ui label">
                      <FormattedMessage id="jobManage.field.rate.max" />
                    </label>
                    <select
                      className={classNames('ui dropdown', {
                        disabled: !(rate.value == 2),
                      })}
                      disabled={!(rate.value == 2)}
                      {...maxRate}
                    >
                      {this.state.rate.maxOptions}
                    </select>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="eleven wide computer eleven wide tablet only column"
            styleName="pdf"
          >
            <Tab panes={panes} />
          </div>
          <div className="five wide computer five wide tablet sixteen wide mobile column">
            <InfoBar sync>
              <InfoBarContact
                contactGender={contactGender}
                contactName={contactName}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
              />
              <InfoBarLocation
                locationStreet={locationStreet}
                locationPlz={locationPlz}
                locationCity={locationCity}
                locationCountry={locationCountry}
              />
              <InfoBarSettings
                editMode
                onlineFrom={onlineFrom}
                onlineUntil={onlineUntil}
                openFrom={openFrom}
                openFromDate={openFromDate}
                submitting={submitting}
                invalid={invalid}
                fields={fields}
                handleSubmit={handleSubmit(this._updateJob)}
              />
            </InfoBar>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate: validateDatesEdit,
    },
    state => ({
      // connect to redux state
      company: state.company,
      global: state.global,
      job: state.companyJobEdit,
    })
  ),
  injectIntl,
  CSSModules(styles)
)(JobEdit)
