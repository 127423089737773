import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { compose } from 'recompose'
import styles from './LangChanger.scss'

class LangChanger extends React.Component {
  static propTypes = {
    locales: PropTypes.array.isRequired, // an array of supported languages, e.g. ['de', 'en']
    changeLocale: PropTypes.func.isRequired, // passed down method for changing the language
  }

  _changeLocale = newLocale => {
    // only dispatch if the language is not already active
    if (newLocale !== this.props.intl.locale) {
      this.props.changeLocale(newLocale)
    }
  }

  render() {
    const { locales } = this.props

    return (
      <div className="right menu" styleName="lang-changer">
        {locales.map(locale => (
          <a
            key={locale}
            className={classNames('item', {
              active: this.props.intl.locale === locale,
            })}
            onClick={() => this._changeLocale(locale)}
          >
            {locale.toUpperCase()}
          </a>
        ))}
      </div>
    )
  }
}

// injectIntl is not yet available as a decorator!
export default compose(injectIntl, CSSModules(styles))(LangChanger)
