import PropTypes from 'prop-types'
import React from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { resetGlobalMessage } from '../../../actions'

import styles from './GlobalMessage.scss'

const GlobalMessage = ({ message, type, resetGlobalMessage }) => (
  <div className="row" styleName="container">
    <div className={classNames('ui icon message', type)} styleName="message">
      <i
        styleName="infoIcon"
        className={classNames('icon', {
          info: !type || type === 'success',
          flag: type === 'error',
        })}
      />
      <i className="close icon" onClick={() => resetGlobalMessage()} />
      <div className="content">
        <div className="header">
          <FormattedMessage id={`globals.${type}`} />
        </div>
        <FormattedMessage id={message} />
      </div>
    </div>
  </div>
)

GlobalMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default CSSModules(GlobalMessage, styles)
