import React from 'react'
import CSSModules from 'react-css-modules'
import { Link } from 'react-router'
import { Card, Grid, Segment, List, Step, Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import { AttachedSegment, SegmentTitle } from '../common/Helpers'

import styles from './Landing.scss'

const Landing = () => (
  <div
    className="sixteen wide computer sixteen wide tablet sixteen wide mobile centered column"
    styleName="container"
  >
    <Grid container stackable>
      <Grid.Column width={11}>
        <AttachedSegment h1="Jobeye.ch">
          <p>
            Um Studierende, Absolvierende und Doktorierende der{' '}
            <strong>Universität Zürich</strong> im Bereich{' '}
            <strong>Finanz und Wirtschaft</strong> zu erreichen, können Sie auf
            Jobeye.ch Ihr Stellenangebot veröffentlichen. Dieses Stellenportal
            ist eine durch das Institut für Banking und Finance geführte
            Stellenplattform für Teilzeitjobs, Festanstellungen und Praktika.
          </p>
          <List bulleted relaxed>
            <List.Item>
              Jobeye.ch wird monatlich von rund 1'750 Studenten und Alumnis
              besucht
            </List.Item>
            <List.Item>
              Inserate im Bereich <strong>Banking und Finance</strong> sowie{' '}
              <strong>Betriebswirtschaftslehre</strong>
            </List.Item>
            <List.Item>
              Maximale Laufzeit des Inserates von 60 Tagen (Start- und Enddatum
              frei wählbar)
            </List.Item>
            <List.Item>
              Kostenlose Erstellung Ihres Unternehmensprofils
            </List.Item>
          </List>
        </AttachedSegment>
        <AttachedSegment h1="Wie funktioniert es?" className="landingSteps">
          <Step.Group fluid vertical>
            <Step>
              <Icon name="signup" />
              <Step.Content>
                <Step.Title>Registrierung</Step.Title>
                <Step.Description>
                  Wählen Sie <Link to="/users/register">Registrierung</Link>,
                  falls Ihre Firma noch kein{' '}
                  <Link to="/users/login">Login</Link> besitzt.
                </Step.Description>
              </Step.Content>
            </Step>
            <Step>
              <Icon name="pencil" />
              <Step.Content>
                <Step.Title>Firmenprofil erstellen</Step.Title>
                <Step.Description>
                  Erstellen Sie ein (optionales) Firmenprofil.
                </Step.Description>
              </Step.Content>
            </Step>
            <Step>
              <Icon name="file pdf outline" />
              <Step.Content>
                <Step.Title>Inserat erstellen</Step.Title>
                <Step.Description>
                  Erfassen Sie eine Jobausschreibung mit PDF-Anhang.
                </Step.Description>
              </Step.Content>
            </Step>
            <Step>
              <Icon name="checkmark" />
              <Step.Content>
                <Step.Title>Abschluss</Step.Title>
                <Step.Description>
                  Ihr Inserat wird von uns überprüft und zur gewünschten Zeit
                  aufgeschalten.
                </Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        </AttachedSegment>
      </Grid.Column>
      <Grid.Column width={5}>
        <Card fluid>
          <Card.Content header="Fragen? Wir helfen gerne." />
          <Card.Content>
            <Icon name="phone" />
            044 634 50 30 (Mo-Fr, 8-12/13-17 Uhr)
          </Card.Content>
          <Card.Content>
            <Icon name="mail" />
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>
          </Card.Content>
          <Card.Content description>
            <p>
              Bei Problemen und Fragen können Sie uns jederzeit kontaktieren.
            </p>
            <p>
              Weitere Informationen finden Sie unter{' '}
              <Link to="/about/for-companies">Für Firmen</Link> und in den{' '}
              <Link to="/about/faq">FAQ</Link>.
            </p>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  </div>
)

export default CSSModules(Landing, styles)
