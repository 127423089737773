export const _redirect = (redirect, id) => {
  switch (redirect) {
    case 'admin':
      return 'admin/jobs'
    case 'viewJob':
      return `/jobs/${id}`
    case 'viewThesis':
      return `/theses/${id}`
    case 'listJobs':
      return '/users/account/jobs'
    case 'listTheses':
      return '/users/account/theses'
    case 'createJob':
      return '/jobs/create'
    case 'prolongJob':
      return `/jobs/prolong/${id}`
    case 'createThesis':
      return '/theses/create'
    case 'editJob':
      return `/jobs/${id}/edit`
    case 'editThesis':
      return `/theses/${id}/edit`
    case 'editCompanyProfile':
      return '/users/account/profile'
    case 'editCompanyData':
      return '/users/account/company'
    case 'editSubs':
      return '/users/account/subscriptions'
    case 'editUserData':
      return '/users/account/personal'
    case 'intro':
      return '/welcome/intro'
    default:
      return '/'
  }
}
