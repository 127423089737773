import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { FILE_URL } from '../../../settings/_settings'

import styles from './SegmentBigHeader.scss'

const SegmentBigHeader = ({
  companyId,
  loading,
  h1 = 'Title',
  h2,
  h3,
  h4,
  description,
  h2Link,
  h2External,
}) => (
  <div className={classNames('row', { loading })} styleName="container">
    {!loading && (
      <div
        className="ui three wide computer four wide tablet sixteen wide mobile column"
        styleName="logoWrapper"
      >
        <img
          className="ui middle aligned image"
          styleName="logo"
          src={`${FILE_URL}/img/logo-${companyId}.png`}
        />
      </div>
    )}
    {!loading && (
      <div
        className="thirteen wide computer twelve wide tablet sixteen wide mobile bottom aligned column"
        styleName="mainContent"
      >
        <div>
          <h1>{h1}</h1>
          {h2 &&
            (h2Link ? (
              h2External ? (
                <h2>
                  <a href={h2Link} target="_blank">
                    {h2}
                  </a>
                </h2>
              ) : (
                <h2>
                  <Link to={h2Link}>{h2}</Link>
                </h2>
              )
            ) : (
              <h2>{h2}</h2>
            ))}
          {h3 && <h3>{h3}</h3>}
          {h4 && <h4>{h4}</h4>}
        </div>
      </div>
    )}
    {/*
         !loading
         &&
         description
         &&
         <p className="five wide computer sixteen wide tablet sixteen wide mobile right floated column">
            {description}
         </p>
         */}
  </div>
)

SegmentBigHeader.propTypes = {
  h1: PropTypes.string.isRequired, // the h1 title of the header
  h2: PropTypes.string, // the h2 title of the header
  h3: PropTypes.string, // the h3 title of the header
  tags: PropTypes.object, // related tags
  img: PropTypes.string, // a filename for a preview image
  description: PropTypes.string, // a short description
}

export default CSSModules(SegmentBigHeader, styles)
