import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'

const TranslatedInput = props => (
  <div
    className={classNames('field', props.className, {
      error: props.error,
      required: props.required,
    })}
  >
    <label>
      <FormattedMessage id={`${props.form}.field.${props.id}.label`} />
    </label>
    <input
      type={props.type || 'text'}
      placeholder={props.intl.formatMessage({
        id: `${props.form}.field.${props.id}.placeholder`,
        defaultMessage: props.intl.formatMessage({
          id: `${props.form}.field.${props.id}.label`,
        }),
      })}
      {...props}
    />
  </div>
)

TranslatedInput.propTypes = {
  type: PropTypes.string, // the type of the input field, defaults to text
  form: PropTypes.string.isRequired, // the unique identifier of the form
  id: PropTypes.string.isRequired, // the unique identifier of the field
}

export default injectIntl(TranslatedInput)
