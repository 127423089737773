// import redux & packages
import { compose, createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// import the root reducer
import rootReducer from '../reducers'

const middlewares = [thunkMiddleware]
// if (process.env.NODE_ENV !== 'production') {
//   const { logger } = require('redux-logger')
//   middlewares.push(logger)
// }

export default function configureStore(initialState) {
  // create the redux store
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      // dynamically insert devtools in development mode
      // (process.env.NODE_ENV !== 'production') && DevTools.instrument()
      process.env.NODE_ENV !== 'production' && window.devToolsExtension
        ? window.devToolsExtension()
        : f => f
    )
  )

  // allow hot replacement for redux reducers
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index').default
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
