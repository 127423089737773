import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'

import styles from './Listing.scss'

const Listing = ({ children, className, loading }) => (
  <div id="listing" className={className} styleName="container">
    {loading && (
      <div className="ui active indeterminate text loader">
        <FormattedMessage id="common.string.loadingResults" />
      </div>
    )}
    {!loading &&
      (children.length > 0 ? (
        children
      ) : (
        <div styleName="messageWrapper">
          <div className="ui icon info message" styleName="message">
            <i className="info icon" />
            <div className="content">
              <div className="header">
                <FormattedMessage id="common.string.noResultsFoundHeader" />
              </div>
              <p>
                <FormattedMessage id="common.string.noResultsFoundDescription" />
              </p>
            </div>
          </div>
        </div>
      ))}
  </div>
)

Listing.propTypes = {
  className: PropTypes.string,
}

export default CSSModules(Listing, styles)
