import PropTypes from 'prop-types'
import React from 'react'
import { InfoBarItem, LabelInput, LabelCheckbox } from '../../../common/Helpers'

const InfoBarAdminSettings = ({ companyId, immediately }) => (
  <InfoBarItem title="jobManage.infobar.adminSettings.title" loading={false}>
    <p>
      Mittels dieser Einstellungen können Jobs für beliebige Firmen/User
      erstellt werden.
    </p>
    <p>
      <LabelInput
        label="jobManage.field.companyId.label"
        reduxForm={companyId}
        type="number"
      />
    </p>
    <p>
      <LabelCheckbox
        id="jobManage.field.immediately.label"
        reduxForm={immediately}
      />
    </p>
  </InfoBarItem>
)

export default InfoBarAdminSettings
