import Raven from 'raven-js'
import ReactGA from 'react-ga'

export const _logException = (ex, context) => {
  Raven.captureException(ex, {
    extra: context,
  })

  if (process.env.NODE_ENV !== 'production') {
    window.console && console.error && console.error(ex)
  }
}

export const _logEvent = event => {
  // only log events in production environment
  if (process.env.NODE_ENV === 'production') {
    ReactGA.event(event)
  }
}
