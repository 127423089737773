import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import SegmentTitle from '../Helpers/SegmentTitle'

import styles from './VerticalMenu.scss'

const VerticalMenu = ({ title, links, children }) => (
  <div className="row">
    <div className="column">
      <div className="ui grid">
        <div className="row" styleName="title">
          <div className="column">
            <SegmentTitle>
              <h1>
                <FormattedMessage id={title} />
              </h1>
            </SegmentTitle>
          </div>
        </div>
        <div className="stackable row" styleName="content">
          <div className="three wide computer four wide tablet column">
            <div className="ui segment" styleName="menuWrapper">
              <div
                className="ui fluid vertical secondary menu"
                styleName="verticalMenu"
              >
                {links.map(item => item)}
              </div>
            </div>
          </div>
          <div className="thirteen wide computer twelve wide tablet stretched column">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
)

VerticalMenu.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.element).isRequired,
  children: PropTypes.element.isRequired,
}

export default CSSModules(VerticalMenu, styles)
