import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { object, string, number } from 'yup'
import { compose } from 'recompose'
import _some from 'lodash/some'
import { _validateSchema } from '../../../utils'
import { TranslatedInput, CountrySelect, TranslatedSelect } from '../../common/Helpers'

const Step4 = ({
  submitting,
  fields: {
    invoiceTitle,
    invoiceName,
    invoiceEmail,
    invoicePhone,
    invoiceStreet,
    invoicePlz,
    invoiceCity,
    invoiceCountry,
  },
  handleSubmit,
  _prev,
}) => (
  <form className="ui form" onSubmit={handleSubmit}>
    <h2 className="ui dividing header">
      <FormattedMessage id="userRegister.step4.title" />
    </h2>
    <div className="two fields">
      <div className="five wide required field">
        <label>
          <FormattedMessage id="common.select.gender.label" />
        </label>
        <TranslatedSelect
          autoFocus
          className="ui dropdown required"
          options={[
            { id: 'x', name: 'common.string.pleaseChoose' },
            { id: 'M', name: 'common.select.gender.male' },
            { id: 'F', name: 'common.select.gender.female' },
          ]}
          reduxForm={invoiceTitle}
        />
      </div>
      <TranslatedInput
        className="eleven wide"
        type="text"
        form="common"
        id="name"
        error={invoiceName.error}
        required
        {...invoiceName}
      />
    </div>
    <TranslatedInput
      type="email"
      form="common"
      id="email"
      error={invoiceEmail.error}
      required
      {...invoiceEmail}
    />
    <TranslatedInput
      type="text"
      form="common"
      id="phone"
      error={invoicePhone.error}
      {...invoicePhone}
    />
    <TranslatedInput
      type="text"
      form="common"
      id="street"
      error={invoiceStreet.error}
      required
      {...invoiceStreet}
    />
    <div className="fields">
      <TranslatedInput
        type="text"
        form="common"
        id="plz"
        className="six wide"
        error={invoicePlz.error}
        required
        {...invoicePlz}
      />
      <TranslatedInput
        type="text"
        form="common"
        id="city"
        className="ten wide"
        error={invoiceCity.error}
        required
        {...invoiceCity}
      />
    </div>
    <div className="required field">
      <CountrySelect reduxForm={invoiceCountry} />
    </div>
    <button
      className={classNames('ui button secondary floated', {
        loading: submitting,
      })}
      onClick={_prev}
      disabled={submitting}
    >
      <FormattedMessage id="common.button.prev" />
    </button>
    <button
      className={classNames('ui button primary right floated', {
        loading: submitting,
      })}
      type="submit"
      disabled={_some([
        submitting,
        invoiceTitle.invalid,
        invoiceName.invalid,
        invoiceEmail.invalid,
        invoiceStreet.invalid,
        invoiceCity.invalid,
        invoicePlz.invalid,
      ])}
    >
      <FormattedMessage id="common.button.submit" />
    </button>
  </form>
)

Step4.propTypes = {}

export default compose(
  reduxForm(
    {
      fields: [
        'email',
        'password',
        'language',
        'companyName',
        'companyUrl',
        'companyStreet',
        'companyCity',
        'companyPlz',
        'companyCountry',
        'contactTitle',
        'contactName',
        'contactPhone',
        'customInvoice',
        'invoiceTitle',
        'invoiceName',
        'invoiceEmail',
        'invoicePhone',
        'invoiceStreet',
        'invoicePlz',
        'invoiceCity',
        'invoiceCountry',
      ],
      form: 'signup',
      destroyOnUnmount: false,
      validate: _validateSchema(
        object().shape({
          invoiceTitle: string()
            .oneOf(['M', 'F'])
            .required(),
          invoiceName: string().required(),
          invoiceEmail: string()
            .email()
            .required(),
          invoiceStreet: string().required(),
          invoicePlz: number()
            .min(1000)
            .max(99999)
            .required(),
          invoicePhone: string(),
          invoiceCity: string().required(),
          invoiceCountry: string(),
        }),
      ),
    },
    (state) => ({
      initialValues: {
        email: state.form.signup.email.value,
        password: state.form.signup.password.value,
        language: state.form.signup.language.value,
        companyName: state.form.signup.companyName.value,
        companyUrl: state.form.signup.companyUrl.value,
        companyStreet: state.form.signup.companyStreet.value,
        companyCity: state.form.signup.companyCity.value,
        companyPlz: state.form.signup.companyPlz.value,
        companyCountry: state.form.signup.companyCountry.value,
        contactTitle: state.form.signup.contactTitle.value,
        contactName: state.form.signup.contactName.value,
        contactPhone: state.form.signup.contactPhone
          ? state.form.signup.contactPhone.value
          : '',
        customInvoice: state.form.signup.customInvoice.value,
        invoiceCountry: 'CH',
      },
    }),
  ),
)(Step4)
