import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames'

const AccountSegment = props => (
  <div className={classNames('ui clearing segment', { loading: !props.sync })}>
    <Formsy.Form className="ui form" onValidSubmit={props.update}>
      <h3 className="ui dividing header">{props.title}</h3>
      {props.children}
      <input
        className="ui button primary right floated"
        type="submit"
        value={props.buttonText}
      />
    </Formsy.Form>
  </div>
)

AccountSegment.propTypes = {
  sync: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
}

export default AccountSegment
