import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'
import classNames from 'classnames'

import styles from './AttachedSegment.scss'

const AttachedSegment = ({
  loading = false,
  h1,
  h2,
  column,
  children,
  className,
}) => (
  <div className={classNames({ 'ui column': column })} styleName="container">
    <div className="ui top attached header">
      {h1 && <h1>{h1}</h1>}
      {h2 && <h2>{h2}</h2>}
    </div>
    <div className={classNames('ui attached segment', { loading }, className)}>
      {children}
    </div>
  </div>
)

AttachedSegment.propTypes = {
  loading: PropTypes.bool, // whether the content of the segment is available
  h1: PropTypes.string, // the content of the h1 header if existing
  h2: PropTypes.string, // the content of the h2 header if existing
  column: PropTypes.bool, // whether the segment euqlas a single column of a grid
}

export default CSSModules(AttachedSegment, styles)
