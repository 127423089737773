import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { compose } from 'recompose'
import moment from 'moment'
import { Tr, Td } from 'reactable'
import { SegmentTitle, TableList } from '../../common/Helpers'
import rest from '../../../settings/rest'

class CompanyList extends React.Component {
  static propTypes = {}

  componentWillMount() {
    // dynamically load content
    this.props.dispatch(rest.actions.adminCompanies.sync())
  }

  setInvoiced = (companyId, invoiced) => {
    this.props.dispatch(
      rest.actions.company.put(
        { id: companyId },
        {
          body: JSON.stringify({
            invoiced,
          }),
        }
      )
    )
  }

  render() {
    const {
      companies: { sync, data },
    } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>Company overview</h2>
        </SegmentTitle>
        <TableList
          className="basic striped"
          isFetching={!sync}
          sortable={[
            'ID',
            'Name',
            'Job count',
            'Invoiced',
            'Created',
            'Modified',
          ]}
          filterable={['Name', 'Job count', 'Invoiced', 'Created', 'Modified']}
          csvColumns={[
            'Company name',
            'Associated account',
            'Invoice contact',
            'Invoice email',
            'Invoice phone',
            'Invoice street',
            'Invoice plz',
            'Invoice city',
            'Invoice country',
            'Job count',
            'Invoiced',
            'Created',
            'Modified',
          ]}
          csvData={
            sync &&
            data.success &&
            data.data.map(item => [
              item.name,
              item.user
                ? item.user.username
                : { username: 'ERROR', active: false },
              item.invoice_address
                ? `${item.invoice_gender === 'M' ? 'Herr' : 'Frau'} ${
                    item.invoice_name
                  }`
                : `${item.contact_gender === 'M' ? 'Herr' : 'Frau'} ${
                    item.contact_name
                  }`,
              item.invoice_address ? item.invoice_email : item.contact_email,
              item.invoice_address ? item.invoice_phone : item.contact_phone,
              item.invoice_address ? item.invoice_street : item.street,
              item.invoice_address ? item.invoice_plz : item.plz,
              item.invoice_address ? item.invoice_city : item.city,
              item.invoice_address ? item.invoice_country : item.country,
              item.jobCount,
              item.invoiced,
              item.created,
              item.modified,
            ])
          }
          defaultSort={{ column: 'ID', direction: 'desc' }}
          csvFilename="companies"
        >
          {sync &&
            data.success &&
            data.data.map(item => (
              <Tr key={item.id}>
                <Td column="ID" value={item.id}>
                  {item.id}
                </Td>
                <Td column="Name" value={item.name}>
                  <Link to={`companies/${item.id}`}>{item.name}</Link>
                </Td>
                <Td
                  column="Associated account"
                  value={item.user ? item.user.username : 'ERROR'}
                >
                  <span>
                    {item.user && item.user.active ? (
                      <i
                        className="green checkmark icon"
                        role="presentation"
                        aria-hidden
                      />
                    ) : (
                      <i
                        className="red remove icon"
                        role="presentation"
                        aria-hidden
                      />
                    )}
                    {item.user ? item.user.username : 'ERROR'}
                  </span>
                </Td>
                <Td column="Invoiced" value={item.invoiced}>
                  <input
                    type="checkbox"
                    className="ui checkbox"
                    checked={item.invoiced}
                    onChange={() => this.setInvoiced(item.id, !item.invoiced)}
                  />
                </Td>
                <Td column="Job count" value={item.jobCount}>
                  {item.jobCount}
                </Td>
                <Td column="Created" value={item.created}>
                  {moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Modified" value={item.modified}>
                  {moment(item.modified, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
              </Tr>
            ))}
        </TableList>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    companies: state.adminCompanies,
  }))
)(CompanyList)
