export const _generateCSV = rows => {
  const colDelim = ';'
  const rowDelim = '\r\n'

  let csvText = ''
  rows.forEach(row => {
    row.forEach(col => {
      csvText += col + colDelim
    })
    csvText += rowDelim
  })

  if (window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob(
      [decodeURIComponent(encodeURI(`%EF%BB%BF${csvText}`))],
      {
        type: 'text/csv;charset=utf-8;',
      }
    )
    navigator.msSaveBlob(blob, 'export.csv')
    return null
  }

  return `data:application/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(
    csvText
  )}`
}
