import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'

import styles from './InfoBar.scss'

const InfoBar = ({ children }) => (
  <div className="ui padded grid" styleName="container">
    {children}
  </div>
)

InfoBar.propTypes = {}

export default CSSModules(InfoBar, styles)
