import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import { FILE_URL } from '../../../settings/_settings'

import styles from './PDFDisplay.scss'

// TODO: display a message if there was no pdf attached
const PDFDisplay = ({ className, url }) => (
  <div className={className} styleName="container">
    {url && (
      <object data={`${FILE_URL}/${url}`} type="application/pdf">
        <a href={`${FILE_URL}/${url}`} target="_blank">
          <button className="ui fluid secondary icon button">
            <i className="download icon" />{' '}
            <FormattedMessage id="common.button.downloadPDF" />
          </button>
        </a>
      </object>
    )}
  </div>
)

PDFDisplay.propTypes = {
  url: PropTypes.string.isRequired, // the filename of the pdf
}

export default CSSModules(PDFDisplay, styles)
