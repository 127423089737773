import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'

import styles from './InfoBarItem.scss'

const InfoBarItem = ({ loading = true, title, children }) => (
  <div className="row ui card" styleName="container">
    <div className="content">
      <h2 className="header" styleName="title">
        <FormattedMessage id={title} />
      </h2>
      <div styleName="innerContent">
        {loading && <div className="ui active inline centered loader" />}
        {!loading && children}
      </div>
    </div>
  </div>
)

InfoBarItem.propTypes = {
  loading: PropTypes.bool, // whether the data is still being fetched
  title: PropTypes.string.isRequired, // the title of the card
}

export default CSSModules(InfoBarItem, styles)
