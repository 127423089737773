// import react & packages
import PropTypes from 'prop-types'
import React from 'react'
import { Router, applyRouterMiddleware } from 'react-router'
import { connect } from 'react-redux'
import { IntlProvider, addLocaleData } from 'react-intl'
import Cookies from 'js-cookie'
import { useScroll } from 'react-router-scroll'
import ReactGA from 'react-ga'

// import child routes
import * as Routes from './settings/routes'

// import react-intl messages and locale settings
import enIntl from 'react-intl/locale-data/en'
import deIntl from 'react-intl/locale-data/de'
import * as DE from './messages/de'
import * as EN from './messages/en'

import { store } from './index'
import history from './history'
import { loginReceive, changeLocale } from './actions'
import { _logException } from './utils'
import _get from 'lodash/get'

// import App component
import App from './components/App'

const messages = { DE, EN }

// load necessary locales into react-intl
addLocaleData(enIntl)
addLocaleData(deIntl)

// the root routes object including the home index route
const routes = [
  {
    path: '/',
    component: App,
    getIndexRoute(location, cb) {
      import('./components/Jobs/JobListing/JobListing')
        .then(module => {
          cb(null, { component: module.default })
        })
        .catch(err => {
          _logException(err, { location })
        })
    },
    childRoutes: [
      Routes.AboutRoute,
      Routes.AccountRoute,
      Routes.AdminRoute,
      Routes.LoginRoute,
      Routes.JobCreateRoute,
      Routes.JobRoute,
      Routes.JobEditRoute,
      Routes.JobProlongRoute,
      Routes.ThesisCreateRoute,
      Routes.ThesisEditRoute,
      Routes.ThesesRoute,
      Routes.ThesisRoute,
      Routes.RegisterAccountRoute,
      Routes.ResetPasswordRoute,
      Routes.ConfirmAccountRoute,
      Routes.CompanyRoute,
      Routes.CompaniesRoute,
      Routes.RequestPasswordRoute,
      Routes.FirstStepsRoute,
      Routes.LandingRoute,
    ],
    onEnter: (nextState, replace, cb) => {
      const queryToken = _get(nextState, 'location.query._token')
      if (queryToken) {
        Cookies.set('JWT', queryToken, {
          expires: new Date(Date.now() + 86400000),
        })
        location.replace(
          `${location.protocol}//${location.host}${location.pathname}`
        )
      }

      const token = Cookies.get('JWT')
      const locale = Cookies.get('locale')

      if (locale) {
        store.dispatch(changeLocale(locale))
      }

      if (token) {
        store.dispatch(loginReceive(token))
      }

      // callback to hand over to router
      cb()
    },
  },
]

ReactGA.initialize('UA-33258666-12', {
  debug: process.env.NODE_ENV !== 'production',
})
const logPageView = () => {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }
}

// create the root component
class Root extends React.Component {
  static propTypes = {
    global: PropTypes.shape({
      locale: PropTypes.string.isRequired, // 'en' or 'de', the currently set locale
    }).isRequired,
  }

  render() {
    // get the currently set locale from the global store
    const { locale } = this.props.global

    // wrap the router inside a react-intl provider and dynamically add devtools
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div>
          <Router
            history={history}
            routes={routes}
            onUpdate={logPageView}
            render={applyRouterMiddleware(
              useScroll(
                (prevRouterProps, { location }) =>
                  prevRouterProps &&
                  location.pathname !== prevRouterProps.location.pathname
              )
            )}
          />
        </div>
      </IntlProvider>
    )
  }
}

export default connect(state => ({
  global: state.global,
}))(Root)
