import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import _some from 'lodash/some'
import { object, string, boolean } from 'yup'
import { compose } from 'recompose'
import { TranslatedInput, TranslatedSelect } from '../../common/Helpers'
import { _validateSchema } from '../../../utils'

const Step3 = ({
  submitting,
  fields: {
    contactTitle, contactName, contactPhone, customInvoice,
  },
  handleSubmit,
  _next,
  _prev,
}) => (
  <form
    className="ui form"
    onSubmit={customInvoice.value ? _next : handleSubmit}
  >
    <h2 className="ui dividing header">
      <FormattedMessage id="userRegister.step3.title" />
    </h2>
    <div className="two fields">
      <div className="five wide required field">
        <label>
          <FormattedMessage id="common.select.gender.label" />
        </label>
        <TranslatedSelect
          autoFocus
          className="ui dropdown required"
          options={[
            { id: 'x', name: 'common.string.pleaseChoose' },
            { id: 'M', name: 'common.select.gender.male' },
            { id: 'F', name: 'common.select.gender.female' },
          ]}
          reduxForm={contactTitle}
        />
      </div>
      <TranslatedInput
        className="eleven wide"
        type="text"
        form="common"
        id="name"
        error={contactName.error}
        required
        {...contactName}
      />
    </div>
    <TranslatedInput
      type="text"
      form="common"
      id="phone"
      error={contactPhone.error}
      {...contactPhone}
    />
    <div className="field">
      <div className="ui checkbox toggle">
        <input type="checkbox" {...customInvoice} />
        <label>
          <FormattedMessage id="userRegister.step3.checkbox.customInvoice.label" />
        </label>
      </div>
    </div>

    <button
      className={classNames('ui button secondary floated', {
        loading: submitting,
      })}
      onClick={_prev}
      disabled={submitting}
    >
      <FormattedMessage id="common.button.prev" />
    </button>
    {customInvoice.value ? (
      <button
        className={classNames('ui button secondary right floated', {
          loading: submitting,
        })}
        type="submit"
        disabled={_some([
          submitting,
          contactTitle.invalid,
          contactName.invalid,
        ])}
      >
        <FormattedMessage id="common.button.next" />
      </button>
    ) : (
      <button
        className={classNames('ui button primary right floated', {
          loading: submitting,
        })}
        type="submit"
        disabled={_some([
          submitting,
          contactTitle.invalid,
          contactName.invalid,
        ])}
      >
        <FormattedMessage id="common.button.submit" />
      </button>
    )}
  </form>
)

Step3.propTypes = {}

export default compose(
  reduxForm({
    fields: [
      'email',
      'password',
      'language',
      'companyName',
      'companyUrl',
      'companyStreet',
      'companyCity',
      'companyPlz',
      'companyCountry',
      'contactTitle',
      'contactName',
      'contactPhone',
      'customInvoice',
    ],
    validate: _validateSchema(
      object().shape({
        contactTitle: string()
          .oneOf(['M', 'F'])
          .required(),
        contactName: string().required(),
        contactPhone: string(),
        customInvoice: boolean(),
      }),
    ),
    form: 'signup',
    destroyOnUnmount: false,
  }),
)(Step3)
