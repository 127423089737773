import Raven from 'raven-js'
// import LogRocket from 'logrocket'
import * as Actions from '../actions/global'
import Cookies from 'js-cookie'
import md5 from 'md5'
import { _decodeToken } from '../utils'

const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.LOGIN_RECEIVE:
      if (action.token) {
        // decode the token (we trust it implicitly, as the server will authenticate)
        // if it was tampered with, all api requests will fail
        const { aai, companyId, id, role, username } = _decodeToken(
          action.token
        )

        // set the sentry user context in order to get additional information
        // TODO: log with hashed ids
        if (
          process.env.SENTRY_DSN &&
          process.env.SENTRY_DSN !== '' &&
          process.env.NODE_ENV === 'production'
        ) {
          Raven.setUserContext({
            aai,
            companyId,
            id,
            role,
            username,
          })
          /* LogRocket.identify(id, {
            email: username,
            companyId,
            aai,
            role,
          }) */
        }

        return {
          token: action.token,
          aai,
          companyId,
          id,
          role,
          username,
        }
      }
      return state

    case Actions.LOGIN_RESET:
      if (
        process.env.SENTRY_DSN &&
        process.env.SENTRY_DSN !== '' &&
        process.env.NODE_ENV === 'production'
      ) {
        // reset the sentry user context on logout
        Raven.setUserContext()
      }

      Cookies.remove('JWT')
      location.reload()
      return {}

    case Actions.LOGIN_ERROR:
      return { success: false, message: action.message }

    default:
      return state
  }
}

const globalReducer = (
  state = {
    locale: 'DE',
    message: null,
    login: {},
    file: null,
    recaptcha: null,
  },
  action
) => {
  switch (action.type) {
    case Actions.CHANGE_LOCALE:
      return { ...state, locale: action.locale }

    case Actions.LOGIN_RECEIVE:
    case Actions.LOGIN_RESET:
    case Actions.LOGIN_ERROR:
      return { ...state, login: loginReducer(state.login, action) }

    case Actions.SET_GLOBAL_MESSAGE:
      return {
        ...state,
        message: { content: action.message, type: action.messageType },
      }
    case Actions.RESET_GLOBAL_MESSAGE:
      return { ...state, message: null }

    case Actions.SET_FILE:
      return { ...state, file: action.file }
    case Actions.RESET_FILE:
      return { ...state, file: null }

    case Actions.SET_RECAPTCHA_TOKEN:
      return { ...state, recaptcha: action.token }
    case Actions.RESET_RECAPTCHA_TOKEN:
      return { ...state, recaptcha: null }

    default:
      return state
  }
}

export default globalReducer
