import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Tags } from '../../Helpers'
import CSSModules from 'react-css-modules'
import { FILE_URL } from '../../../../settings/_settings'

import styles from './ListingItem.scss'

const ListingItem = ({
  companyId,
  title,
  titleLink,
  subtitle,
  subtitleLink,
  tags,
  barTags,
  date,
}) => (
  <div className="ui horizontally padded grid container" styleName="container">
    <div className="three wide computer tablet only column ui middle aligned centered image">
      <Link to={titleLink}>
        <img src={`${FILE_URL}/img/logo-${companyId}.png`} />
      </Link>
    </div>
    <div
      className="thirteen wide computer thirteen wide tablet sixteen wide mobile column"
      styleName="upper"
    >
      <div styleName="content">
        <div className="ui grid">
          <div className="ui three wide mobile only column middle aligned centered image">
            <img
              alt={`Logo ${subtitle}`}
              src={`${FILE_URL}/img/logo-${companyId}.png`}
            />
          </div>
          <div
            className="sixteen wide computer sixteen wide tablet thirteen wide mobile column"
            styleName={classNames({
              tagNew: moment(date, 'YYYY-MM-DD').isSame(moment(), 'day'),
            })}
          >
            {moment(date, 'YYYY-MM-DD').isSame(moment(), 'day') && (
              <div className="ui red right ribbon label">
                <FormattedMessage id="common.string.new" />
              </div>
            )}
            <h1>
              <Link to={titleLink}>{title}</Link>
            </h1>
            <h2>
              <Link to={subtitleLink}>{subtitle}</Link>
            </h2>
          </div>
        </div>
      </div>
      <div className="ui stackable grid" styleName="tags">
        <div className="right floated ten wide computer fourteen wide tablet column">
          {tags && <Tags tags={tags} />}
        </div>
      </div>
      <div className="ui doubling grid" styleName="bar">
        <div className="seven wide column" styleName="barTags">
          {barTags}
        </div>
        <div className="two wide column" />
        <div className="seven wide column" styleName="date">
          {moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
        </div>
      </div>
    </div>
  </div>
)

ListingItem.propTypes = {}

export default CSSModules(ListingItem, styles)
