import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import { jobsFilter, updateRates } from '../../../actions'
import { _translateOptions, _parseToIdArray } from '../../../utils'
import * as Settings from '../../../settings/_settings'

import {
  SidebarWrapper,
  Search,
  Section,
  SliderSection,
  SubscriptionButton,
  SelectSection,
} from '../../common/Helpers'

class Sidebar extends React.Component {
  componentWillMount() {
    this.props.dispatch(rest.actions.subscriptionFromListing.reset())
  }

  _updateRates = values => {
    this.props.dispatch(updateRates({ min: values[0], max: values[1] }))
  }

  _updateQuery = event => {
    const query = event.target.value

    this._filter({ ...this.props.jobs.filters, query })
  }

  _updateFilters = (section, values) => {
    const filters = this.props.jobs.filters
    filters[section] = _parseToIdArray(values)

    this._filter(filters)
  }

  _addSub = e => {
    e.preventDefault()

    if (this.props.login.role === 'admin' || this.props.login.role === 'user') {
      this.props.dispatch(
        rest.actions.subscriptionFromListing(
          {},
          {
            body: JSON.stringify({
              query: this.props.jobs.filters.query,
              min_rate: this.props.jobs.filters.rate.min,
              max_rate: this.props.jobs.filters.rate.max,
              categories: JSON.stringify(this.props.jobs.filters.categories),
              education: JSON.stringify(this.props.jobs.filters.education),
              types: JSON.stringify(this.props.jobs.filters.types),
            }),
          }
        )
      )
    }
  }

  _filter = (filters = this.props.jobs.filters) => {
    this.props.dispatch(jobsFilter(filters))
  }

  render() {
    const { intl, jobs, login, subscription } = this.props

    return (
      <SidebarWrapper>
        <Search
          value={jobs.filters.query}
          updateQuery={this._updateQuery}
          intlId="sidebar.field.search.placeholder"
        />
        <SliderSection
          intlId="rate.name.plural"
          min={jobs.filters.rate.min}
          max={jobs.filters.rate.max}
          onChange={this._updateRates}
          onAfterChange={() => this._filter()}
        />
        <SelectSection
          multi
          intlId="category.name.plural"
          placeholderId="sidebar.string.selectPlaceholder"
          options={_translateOptions(intl, 'category', Settings.categoryItems)}
          value={jobs.filters.categories}
          onChange={values => this._updateFilters('categories', values)}
        />
        <SelectSection
          multi
          intlId="education.name.plural"
          placeholderId="sidebar.string.selectPlaceholder"
          options={_translateOptions(
            intl,
            'education',
            Settings.educationItems
          )}
          value={jobs.filters.education}
          onChange={values => this._updateFilters('education', values)}
        />
        <SelectSection
          multi
          intlId="type.name.plural"
          placeholderId="sidebar.string.selectPlaceholder"
          options={_translateOptions(intl, 'type', Settings.typeItems)}
          value={jobs.filters.types}
          onChange={values => this._updateFilters('types', values)}
        />
        <Section intlId="sidebar.string.subscriptionTitle" intl={intl}>
          <SubscriptionButton
            intl={intl}
            disabled={!login.role}
            loading={subscription.loading}
            data={subscription.data}
            onClick={e => this._addSub(e)}
          />
        </Section>
      </SidebarWrapper>
    )
  }
}

export default compose(
  connect(state => ({
    login: state.global.login,
    jobs: state.jobs,
    subscription: state.subscriptionFromListing,
  })),
  injectIntl
)(Sidebar)
