export const _dateToFieldFormat = date => {
  const year = date.getFullYear()
  const month =
    date.getMonth() < 9 ? '0'.concat(date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0'.concat(date.getDate()) : date.getDate()

  return ''
    .concat(year)
    .concat('-')
    .concat(month)
    .concat('-')
    .concat(day)
}
