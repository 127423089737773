import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'recompose'
import { Link } from 'react-router'
import { Tr, Td } from 'reactable'
import { SegmentTitle, TableList } from '../../common/Helpers'
import rest from '../../../settings/rest'

class UserList extends React.Component {
  static propTypes = {}

  componentWillMount() {
    // dynamically load content
    this.props.dispatch(rest.actions.users.sync())
  }

  render() {
    const {
      users: { sync, data },
    } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>User overview</h2>
        </SegmentTitle>
        <TableList
          className="basic striped"
          isFetching={!sync}
          sortable={['ID', 'Username', 'Role', 'Active', 'Created', 'Modified']}
          filterable={['Username', 'Role', 'Active', 'Created', 'Modified']}
          defaultSort={{ column: 'ID', direction: 'desc' }}
        >
          {sync &&
            data.map(item => (
              <Tr key={item.id}>
                <Td column="ID" value={item.id}>
                  {item.id}
                </Td>
                <Td column="Username" value={item.username}>
                  {item.username}
                </Td>
                <Td column="Role" value={item.role}>
                  {item.role === 'admin' ||
                  item.role === 'privileged' ||
                  item.role === 'company' ? (
                    <Link to={`companies/${item.companyId}`}>
                      {item.role} (Company #{item.companyId})
                    </Link>
                  ) : (
                    item.role
                  )}
                </Td>
                <Td column="Active" value={item.active}>
                  {item.active ? (
                    <i
                      className="green checkmark icon"
                      role="presentation"
                      aria-hidden
                    />
                  ) : (
                    <i
                      className="red remove icon"
                      role="presentation"
                      aria-hidden
                    />
                  )}
                </Td>
                <Td column="Created" value={item.created}>
                  {moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Modified" value={item.modified}>
                  {moment(item.modified, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
              </Tr>
            ))}
        </TableList>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    users: state.users,
  }))
)(UserList)
