import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  InfoBarItem,
  LabelSelect,
  TranslatedSelect,
  LabelInput,
} from '../../../common/Helpers'

const InfoBarContact = ({
  contactGender,
  contactName,
  contactEmail,
  contactPhone,
}) => (
  <InfoBarItem loading={false} title="common.infobar.contact.title">
    <div className="ui info message">
      <i className="info icon" />
      <FormattedMessage id="jobManage.string.publiclyVisible" />
    </div>
    <p>
      <LabelSelect label="common.select.gender.label">
        <TranslatedSelect
          options={[
            {
              name: 'common.select.gender.male',
              id: 'M',
            },
            {
              name: 'common.select.gender.female',
              id: 'F',
            },
          ]}
          reduxForm={contactGender}
        />
      </LabelSelect>
    </p>
    <p>
      <LabelInput label="common.field.name.label" reduxForm={contactName} />
    </p>
    <p>
      <LabelInput label="common.field.email.label" reduxForm={contactEmail} />
    </p>
    <p>
      <LabelInput label="common.field.phone.label" reduxForm={contactPhone} />
    </p>
  </InfoBarItem>
)

InfoBarContact.propTypes = {
  contactGender: PropTypes.object.isRequired,
  contactName: PropTypes.object.isRequired,
  contactEmail: PropTypes.object.isRequired,
  contactPhone: PropTypes.object.isRequired,
}

export default InfoBarContact
