import Cookies from 'js-cookie'

import history from '../history'

export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const changeLocale = locale => ({ type: CHANGE_LOCALE, locale })

export const SET_GLOBAL_MESSAGE = 'SET_GLOBAL_MESSAGE'
export const setGlobalMessage = (message, messageType) => ({
  type: SET_GLOBAL_MESSAGE,
  message,
  messageType,
})

export const RESET_GLOBAL_MESSAGE = 'RESET_GLOBAL_MESSAGE'
export const resetGlobalMessage = () => ({ type: RESET_GLOBAL_MESSAGE })

export const SET_FILE = 'SET_FILE'
export const setFile = file => ({ type: SET_FILE, file })

export const RESET_FILE = 'RESET_FILE'
export const resetFile = () => ({ type: RESET_FILE })

export const LOGIN_RECEIVE = 'LOGIN_RECEIVE'
export const loginReceive = token => ({ type: LOGIN_RECEIVE, token })

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const loginError = message => ({ type: LOGIN_ERROR, message })

export const LOGIN_RESET = 'LOGIN_RESET'
export const loginReset = () => ({ type: LOGIN_RESET })

export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN'
export const setRecaptchaToken = token => ({ type: SET_RECAPTCHA_TOKEN, token })

export const RESET_RECAPTCHA_TOKEN = 'RESET_RECAPTCHA_TOKEN'
export const resetRecaptchaToken = () => ({ type: RESET_RECAPTCHA_TOKEN })

export const setLocale = newLocale => dispatch => {
  if (Cookies.get('locale') !== newLocale) {
    Cookies.set('locale', newLocale)

    // only reload the page if we are on one of the big text content pages
    if (location.href.indexOf('about') !== -1) {
      location.reload()
    }
  }

  dispatch(changeLocale(newLocale))
}

export const logout = () => dispatch => {
  // remove the token from global state
  dispatch(loginReset())

  // we set the cookie to an expiration of "right now"
  Cookies.remove('JWT')

  // redirect to homepage
  history.push('/')
}
