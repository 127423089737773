import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'

import styles from './DividingHeader.scss'

const DividingHeader = ({ children }) => (
  <h3 className="ui dividing header" styleName="header">
    {children}
  </h3>
)

DividingHeader.propTypes = {}

export default CSSModules(DividingHeader, styles)
