import PropTypes from 'prop-types'
import React from 'react'
import UserArea from './UserArea'
import { compose } from 'recompose'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import { BASE_URL } from '../../../settings/_settings'

import styles from './Header.scss'

class Header extends React.Component {
  static propTypes = {
    loginData: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  }

  static contextTypes = {
    toggleSlideout: PropTypes.func,
  }

  render() {
    const { companyData, loginData, logout } = this.props

    return (
      <header className="padded row">
        <div className="three wide mobile only column" styleName="slideoutWrap">
          <button
            className="ui basic icon button"
            onClick={() => this.context.toggleSlideout()}
            styleName="slideoutButton"
          >
            <i className="sidebar icon" />
          </button>
        </div>
        <div className="eight wide computer eight wide tablet thirteen wide bottom aligned mobile column">
          <div className="ui image">
            <Link to="/">
              <img src={`${BASE_URL}/jobeye-logo-cut.png`} alt="Jobeye Logo" />
            </Link>
          </div>
        </div>
        <div
          className="eight wide computer eight wide tablet only column"
          styleName="userArea"
        >
          <UserArea
            companyData={companyData}
            loginData={loginData}
            logout={logout}
          />
        </div>
      </header>
    )
  }
}

export default compose(CSSModules(styles))(Header)
