import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const FormMessage = ({ name, messageType }) => {
  const error = messageType === 'error'
  const success = messageType === 'success'

  return (
    <div className={classNames('ui message', { error, success })}>
      <FormattedMessage
        id={`message.${classNames({ error, success })}.${name.toLowerCase()}`}
        defaultMessage={name}
        description="B"
      />
    </div>
  )
}

FormMessage.propTypes = {
  success: PropTypes.bool, // if it is a success message
  error: PropTypes.bool, // if it is an error message
  name: PropTypes.string.isRequired, // the unique name of the message
  messageType: PropTypes.string, // the type of the message, either error, success or '' for neutral
}

export default FormMessage
