import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import { object, string, ref } from 'yup'
import { compose } from 'recompose'
import rest from '../../settings/rest'
import { TranslatedInput } from '../../components/common/Helpers'
import { CenteredSegment } from '../../components/common/Layouts'
import { _decodeToken } from '../../utils/jwt'
import _get from 'lodash/get'
import { _validateSchema } from '../../utils'
import _some from 'lodash/some'

class UserReset extends React.Component {
  static propTypes = {}
  state = {
    validToken: false,
  }

  componentWillMount = () => {
    const {
      query: { resetToken },
    } = this.props.location

    // if a resetToken query param is set, check if it is a valid jwt
    if (resetToken) {
      const jwt = _decodeToken(resetToken)

      // if it is valid, save to state
      if (jwt) {
        this.setState({ jwt, resetToken, validToken: true })
      }
    }
  }

  _submit = (values, dispatch) => {
    // submit the newly set password
    dispatch(
      rest.actions.resetPassword(
        { id: this.state.jwt.id, _token: this.state.resetToken },
        {
          body: JSON.stringify({
            password: values.password,
          }),
        }
      )
    )
  }

  render() {
    const {
      intl,
      resetPassword,
      handleSubmit,
      submitting,
      fields: { password, passwordRepeat },
    } = this.props

    const sync = resetPassword.sync
    const success = _get(resetPassword, 'data.success')

    const expiredToken =
      _get(resetPassword, 'error.message') === 'Expired token'

    return (
      <CenteredSegment
        title={intl.formatMessage({ id: 'resetPassword.string.title' })}
        success={
          !expiredToken && this.state.validToken && !sync ? 'success' : 'error'
        }
        message={
          !expiredToken && this.state.validToken && !sync
            ? intl.formatMessage({
                id: 'resetPassword.string.resetting',
                values: { username: this.state.jwt.username },
              })
            : expiredToken
            ? intl.formatMessage({ id: 'resetPassword.string.expiredToken' })
            : intl.formatMessage({ id: 'resetPassword.string.expiredToken' })
        }
      >
        {!success && !expiredToken && this.state.validToken && (
          <form className="ui form" onSubmit={handleSubmit(this._submit)}>
            <TranslatedInput
              autoFocus
              type="password"
              form="common"
              id="password"
              error={password.error}
              {...password}
            />
            <TranslatedInput
              type="password"
              form="common"
              id="passwordRepeat"
              error={passwordRepeat.error}
              {...passwordRepeat}
            />
            <button
              className={classNames('ui button primary right floated', {
                loading: submitting,
              })}
              type="submit"
              disabled={_some([
                submitting,
                password.invalid,
                passwordRepeat.invalid,
              ])}
            >
              <FormattedMessage id="common.button.submit" />
            </button>
          </form>
        )}
        {success && (
          <div className="ui success message">
            <FormattedMessage id="resetPassword.string.success" />
          </div>
        )}
      </CenteredSegment>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: 'resetPassword',
      fields: ['password', 'passwordRepeat'],
      validate: _validateSchema(
        object().shape({
          password: string().required(),
          passwordRepeat: string()
            .oneOf([ref('password'), null])
            .required(),
        })
      ),
    },
    state => ({
      resetPassword: state.resetPassword,
    })
  ),
  injectIntl
)(UserReset)
