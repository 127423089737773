import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import Imprint from './Imprint'

import styles from './About.scss'

const FAQ = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>FAQ</h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="Was verbirgt sich genau hinter jobeye.ch?">
          <p>
            Jobeye.ch ist eine durch das Institut für Banking und Finance der
            Universität Zürich geführte Stellenplattform mit dem Zweck der
            Zusammenführung von hochqualifizierten Studierenden und Absolventen
            der Richtungen Banking und Finance und BWL der Uni Zürich mit den
            führenden Unternehmen aus Finanz und Wirtschaft. Zusätzlich fungiert
            jobeye.ch neben der Stellenplattform auch als Brücke zwischen
            Studierenden und Unternehmen in der Vermittlung von praxisnahen
            Abschlussarbeiten (Bachelor- oder Masterarbeiten).
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Welche Vorzüge bietet jobeye.ch gegenüber anderen Stellenplattformen?">
          <p>
            Durch das Institut für Banking und Finance der Universität Zürich
            und mit der Unterstützung einer Vielzahl von Professoren sowie
            weiterer universitärer Institute und Verbindungen verfügt jobeye.ch
            über einen direkten Kontakt zu einer breiten Studierenden- und
            Absolventenbasis, insbesondere im Bereich Banking und Finance sowie
            der Betriebswirtschaftslehre. Durch die unterschiedlichen
            Distributionsvarianten (Online-Inserat, physischer Aushang)
            erreichen Sie mit Ihrem Stelleninserat oder Ihrer
            Abschlussarbeitsausschreibung ein hochkompetentes, Ihren
            Bedürfnissen entsprechendes Zielpublikum.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Wie kann ich ein Inserat auf jobeye.ch publizieren?">
          <p>
            Die Publikation eines Stelleninserates wird über das Menü
            “Inserieren” vorgenommen. Falls Sie noch über kein Benutzerprofil
            verfügen, müssen Sie ein solches zuerst anlegen. Durch eine
            anschliessende Authentifizierung mit Ihrem Benutzernamen sowie
            Passwort können Sie die benötigen Informationen zur
            Stellenausschreibung eingeben und das PDF anfügen.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Wie lange dauert es, bis das Inserat publiziert wird?">
          <p>
            Nachdem Sie das Inserat online erfasst haben, wird es durch uns
            kontrolliert und werktags innerhalb 24 Stunden aufgeschaltet. Sie
            bekommen eine Bestätigung, sobald das Inserat auf der jobeye.ch
            Website sichtbar ist.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Wie lange wird das Inserat publiziert?">
          <p>
            Das Inserat wird für den gegebenen Preis für max. 60 Tage (Anfangs-
            und Enddatum frei wählbar) publiziert. Das Inserat kann aber
            jederzeit modifiziert, vorzeitig gelöscht resp. abgehängt werden
            oder verlängert werden (ggf. entfallen dann erneute Gebühren). In
            jedem Fall erhalten Sie zwei Tage vor Ablauf des von Ihnen
            eingegebenen Enddatums eine E-Mail von uns mit der Option zur
            Verlängerung.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Welche Publikationsformen bietet jobeye.ch an?">
          <p>
            Jobeye.ch bietet Ihnen folgende Publikationsmöglichkeiten für Ihr
            Stelleninserat an:
          </p>
          <table className="ui collapsing celled table">
            <thead>
              <tr>
                <th>Online-Publikation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right aligned">CHF 200</td>
              </tr>
            </tbody>
          </table>
          <p>
            Alle Preise in CHF, exkl. MwSt. Einen detaillierten Überblick über
            unser Service-Angebot erhalten Sie{' '}
            <Link to="about/for-companies">hier</Link>.
          </p>
          <p>
            Falls bei der Publikation Ihres Inserates Probleme oder Fragen
            auftauchen sollten, stehen wir ihnen selbstverständlich gerne
            telefonisch (+41 44 634 50 30) oder per{' '}
            <a href="mailto:jobeye@df.uzh.ch">E-Mail</a>
            zur Verfügung.
          </p>
        </AttachedSegment>

        <AttachedSegment h2='Was verbirgt sich hinter der Funktion "Suchabo erstellen"?'>
          <p>
            Falls Sie sich nur für Stellenausschreibungen aufgrund spezifischer
            Suchkriterien interessieren, können Sie diese Auswahl auch in einem
            Suchabo speichern. Dadurch erhalten Sie von jobeye.ch sogleich eine
            E-Mail Benachrichtigung, wenn ein neues Stelleninserat Ihren
            definierten Suchkriterien entspricht. Für das Erstellen eines
            Suchabos ist eine Registierung nötig.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Wie kann ich eine Abschlussarbeit auf jobeye.ch veröffentlichen?">
          Jobeye.ch fungiert neben der Stellenplattform auch als Brücke zwischen
          Studierenden und Unternehmen in der Vermittlung von praxisnahen
          Abschlussarbeiten (Bachelor- oder Masterarbeiten). Falls Sie als
          Unternehmen an einer Zusammenarbeit interessiert sind, nehmen Sie
          bitte ungeniert telefonisch (+41 44 634 50 30) oder per E-Mail (
          <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) mit dem
          Jobeye.ch-Team Kontakt auf. Wir können Ihnen bezüglich einer möglichen
          Ausarbeitung des Themas, dem Vermitteln von interessierten
          Studierenden sowie der Zusammenarbeit mit den universitären
          Lehrstühlen gerne weiterhelfen. Preise auf Anfrage.
        </AttachedSegment>

        <AttachedSegment h2="Was passiert mit dem Erlös von jobeye.ch?">
          <p>
            Jobeye.ch hat sich zum Ziel gesetzt, mit dem Erlös Seminare,
            Unterrichtsmaterialien oder neue Projekte im Bereich der Lehre zu
            finanzieren.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Weitere Fragen?">
          <p>
            Falls Sie weitere Fragen an uns haben, stehen wir Ihnen jederzeit
            sehr gerne telefonisch (+41 44 634 50 30) oder per E-Mail (
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) zur
            Verfügung.
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(FAQ, styles)
