import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router'
import classNames from 'classnames'
import CSSModules from 'react-css-modules'
import styles from './Button.scss'

const Button = props => {
  const { link = '#', icon, circular, fluid, className } = props

  return (
    <Link to={link}>
      <button
        className={classNames(
          'ui',
          'button',
          { icon, circular, fluid },
          className
        )}
        styleName="button"
        onClick={props.onClick || null}
      >
        {icon ? <i className={`${icon} icon`} /> : null}
        {!circular ? props.children : false}
      </button>
    </Link>
  )
}

Button.propTypes = {
  circular: PropTypes.bool, // if the button is circular
  link: PropTypes.string, // where the button links to
  icon: PropTypes.string, // if the button has an icon, the name of the icon
  fluid: PropTypes.bool, // if the button is fluid, fills the whole width
  className: PropTypes.string, // the additional css classes of the button
}

export default CSSModules(Button, styles)
