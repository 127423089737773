import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import CSSModules from 'react-css-modules'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'
import { Table } from 'reactable'
import { _generateCSV } from '../../../utils'

import styles from './TableList.scss'

class TableList extends React.Component {
  state = {
    download: null,
    href: null,
  }

  getCSV = () => {
    if (this.props.csvData.length > 0) {
      let csv
      if (this.props.csvColumns.indexOf('Processed') > -1) {
        csv = _generateCSV([
          this.props.csvColumns,
          ...this.props.csvData.filter(row => !row[6] && row[5]),
        ])
      } else {
        csv = _generateCSV([this.props.csvColumns, ...this.props.csvData])
      }

      if (csv) {
        this.setState({
          download: `${moment().format('YYYY-MM-DD')}_JOBEYE_${this.props
            .csvFilename || 'export'}.csv`,
          href: csv,
        })
      } else {
        this.setState({ download: null, href: null })
      }
    } else {
      this.setState({ download: null, href: null })
    }
  }

  render() {
    const {
      className = '',
      sortable = true,
      filterable = true,
      itemsPerPage = 20,
      isFetching = false,
      defaultSort,
      csvColumns,
      csvData,
      button,
      buttonLink,
    } = this.props

    return (
      <div styleName="container">
        {isFetching ? (
          <div className="ui segment">
            <div className="ui active inverted dimmer">
              <div className="ui loader" />
            </div>
          </div>
        ) : this.props.children.length > 0 ? (
          <Table
            className={classNames('ui table', className)}
            itemsPerPage={itemsPerPage}
            sortable={sortable}
            filterable={filterable}
            defaultSort={defaultSort}
          >
            {this.props.children}
          </Table>
        ) : (
          <span>
            <FormattedMessage id="common.string.nothing" />
          </span>
        )}
        {button && (
          <Link
            to={buttonLink}
            className="ui right floated primary icon button"
          >
            <i className="plus icon" />
            <FormattedMessage id={button} />
          </Link>
        )}
        {!isFetching &&
          this.props.children.length > 0 &&
          csvColumns &&
          csvData && (
            <a
              download={this.state.download}
              href={this.state.href}
              className="ui icon button"
              onClick={this.getCSV}
            >
              <i className="table icon" /> Export
            </a>
          )}
      </div>
    )
  }
}

TableList.propTypes = {
  className: PropTypes.string, // additional classes of the table
  sortable: PropTypes.array, // which fields should be sortable in the list
  defaultSort: PropTypes.object, // default sorting for the table
  filterable: PropTypes.array, // which fields should be filterable in the array
  itemsPerPage: PropTypes.number, // how many items are displayed on one page
  isFetching: PropTypes.bool, // whether the table contents are being loaded or not
}

export default CSSModules(styles)(TableList)
