import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { _decodeToken } from '../../utils'
import rest from '../../settings/rest'
import { FormMessage } from '../../components/common/Helpers'
import { CenteredSegment } from '../../components/common/Layouts'

class UserConfirm extends React.Component {
  static propTypes = {}
  state = {
    validToken: false,
  }

  componentWillMount = () => {
    const {
      query: { activationToken },
    } = this.props.location

    // if an activationToken query param is set, check if it is a valid jwt
    if (activationToken) {
      const jwt = _decodeToken(activationToken)

      // if it is valid, save to state
      if (jwt) {
        this.setState({ jwt, activationToken, validToken: true })

        this.props.dispatch(
          rest.actions.confirmUser(
            {},
            {
              body: JSON.stringify({
                token: activationToken,
              }),
            }
          )
        )
      }
    }
  }

  render() {
    const {
      intl,
      confirmUser: {
        data: { message, messageType },
        sync,
      },
    } = this.props

    return (
      <CenteredSegment
        title={intl.formatMessage({ id: 'accountActivation.string.title' })}
      >
        {!sync && this.state.validToken && (
          <div className="ui message">
            <FormattedMessage
              id="accountActivation.string.activating"
              values={{ username: this.state.jwt.username }}
            />
          </div>
        )}
        {sync && message && (
          <FormMessage messageType={messageType} name={message} />
        )}
      </CenteredSegment>
    )
  }
}

export default compose(
  connect(state => ({
    confirmUser: state.confirmUser,
  })),
  injectIntl
)(UserConfirm)
