import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import rest from '../settings/rest'
import SlideOut from 'slideout'
import { compose } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { IndexLink, Link } from 'react-router'
import { setLocale, logout, resetGlobalMessage } from '../actions'
import Header from './common/Header/Header'
import Footer from './common/Footer/Footer'
import Nav from './common/Nav/Nav'
import { GlobalMessage } from '../components/common/Helpers'

import './App.scss'

class App extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    dispatch: PropTypes.func.isRequired,
    global: PropTypes.object.isRequired,
  }

  static childContextTypes = {
    toggleSlideout: PropTypes.func,
  }

  getChildContext() {
    return {
      toggleSlideout: () => this.slideout.toggle(),
    }
  }

  componentWillMount() {
    if (
      this.props.global.login &&
      this.props.global.login.companyId &&
      !this.props.account.companyData.init
    ) {
      this.props.dispatch(
        rest.actions.companyDataInit({ id: this.props.global.login.companyId })
      )
    }
  }

  componentDidMount() {
    this.slideout = new SlideOut({
      panel: document.getElementById('panel'),
      menu: document.getElementById('slideout'),
      padding: 256,
      tolerance: 70,
      touch: false,
    })
  }

  _changeLocale = newLocale => {
    this.props.dispatch(setLocale(newLocale))
  }

  _logout = () => {
    this.slideout.close()
    this.props.dispatch(logout())
  }

  render() {
    const { company, global } = this.props

    return (
      <div>
        <div id="slideout">
          <h1>
            <FormattedMessage id="slideout.title" />
          </h1>
          <nav className="ui fluid vertical menu">
            <div className="item">
              <div className="header">
                <FormattedMessage id="slideout.subtitle.content" />
              </div>
              <IndexLink
                className="item"
                to="/"
                activeClassName="active"
                onClick={() => this.slideout.close()}
              >
                <FormattedMessage id="nav.button.jobs" />
              </IndexLink>
              <Link
                className="item"
                to="/theses"
                activeClassName="active"
                onClick={() => this.slideout.close()}
              >
                <FormattedMessage id="nav.button.theses" />
              </Link>
            </div>
            <div className="item">
              <div className="header">
                <FormattedMessage id="slideout.subtitle.account" />
              </div>
              {!global.login.token && (
                <Link
                  className="item"
                  to="/users/login"
                  activeClassName="active"
                  onClick={() => this.slideout.close()}
                >
                  <FormattedMessage id="header.button.login" />
                </Link>
              )}
              {!global.login.token && (
                <a
                  className="item"
                  href="https://www.df.uzh.ch/AAILogin/jobeye/aai.php"
                >
                  AAI
                </a>
              )}
              {!global.login.token && (
                <Link
                  className="item"
                  to="/users/register"
                  activeClassName="active"
                  onClick={() => this.slideout.close()}
                >
                  <FormattedMessage id="header.button.signup" />
                </Link>
              )}
              {global.login.token && global.login.role === 'admin' && (
                <Link
                  className="item"
                  activeClassName="active"
                  to="/admin/jobs"
                  onClick={() => this.slideout.close()}
                >
                  <FormattedMessage id="header.button.admin" />
                </Link>
              )}
              {global.login.token &&
                (global.login.role === 'privileged' ||
                  global.login.role === 'admin') && (
                  <Link
                    className="item"
                    activeClassName="active"
                    to="/theses/create"
                    onClick={() => this.slideout.close()}
                  >
                    <FormattedMessage id="header.button.createThesis" />
                  </Link>
                )}
              {global.login.token &&
                (global.login.role === 'company' ||
                  global.login.role === 'privileged' ||
                  global.login.role === 'admin') && (
                  <Link
                    className="item"
                    activeClassName="active"
                    to="/jobs/create"
                    onClick={() => this.slideout.close()}
                  >
                    <FormattedMessage id="header.button.createJob" />
                  </Link>
                )}
              {global.login.token && (
                <Link
                  className="item"
                  activeClassName="active"
                  to={
                    global.login.role === 'admin' ||
                    global.login.role === 'privileged' ||
                    global.login.role === 'company'
                      ? '/users/account/jobs'
                      : '/users/account/subscriptions'
                  }
                  onClick={() => this.slideout.close()}
                >
                  <FormattedMessage id="header.button.account" />
                </Link>
              )}
              {global.login.token && (
                <a className="item" onClick={() => this._logout()}>
                  <FormattedMessage id="header.button.logout" />
                </a>
              )}
            </div>
          </nav>
          <p className="slideoutFooter">
            <FormattedMessage id="footer.string.address1" />
            <br />
            <FormattedMessage id="footer.string.address2" />
          </p>
        </div>
        <div
          id="panel"
          className="ui grid horizontally padded container jobeye"
        >
          <Header
            companyData={company}
            loginData={global.login}
            logout={this._logout}
          />
          <Nav changeLocale={this._changeLocale} />
          {global.message != null && (
            <GlobalMessage
              message={global.message.content}
              type={global.message.type}
              resetGlobalMessage={() =>
                this.props.dispatch(resetGlobalMessage())
              }
            />
          )}
          {this.props.children}
          <Footer />
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    global: state.global,
    account: state.account2,
    company: state.account2.companyData.general,
  }))
)(App)
