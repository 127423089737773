import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'

import styles from './Section.scss'

const Section = ({ name, items, children, intl, intlId }) => (
  <div className="ui card" styleName="section">
    <div className="content">
      <h1 className="header">{intl.formatMessage({ id: intlId })}</h1>
      <div styleName="innerContent">{children}</div>
    </div>
  </div>
)

Section.propTypes = {
  children: PropTypes.children,
  intlId: PropTypes.string.isRequired,
}

export default injectIntl(CSSModules(Section, styles))
