import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'

import styles from './LabelSelect.scss'

const LabelSelect = ({ label, children }) => (
  <div className="ui labeled fluid small input" styleName="container">
    <div className="ui label">
      <FormattedMessage id={label} />
    </div>
    {children}
  </div>
)

LabelSelect.propTypes = {}

export default CSSModules(LabelSelect, styles)
