import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { IndexLink, Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import { VerticalMenu } from '../../common/Layouts'

const UserAccount = props => {
  let links
  if (props.global.login.companyId > 0) {
    if (
      props.global.login.role === 'privileged' ||
      props.global.login.role === 'admin'
    ) {
      links = [
        <IndexLink
          activeClassName="active"
          className="item"
          to="/users/account/jobs"
        >
          <FormattedMessage id="userAccount.menu.jobs" />
        </IndexLink>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/theses"
        >
          <FormattedMessage id="userAccount.menu.theses" />
        </Link>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/profile"
        >
          <FormattedMessage id="userAccount.menu.about" />
        </Link>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/company"
        >
          <FormattedMessage id="userAccount.menu.company" />
        </Link>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/personal"
        >
          <FormattedMessage id="userAccount.menu.personal" />
        </Link>,
      ]

      if (props.global.login.role === 'admin') {
        links = [
          ...links,
          <Link
            activeClassName="active"
            className="item"
            to="/users/account/subscriptions"
          >
            <FormattedMessage id="userAccount.menu.subscriptions" />
          </Link>,
        ]
      }
    } else {
      links = [
        <IndexLink
          activeClassName="active"
          className="item"
          to="/users/account/jobs"
        >
          <FormattedMessage id="userAccount.menu.jobs" />
        </IndexLink>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/profile"
        >
          <FormattedMessage id="userAccount.menu.about" />
        </Link>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/company"
        >
          <FormattedMessage id="userAccount.menu.company" />
        </Link>,
        <Link
          activeClassName="active"
          className="item"
          to="/users/account/personal"
        >
          <FormattedMessage id="userAccount.menu.personal" />
        </Link>,
      ]
    }
  } else {
    links = [
      <IndexLink
        activeClassName="active"
        className="item"
        to="/users/account/subscriptions"
      >
        <FormattedMessage id="userAccount.menu.subscriptions" />
      </IndexLink>,
      <Link
        activeClassName="active"
        className="item"
        to="/users/account/personal"
      >
        <FormattedMessage id="userAccount.menu.personal" />
      </Link>,
    ]
  }

  return (
    <VerticalMenu title="userAccount.string.title" links={links}>
      {props.children}
    </VerticalMenu>
  )
}

export default connect(state => ({
  global: state.global,
}))(UserAccount)
