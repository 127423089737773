import React from 'react'
import CSSModules from 'react-css-modules'
import rest from '../../settings/rest'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reduxForm } from 'redux-form'
import {
  AttachedSegment,
  BasicInput,
  BasicTextarea,
} from '../../components/common/Helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import { setRecaptchaToken, resetRecaptchaToken } from '../../actions'
import { object, string } from 'yup'
import { compose } from 'recompose'
import { _validateSchema } from '../../utils'
import _some from 'lodash/some'

import Imprint from './Imprint'
import styles from './Contact.scss'

const formName = 'contact'
const formFields = ['name', 'email', 'message']

class Contact extends React.Component {
  componentWillMount = () => {
    this.props.dispatch(resetRecaptchaToken())
  }

  componentWillUnmount = () => {
    this.props.dispatch(resetRecaptchaToken())
  }

  onCaptchaChange = response => {
    this.props.dispatch(setRecaptchaToken(response))
  }

  render() {
    const {
      global,
      fields: { name, email, message },
      dispatch,
      intl,
    } = this.props

    return (
      <div
        className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column"
        styleName="container"
      >
        <AttachedSegment
          h1={intl.formatMessage({ id: 'footer.string.contact' })}
        >
          <p>
            <FormattedMessage id="contact.string.description" />
          </p>

          <div className="ui divider" />
          <div className="ui form">
            <BasicInput required formName={formName} reduxForm={name} />
            <BasicInput required formName={formName} reduxForm={email} />
            <BasicTextarea required formName={formName} reduxForm={message} />
            <ReCAPTCHA
              ref="recaptcha"
              sitekey="6LeSoiUTAAAAAJvTBnvTkjeGNjPG8UZKkhSAOPPs"
              onChange={this.onCaptchaChange}
              className={styles.captcha}
            />
            <button
              disabled={_some([
                name.invalid,
                email.invalid,
                message.invalid,
                !global.recaptcha,
              ])}
              className="ui primary button"
              type="submit"
              onClick={() =>
                dispatch(
                  rest.actions.contact(
                    {},
                    {
                      body: JSON.stringify({
                        name: name.value,
                        email: email.value,
                        message: message.value,
                        recaptcha: global.recaptcha,
                      }),
                    }
                  )
                )
              }
            >
              <FormattedMessage id="common.button.submit" />
            </button>
          </div>
        </AttachedSegment>

        <Imprint />
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate: _validateSchema(
        object().shape({
          name: string().required(),
          email: string()
            .email()
            .required(),
          message: string().required(),
        })
      ),
    },
    state => ({
      global: state.global,
    })
  ),
  injectIntl,
  CSSModules(styles)
)(Contact)
