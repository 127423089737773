import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import {
  BasicInput,
  BasicSubmit,
  DividingHeader,
} from '../../../common/Helpers'
import classNames from 'classnames'
import { _isInvalid, _validateSchema } from '../../../../utils'
import { compose } from 'recompose'
import { object, string, ref } from 'yup'

import styles from '../CompanyData/CompanyGeneral.scss'

const formName = 'accountUserPassword'
const formFields = ['password', 'passwordRepeat']

class UserPassword extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  render() {
    const {
      displaySuccess,
      displayError,
      intl,
      message,
      success,
      loading,
      fields,
      handleSubmit,
      fields: { password, passwordRepeat },
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="ui clearing segment" styleName="container">
        <form
          className={classNames('ui form', {
            error: !loading && invalid,
            loading,
            success,
          })}
          onSubmit={handleSubmit}
        >
          <DividingHeader>
            {intl.formatMessage({ id: `${formName}.string.title` })}
          </DividingHeader>
          {message && (
            <div className={classNames('ui message', { success })}>
              {message}
            </div>
          )}
          <div className="two fields">
            <BasicInput
              type="password"
              common
              required
              formName={formName}
              reduxForm={password}
            />
            <BasicInput
              type="password"
              common
              required
              formName={formName}
              reduxForm={passwordRepeat}
            />
          </div>
          {displaySuccess && !loading && (
            <div styleName="success">
              <i className="green checkmark icon" />{' '}
              <FormattedMessage id="accountUserPassword.string.updateSuccess" />
            </div>
          )}
          {displayError && !loading && (
            <div styleName="error">
              <i className="red flag icon" />{' '}
              <FormattedMessage id="accountUserPassword.string.updateError" />
            </div>
          )}
          <BasicSubmit
            primary
            floated="right"
            disabled={
              loading || invalid || !password.value || !passwordRepeat.value
            }
            loading={loading}
          />
        </form>
      </div>
    )
  }
}

export default compose(
  reduxForm({
    form: formName,
    fields: formFields,
    validate: _validateSchema(
      object().shape({
        password: string().required(),
        passwordRepeat: string()
          .oneOf([ref('password'), null])
          .required(),
      })
    ),
  }),
  injectIntl,
  CSSModules(styles)
)(UserPassword)
