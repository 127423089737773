import PropTypes from 'prop-types'
import React from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'

import { Steps } from '../Helpers'

import styles from './CenteredSegment.scss'

const CenteredSegment = ({
  title,
  success,
  message,
  steps,
  bottomMessage,
  left,
  right,
  children,
}) => (
  <div className="row" styleName="container">
    <div className="one wide computer only column" />
    <div className="four wide computer four wide tablet only column">
      {left}
    </div>
    <div className="six wide computer eight wide tablet sixteen wide mobile column">
      <h1 className="ui top attached header">{title}</h1>
      <div className="ui attached clearing segment">
        {message && (
          <div className={`ui ${success} message`}>
            <FormattedMessage id={message} />
          </div>
        )}
        {children}
      </div>
      {steps && <Steps steps={steps} />}
      {bottomMessage && (
        <div className="ui bottom attached message">
          <i className="info icon" />
          {bottomMessage}
        </div>
      )}
    </div>
    <div className="four wide computer four wide tablet only column">
      {right}
    </div>
    <div className="one wide computer only column" />
  </div>
)

CenteredSegment.propTypes = {
  title: PropTypes.string, // the react-intl identifier string for the title of the segment
  success: PropTypes.string, // the className of the ui message based on whether the action was successful or not
  message: PropTypes.string, // the react-intl identifier string for the error / success message
  steps: PropTypes.object,
  bottomMessage: PropTypes.element, // what to display in the attached bottom message
}

export default CSSModules(CenteredSegment, styles)
