export const _isInvalid = fields => {
  for (const field in fields) {
    if (fields.hasOwnProperty(field) && fields[field].error) return true
  }
  return false
}

export const _validateSchema = (
  validationSchema,
  additionalValidator
) => values => {
  try {
    validationSchema.validateSync(values, { abortEarly: false })
  } catch ({ inner }) {
    const errors = {}
    inner.forEach(({ path, message }) => {
      errors[path] = message
    })
    return errors
  }

  if (additionalValidator) {
    const additionalResult = additionalValidator(values)
    return additionalResult || {}
  }

  return {}
}
