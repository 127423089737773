import PropTypes from 'prop-types';
import React from 'react';

const About = ({ children }) => <div className="row">{children}</div>

About.propTypes = {
  children: PropTypes.object.isRequired,
}

export default About
