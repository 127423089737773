import * as Actions from '../actions/jobs'
import _filter from 'lodash/filter'
import _chunk from 'lodash/chunk'

const initialState = {
  sync: false,
  filters: {
    categories: [],
    education: [],
    query: '',
    rate: {
      min: 0,
      max: 100,
    },
    types: [],
  },
  data: [],
  paginated: [],
  page: [],
  currentPage: 1,
}

const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.UPDATE_RATES:
      return {
        ...state,
        filters: {
          ...state.filters,
          rate: {
            min: action.rate.min,
            max: action.rate.max,
          },
        },
      }

    case Actions.JOBS_FILTER:
      const filters = action.filters
      let filtered = state.data

      if (filters.rate.min > 0 || filters.rate.max < 100) {
        filtered = _filter(
          filtered,
          job =>
            job.max_rate <= filters.rate.max && job.min_rate >= filters.rate.min
        )
      }

      if (filters.categories.length > 0) {
        filtered = _filter(
          filtered,
          job => filters.categories.indexOf(job.category.id) > -1
        )
      }

      if (filters.education.length > 0) {
        filtered = _filter(
          filtered,
          job => filters.education.indexOf(job.education.id) > -1
        )
      }

      if (filters.types.length > 0) {
        filtered = _filter(
          filtered,
          job => filters.types.indexOf(job.type.id) > -1
        )
      }

      if (filters.query.length > 0) {
        const query = filters.query.toLowerCase()
        filtered = _filter(
          filtered,
          job =>
            job.title.toLowerCase().indexOf(query) > -1 ||
            job.company.name.toLowerCase().indexOf(query) > -1
        )
      }

      const paginated1 = _chunk(filtered, 25)
      return {
        ...state,
        filters,
        filtered,
        sync: true,
        paginated: paginated1,
        page: paginated1.length > 0 ? paginated1[0] : [],
        currentPage: 1,
      }

    case Actions.JOBS_PAGINATE:
      return {
        ...state,
        page: state.paginated[action.pageNumber - 1] || [],
        currentPage: action.pageNumber,
      }

    case Actions.JOBS_REQUEST:
      return {
        ...state,
        sync: false,
      }

    case Actions.JOBS_RECEIVE:
      const paginated2 = _chunk(action.jobs, 25)
      return {
        ...state,
        sync: true,
        data: action.jobs,
        filtered: action.jobs,
        paginated: paginated2,
        page: paginated2.length > 0 ? paginated2[0] : [],
        currentPage: 1,
      }

    default:
      return state
  }
}

export default jobsReducer
