import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import Imprint from './Imprint'

import styles from './About.scss'

const FAQ = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>FAQ</h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="What is the purpose of jobeye.ch?">
          <p>
            Jobeye.ch is a job platform maintained by the Department of Banking
            and Finance of the University of Zurich. The purpose of jobeye.ch is
            to bring highly qualified students or graduates in banking and
            finance or business together with leading companies, whether for a
            job offering or a practical thesis.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="What are the advantages of jobeye.ch compared to other job platforms?">
          <p>
            Jobeye.ch is subordinated to the Department of Banking and Finance
            of the University of Zurich and its network among the professors and
            therefore it has a direct contact to the students and alumni,
            especially in the fields of banking, finance and also business. Your
            potentially qualified employees can be easily reached through the
            different distribution channels (i.e. online advertisement,
            posting).
          </p>
        </AttachedSegment>

        <AttachedSegment h2="How can I publish an advertisement on jobeye.ch?">
          <p>
            The publication of an advertisement can be made over the menu “…”.
            In the case of not having a user account, you have to create one. By
            the subsequent authentication with your selected username and
            password, you can enter the required information and attach the PDF
            of your advertisement.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="How long does it take to publish your advertisement?">
          <p>
            After your submission, the advertisement will be reviewed and
            published within 24 hours weekdays. You will receive a confirmation,
            as soon as the advertisement is posted on jobeye.ch.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="For how long is the advertisement online?">
          <p>
            The advertisement will be published for a given price for maximal 60
            days (start- and end date are free of choice). The ad can be
            modified, earlier deleted or extended (additional costs may be
            charged). In any case, you will receive an e-mail with the option of
            extension two days before the selected end date.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Which kind of distribution channels does jobeye.ch provide?">
          <p>
            Jobeye.ch offers you the following distribution channels for your
            advertisement:
          </p>
          <table className="ui collapsing celled table">
            <thead>
              <tr>
                <th>Online-Publication</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right aligned">CHF 200</td>
              </tr>
            </tbody>
          </table>
          <p>
            All prices are in CHF, excl. VAT. A detailed overview of our service
            offering is displayed here(
            <Link to="about/for-companies">hier</Link>).
          </p>
          <p>
            In case of questions or problems concerning the publication of your
            advertisement, we are available by phone (+41 44 634 50 30) or by
            e-mail (<a href="mailto:jobeye@df.uzh.ch">E-Mail</a>).
          </p>
        </AttachedSegment>

        <AttachedSegment h2="What is behind the option “Create Search Subscription”?">
          <p>
            In case, you are interested in job advertisement based on specific
            search criteria. The option “Create Search Subscription” allows you
            to save those, thereby you will receive an e-mail notification from
            jobeye.ch when there appears a new matching job advertisement. This
            option requires a jobeye.ch user account.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Cooperation for thesis">
          Besides the job platform, Jobeye.ch mediates practical theses
          (bachelor - or master level) between students and companies. If your
          company is interested in a collaboration, please get in touch by phone
          (+41 44 634 50 30) or by mail (
          <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) with the
          jobeye.ch team. We can assist you with the elaboration of the topic,
          the mediation of highly qualified students and also the collaboration
          with the Chairs of the University of Zurich. Price on request.
        </AttachedSegment>

        <AttachedSegment h2="What will happen with proceeds of jobeye.ch?">
          <p>
            Jobeye.ch is a non-profit organization and its purpose is to finance
            seminars, teaching material or new teaching projects with the
            proceeds.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Further Question?">
          <p>
            If you have any further questions, do not hesitate to contact us by
            phone (+41 44 634 50 30) or by e-mail (
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>).
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(FAQ, styles)
