import reduxApi from 'redux-api'
import adapterFetch from '../../node_modules/redux-api/lib/adapters/fetch'
import _get from 'lodash/get'
import _merge from 'lodash/merge'
import Cookies from 'js-cookie'
import { initialize } from 'redux-form'
import moment from 'moment'
import {
  loginError,
  resetFile,
  jobsReceive,
  jobsRequest,
  accountCompanyReceive,
  setGlobalMessage,
  thesesRequest,
  thesesReceive,
} from '../actions'
import {
  companyProfileRequest,
  companyProfileSuccess,
  companyProfileFailure,
  companyJobsRequest,
  companyJobsSuccess,
  companyJobsFailure,
  companyThesesRequest,
  companyThesesSuccess,
  companyThesesFailure,
  companyDataInit,
  companyDataGeneralRequest,
  companyDataGeneralSuccess,
  companyDataGeneralFailure,
  companyDataContactRequest,
  companyDataContactSuccess,
  companyDataContactFailure,
  companyDataInvoiceRequest,
  companyDataInvoiceSuccess,
  companyDataInvoiceFailure,
  userDataInit,
  userDataGeneralRequest,
  userDataGeneralSuccess,
  userDataGeneralFailure,
  userDataPasswordRequest,
  userDataPasswordSuccess,
  userDataPasswordFailure,
  userSubscriptionsRequest,
  userSubscriptionsSuccess,
  userSubscriptionsFailure,
  userSubscriptionRequest,
  userSubscriptionSuccess,
  userSubscriptionFailure,
} from '../actions/account'
import { _checkMessage, _redirect, _logEvent } from '../utils'
import { API_URL } from './_settings'
import history from '../history'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json', // preflight request!
}

// if the login cookie is set, get the token and set the authorization header
if (Cookies.get('JWT')) {
  headers.Authorization = `Bearer ${Cookies.get('JWT')}`
}

export default reduxApi({
  accountCompany: {
    virtual: true,
    crud: true,
    url: `${API_URL}/companies/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(accountCompanyReceive(data))
      },
    ],
  },
  adminCompanies: {
    url: `${API_URL}/companies?admin=all`,
    options: { headers },
  },
  adminJobsApproved: {
    url: `${API_URL}/jobs?admin=approved`,
    options: { headers },
  },
  adminJobsPending: {
    url: `${API_URL}/jobs?admin=pending`,
    options: { headers },
  },
  adminTheses: {
    url: `${API_URL}/theses?admin=all`,
    options: { headers },
  },
  confirmUser: {
    url: `${API_URL}/users/confirm`,
    options: {
      headers: {
        // don't send authorization header
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          _logEvent({
            category: 'Account',
            action: 'Account activated',
            value: data.data.id,
          })
          dispatch(setGlobalMessage('globals.confirmUser.success', 'success'))
          history.push('/users/login')
        } else {
          dispatch(setGlobalMessage('globals.confirmUser.fail', 'error'))
          history.push('/')
        }
      },
    ],
  },
  companies: {
    url: `${API_URL}/companies`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the companies array as data or an empty array or the error message if existent
      _get(response, 'data') || _checkMessage(response) || [],
  },
  company: {
    crud: true,
    url: `${API_URL}/companies/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        const login = getState().global.login

        if (
          login &&
          login.role === 'admin' &&
          request.params &&
          request.params.method === 'PUT'
        ) {
          dispatch(actions.adminCompanies())
        }
      },
    ],
  },
  companyJobCreate: {
    url: `${API_URL}/companies/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(
          initialize(
            'jobCreate',
            {
              contactGender: data.contact.gender,
              contactName: data.contact.name,
              contactEmail: data.contact.email,
              contactPhone: data.contact.phone,
              locationStreet: data.company.street,
              locationPlz: data.company.plz,
              locationCity: data.company.city,
              locationCountry: data.company.country,
              onlineFrom: moment()
                .add(1, 'days')
                .format('YYYY-MM-DD'),
              onlineUntil: moment()
                .add(1, 'days')
                .add(2, 'months')
                .format('YYYY-MM-DD'),
              openFrom: 'now',
              openFromDate: moment(),
              category: 7,
              agb: false,
            },
            [
              'title',
              'category',
              'education',
              'rate',
              'type',
              'contactGender',
              'contactName',
              'contactEmail',
              'contactPhone',
              'locationStreet',
              'locationPlz',
              'locationCity',
              'locationCountry',
              'openFrom',
              'openFromDate',
              'onlineFrom',
              'onlineUntil',
              'agb',
            ]
          )
        )
      },
    ],
  },
  companyJobProlong: {
    url: `${API_URL}/jobs/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(
          initialize(
            'jobCreate',
            {
              contactGender: data.contact.gender,
              contactName: data.contact.name,
              contactEmail: data.contact.email,
              contactPhone: data.contact.phone,
              locationStreet: data.contact.street,
              locationPlz: data.contact.plz,
              locationCity: data.contact.city,
              locationCountry: data.contact.country,
              onlineFrom: moment(data.onlineUntil).isBefore(moment())
                ? moment()
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                : moment(data.onlineUntil)
                    .add(1, 'days')
                    .format('YYYY-MM-DD'),
              onlineUntil: moment(data.onlineUntil).isBefore(moment())
                ? moment()
                    .add(1, 'days')
                    .add(2, 'months')
                    .format('YYYY-MM-DD')
                : moment(data.onlineUntil)
                    .add(1, 'days')
                    .add(2, 'months')
                    .format('YYYY-MM-DD'),
              openFrom:
                data.openFrom === 'now' || data.openFrom === 'arrangement'
                  ? data.openFrom
                  : 'date',
              openFromDate:
                data.openFrom === 'now' || data.openFrom === 'arrangement'
                  ? moment()
                  : moment(data.openFrom, 'YYYY-MM-DD'),
              category: data.category.id,
              education: data.education.id,
              type: data.type.id,
              rate: data.maxRate < 100 || data.minRate < 100 ? 2 : 1,
              minRate: data.minRate,
              maxRate: data.maxRate,
              title: data.title,
              agb: false,
            },
            [
              'title',
              'category',
              'education',
              'rate',
              'minRate',
              'maxRate',
              'type',
              'contactGender',
              'contactName',
              'contactEmail',
              'contactPhone',
              'locationStreet',
              'locationPlz',
              'locationCity',
              'locationCountry',
              'openFrom',
              'openFromDate',
              'onlineFrom',
              'onlineUntil',
              'agb',
            ]
          )
        )
      },
    ],
  },
  companyJobEdit: {
    url: `${API_URL}/jobs/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        // if the company in state has a different id than the own one, fetch it
        if (
          !getState().company.sync ||
          getState().company.data.company.id !== data.company.id
        ) {
          dispatch(actions.company({ id: data.company.id }))
        }

        dispatch(
          initialize(
            'jobEdit',
            {
              title: data.title,
              category: data.category.id,
              education: data.education.id,
              rate: data.maxRate < 100 || data.minRate < 100 ? 2 : 1,
              minRate: data.minRate,
              maxRate: data.maxRate,
              type: data.type.id,
              openFrom:
                data.openFrom === 'now' || data.openFrom === 'arrangement'
                  ? data.openFrom
                  : 'date',
              openFromDate:
                data.openFrom === 'now' || data.openFrom === 'arrangement'
                  ? moment()
                  : moment(data.openFrom, 'YYYY-MM-DD'),
              contactGender: data.contact.gender,
              contactName: data.contact.name,
              contactEmail: data.contact.email,
              contactPhone: data.contact.phone,
              locationStreet: data.contact.street,
              locationPlz: data.contact.plz,
              locationCity: data.contact.city,
              locationCountry: data.contact.country,
              onlineFrom: moment(data.onlineFrom, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
              onlineUntil: moment(data.onlineUntil, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
            },
            [
              'title',
              'category',
              'education',
              'rate',
              'minRate',
              'maxRate',
              'type',
              'openFrom',
              'openFromDate',
              'contactGender',
              'contactName',
              'contactEmail',
              'contactPhone',
              'locationStreet',
              'locationPlz',
              'locationCity',
              'locationCountry',
              'onlineFrom',
              'onlineUntil',
            ]
          )
        )
      },
    ],
  },
  companyThesisCreate: {
    url: `${API_URL}/companies/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(
          initialize(
            'thesisCreate',
            {
              contactGender: data.contact.gender,
              contactName: data.contact.name,
              contactEmail: data.contact.email,
              contactPhone: data.contact.phone,
              type: 'BA',
              withJob: 0,
              onlineFrom: moment()
                .add(2, 'days')
                .format('YYYY-MM-DD'),
              onlineUntil: moment()
                .add(2, 'days')
                .add(2, 'months')
                .format('YYYY-MM-DD'),
            },
            [
              'title',
              'type',
              'withJob',
              'contactGender',
              'contactName',
              'contactEmail',
              'contactPhone',
              'onlineFrom',
              'onlineUntil',
            ]
          )
        )
      },
    ],
  },
  companyThesisEdit: {
    url: `${API_URL}/theses/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        // if the company in state has a different id than the own one, fetch it
        if (
          !getState().company.sync ||
          getState().company.data.company.id !== data.company.id
        ) {
          dispatch(actions.company({ id: data.company.id }))
        }

        dispatch(
          initialize(
            'thesisEdit',
            {
              title: data.title,
              type: data.type,
              withJob: data.withJob + 0,
              contactGender: data.contact.gender,
              contactName: data.contact.name,
              contactEmail: data.contact.email,
              contactPhone: data.contact.phone,
              onlineFrom: moment(data.onlineFrom, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
              onlineUntil: moment(data.onlineUntil, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
            },
            [
              'title',
              'type',
              'withJob',
              'contactGender',
              'contactName',
              'contactEmail',
              'contactPhone',
              'onlineFrom',
              'onlineUntil',
            ]
          )
        )
      },
    ],
  },
  contact: {
    url: `${API_URL}/misc/sendContactMessage`,
    options: {
      headers,
      method: 'POST',
    },
    postfetch: [
      ({ data, dispatch }) => {
        if (data.success) {
          dispatch(
            setGlobalMessage('globals.sendContactMessage.success', 'success')
          )
          history.push('/')
        } else {
          dispatch(
            setGlobalMessage('globals.sendContactMessage.error', 'error')
          )
        }
      },
    ],
  },
  jobs: {
    // fetch all the jobs
    virtual: true,
    url: `${API_URL}/jobs`,
    options: { headers },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(jobsRequest())
        cb()
      },
    ],
    transformer: (response, prevData, action) =>
      // return the jobs array as data or an empty array or the error message if existent
      _get(response, 'data') || _checkMessage(response) || [],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(jobsReceive(data))
      },
    ],
  },
  jobCreate: {
    url: `${API_URL}/jobs`,
    options: {
      headers,
      method: 'POST',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        const file = _get(getState(), 'global.file')
        const id = data.data.id

        console.error(data)

        // if there is a file in state, upload it and append it to the job posting
        if (file) {
          const body = new FormData()
          body.append('file', file)

          dispatch(
            actions.uploadPDF(
              { job: id },
              {
                body,
              }
            )
          )

          // TODO: also check if the file upload was successful!
          if (data.success) {
            dispatch(setGlobalMessage('globals.jobCreate.success', 'success'))
            dispatch(resetFile())
            history.push(`jobs/${id}`)
          } else {
            dispatch(setGlobalMessage('globals.jobCreate.fail', 'error'))
          }
        } else {
          dispatch(setGlobalMessage('globals.jobCreate.noFile', 'error'))
        }
      },
    ],
  },
  job: {
    // fetch a single job
    crud: true,
    url: `${API_URL}/jobs/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _merge(prevData, _get(response, 'data')) || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        const login = getState().global.login

        const file = _get(getState(), 'global.file')

        if (request.params && request.params.method === 'PUT') {
          if (file) {
            const body = new FormData()
            body.append('file', file)

            dispatch(
              actions.uploadPDF(
                { job: data.id },
                {
                  body,
                }
              )
            )

            // TODO: also check if the file upload was successful!
            if (data.success) {
              dispatch(setGlobalMessage('globals.jobCreate.success', 'success'))
              dispatch(resetFile())
              history.push(`jobs/${data.id}`)
            } else {
              dispatch(setGlobalMessage('globals.jobCreate.fail', 'error'))
            }
          } else {
            dispatch(setGlobalMessage('globals.jobCreate.noFile', 'error'))
          }

          if (!data.message) {
            dispatch(setGlobalMessage('globals.jobEdit.success', 'success'))
            history.push(`/jobs/${data.id}`)
          } else {
            dispatch(setGlobalMessage('globals.jobEdit.fail', 'error'))
          }

          if (login && login.role === 'admin') {
            dispatch(actions.adminJobsApproved())
          }
        }
      },
    ],
  },
  login: {
    virtual: true,
    url: `${API_URL}/users/token`,
    options: {
      headers,
      method: 'post',
    },
    postfetch: [
      // executed after a successful login
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          const token = data.data.token,
            language = data.data.language || 'EN',
            migrated = data.data.migrated,
            role = data.data.role

          // if the token in data is set
          if (token) {
            // set the jwt cookie
            Cookies.set('JWT', token, {
              expires: new Date(Date.now() + 86400000),
            })
            Cookies.set('locale', language)

            // reload the page to initiate the session
            location.reload()

            // redirect to the job listing
            if (!migrated && role !== 'admin') {
              history.push('/intro/welcome')
            } else if (request.pathvars && request.pathvars.redirect) {
              history.push(
                _redirect(request.pathvars.redirect, request.pathvars.id)
              )
            } else {
              history.push('/')
            }
          }
        } else if (data.message && data.message === 'ACCOUNT_INACTIVE') {
          dispatch(loginError('login.error.inactive'))
        } else {
          dispatch(loginError('login.error.general'))
        }
      },
    ],
  },
  register: {
    url: `${API_URL}/users`,
    options: {
      headers,
      method: 'post',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          if (data.data.role === 'user') {
            _logEvent({
              category: 'Account',
              action: 'User account created',
              value: data.data.id,
            })
            dispatch(setGlobalMessage('globals.register.success', 'success'))
            history.push('/')
          } else if (data.data.role === 'company') {
            _logEvent({
              category: 'Account',
              action: 'Company account created',
              value: data.data.id,
            })
            dispatch(setGlobalMessage('globals.register.success', 'success'))
            history.push('/')
          }
        } else {
          dispatch(setGlobalMessage('globals.register.fail', 'error'))
        }
      },
    ],
  },
  requestPassword: {
    url: `${API_URL}/users/requestPassword`,
    options: {
      headers,
      method: 'post',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(
            setGlobalMessage('globals.requestPassword.success', 'success')
          )
          history.push('/')
        } else {
          dispatch(setGlobalMessage('globals.requestPassword.fail', 'error'))
        }
      },
    ],
  },
  resetPassword: {
    url: `${API_URL}/users/:id`,
    options: {
      headers: {
        // don't send authorization header
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'put',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(setGlobalMessage('globals.resetPassword.success', 'success'))
          history.push('/')
        } else {
          dispatch(setGlobalMessage('globals.resetPassword.fail', 'error'))
        }
      },
    ],
  },
  subscription: {
    crud: true,
    url: `${API_URL}/subscriptions/:id`,
    options: { headers },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          if (
            request.params.method === 'PUT' ||
            request.params.method === 'POST' ||
            request.params.method === 'DELETE'
          ) {
            dispatch(actions.userSubscriptions())
            dispatch(actions.subscription.reset())
          }
        } else {
          dispatch(setGlobalMessage('globals.subscription.fail', 'error'))
        }
      },
    ],
  },
  subscriptionFromListing: {
    url: `${API_URL}/subscriptions`,
    options: {
      headers,
      method: 'POST',
    },
  },
  subscriptions: {
    crud: true,
    url: `${API_URL}/subscriptions`,
    options: { headers },
  },
  thesisCreate: {
    url: `${API_URL}/theses`,
    options: {
      headers,
      method: 'POST',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        const file = _get(getState(), 'global.file')
        const id = data.data.id

        // if there is a file in state, upload it and append it to the job posting
        if (file) {
          const body = new FormData()
          body.append('file', file)

          dispatch(
            actions.uploadThesisPDF(
              { thesis: id },
              {
                body,
              }
            )
          )

          // TODO: also check if the file upload was successful!
          if (data.success) {
            dispatch(
              setGlobalMessage('globals.thesisCreate.success', 'success')
            )
            dispatch(resetFile())
            history.push(`theses/${id}`)
          } else {
            dispatch(setGlobalMessage('globals.thesisCreate.fail', 'error'))
          }
        } else {
          dispatch(setGlobalMessage('globals.thesisCreate.noFile', 'error'))
        }
      },
    ],
  },
  thesis: {
    crud: true,
    url: `${API_URL}/theses/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the company or an empty object or the error message if existent
      _merge(prevData, _get(response, 'data')) || _checkMessage(response) || {},
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (request.params && request.params.method === 'PUT') {
          if (!data.message) {
            dispatch(setGlobalMessage('globals.thesisEdit.success', 'success'))
            history.push(`/theses/${data.id}`)
          } else {
            dispatch(setGlobalMessage('globals.thesisEdit.fail', 'error'))
          }
        }
      },
    ],
  },
  theses: {
    virtual: true,
    url: `${API_URL}/theses`,
    options: { headers },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(thesesRequest())
        cb()
      },
    ],
    transformer: (response, prevData, action) =>
      // return the jobs array as data or an empty array or the error message if existent
      _get(response, 'data') || _checkMessage(response) || [],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        dispatch(thesesReceive(data))
      },
    ],
  },
  uploadLogo: {
    url: `${API_URL}/companies/uploadLogo`,
    options: {
      headers: {
        Authorization: `Bearer ${Cookies.get('JWT')}`,
        Accept: 'application/json',
      },
      method: 'post',
    },
  },
  uploadPDF: {
    url: `${API_URL}/jobs/uploadPDF`,
    options: {
      headers: {
        Authorization: `Bearer ${Cookies.get('JWT')}`,
        Accept: 'application/json',
      },
      method: 'post',
    },
  },
  uploadThesisPDF: {
    url: `${API_URL}/theses/uploadPDF`,
    options: {
      headers: {
        Authorization: `Bearer ${Cookies.get('JWT')}`,
        Accept: 'application/json',
      },
      method: 'post',
    },
  },
  user: {
    crud: true,
    url: `${API_URL}/users/:id`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the user or an empty object or the error message if existent
      _get(response, 'data') || _checkMessage(response) || {},
  },
  users: {
    url: `${API_URL}/users`,
    options: { headers },
    transformer: (response, prevData, action) =>
      // return the users array as data or an empty array or the error message if existent
      _get(response, 'data') || _checkMessage(response) || [],
  },

  /* ----- REFACTORED ----- */
  companyDataInit: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'GET',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyDataGeneralRequest())
        dispatch(companyDataContactRequest())
        dispatch(companyDataInvoiceRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(
            companyDataGeneralSuccess({
              init: true,
              data: { id: data.data.id, ...data.data.company },
            })
          )
          dispatch(
            initialize(
              'accountCompanyGeneral',
              {
                ...data.data.company,
              },
              ['name', 'url', 'description', 'street', 'plz', 'city', 'country']
            )
          )

          dispatch(
            companyDataContactSuccess({ init: true, data: data.data.contact })
          )
          dispatch(
            initialize(
              'accountCompanyContact',
              {
                ...data.data.contact,
              },
              ['gender', 'name', 'phone']
            )
          )

          dispatch(
            companyDataInvoiceSuccess({
              init: true,
              data: data.data.invoice || null,
            })
          )
          dispatch(
            initialize(
              'accountCompanyInvoice',
              {
                ...data.data.company,
                ...data.data.contact,
                ...data.data.invoice,
              },
              [
                'gender',
                'name',
                'email',
                'phone',
                'street',
                'plz',
                'city',
                'country',
              ]
            )
          )

          dispatch(companyDataInit())
        } else {
          dispatch(
            companyDataGeneralFailure({
              init: true,
              message: data.data.message,
            })
          )
          dispatch(
            companyDataContactFailure({
              init: true,
              message: data.data.message,
            })
          )
          dispatch(
            companyDataInvoiceFailure({
              init: true,
              message: data.data.message,
            })
          )
        }
      },
    ],
  },
  companyDataGeneral: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyDataGeneralRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(companyDataGeneralSuccess({ data: data.data.company }))
          dispatch(
            initialize(
              'accountCompanyGeneral',
              {
                ...data.data.company,
              },
              ['name', 'url', 'description', 'street', 'plz', 'city', 'country']
            )
          )
        } else {
          dispatch(companyDataGeneralFailure({ message: data.data.message }))
        }
      },
    ],
  },
  companyDataContact: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyDataContactRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(companyDataContactSuccess({ data: data.data.contact }))
          dispatch(
            initialize(
              'accountCompanyContact',
              {
                ...data.data.contact,
              },
              ['gender', 'name', 'phone']
            )
          )
        } else {
          dispatch(companyDataContactFailure({ message: data.data.message }))
        }
      },
    ],
  },
  companyDataInvoice: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyDataInvoiceRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(
            companyDataInvoiceSuccess({ data: data.data.invoice || null })
          )
          dispatch(
            initialize(
              'accountCompanyInvoice',
              {
                ...data.data.company,
                ...data.data.contact,
                ...data.data.invoice,
              },
              [
                'gender',
                'name',
                'email',
                'phone',
                'street',
                'plz',
                'city',
                'country',
              ]
            )
          )
        } else {
          dispatch(companyDataInvoiceFailure({ message: data.data.message }))
        }
      },
    ],
  },
  companyJobs: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'GET',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyJobsRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(companyJobsSuccess({ jobs: data.data.jobs }))
        } else {
          dispatch(companyJobsFailure({ message: data.data.message }))
        }
      },
    ],
  },
  companyProfile: {
    crud: true,
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyProfileRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(
            companyProfileSuccess({
              method: request.params.method,
              about: data.data.company.about,
            })
          )

          // hack: also dispatch general success to reload the first steps counter!
          dispatch(companyDataGeneralSuccess({ data: data.data.company }))
        } else {
          dispatch(
            companyProfileFailure({
              method: request.params.method,
              message: data.data.message,
            })
          )
        }
      },
    ],
  },
  companyTheses: {
    virtual: true,
    url: `${API_URL}/companies/:id`,
    options: {
      headers,
      method: 'GET',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(companyThesesRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(companyThesesSuccess({ theses: data.data.theses }))
        } else {
          dispatch(companyThesesFailure({ message: data.data.message }))
        }
      },
    ],
  },
  userDataInit: {
    virtual: true,
    url: `${API_URL}/users/:id`,
    options: {
      headers,
      method: 'GET',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(userDataGeneralRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(userDataGeneralSuccess({ init: true, data: data.data }))
          dispatch(
            initialize(
              'accountUserGeneral',
              {
                email: data.data.username,
                language: data.data.language,
              },
              ['email', 'language']
            )
          )
          dispatch(userDataInit())
        } else {
          dispatch(
            userDataGeneralFailure({ init: true, message: data.data.message })
          )
        }
      },
    ],
  },
  userDataGeneral: {
    virtual: true,
    url: `${API_URL}/users/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(userDataGeneralRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(userDataGeneralSuccess({ data: data.data }))
          dispatch(
            initialize(
              'accountUserGeneral',
              {
                email: data.data.username,
                language: data.data.language,
              },
              ['email', 'language']
            )
          )
        } else {
          dispatch(userDataGeneralFailure({ message: data.data.message }))
        }
      },
    ],
  },
  userDataPassword: {
    virtual: true,
    url: `${API_URL}/users/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(userDataPasswordRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(userDataPasswordSuccess({}))
        } else {
          dispatch(userDataPasswordFailure({ message: data.data.message }))
        }
      },
    ],
  },
  userSubscription: {
    virtual: true,
    crud: true,
    url: `${API_URL}/subscriptions/:id`,
    options: { headers },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(userSubscriptionRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(
            userSubscriptionSuccess({
              method: request.params.method,
              subscription: data.data,
            })
          )
        } else {
          dispatch(
            userSubscriptionFailure({
              method: request.params.method,
              message: data.data.message,
            })
          )
        }
      },
    ],
  },
  userSubscriptions: {
    virtual: true,
    url: `${API_URL}/subscriptions`,
    options: {
      headers,
      method: 'GET',
    },
    prefetch: [
      ({ actions, dispatch, getState }, cb) => {
        dispatch(userSubscriptionsRequest())
        cb()
      },
    ],
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(userSubscriptionsSuccess({ subscriptions: data.data }))
        } else {
          dispatch(userSubscriptionsFailure({ message: data.data.message }))
        }
      },
    ],
  },
  jobUpdateProcessed: {
    url: `${API_URL}/jobs/:id`,
    options: {
      headers,
      method: 'PUT',
    },
    postfetch: [
      ({ data, actions, dispatch, getState, request }) => {
        if (data.success) {
          dispatch(actions.adminJobsApproved())
        }
      },
    ],
  },
}).use('fetch', adapterFetch(fetch))
