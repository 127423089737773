import * as Actions from '../actions/account'
import RichTextEditor from 'react-rte'

const defaultState = {
  sync: false,
  loading: false,
  success: false,
}
const defaultRequest = {
  loading: true,
  success: false,
  displaySuccess: false,
  displayError: false,
}
const defaultSuccess = {
  sync: true,
  loading: false,
  success: true,
  displayError: false,
}
const defaultError = {
  sync: true,
  loading: false,
  success: false,
  displaySuccess: false,
}

const _defaultRequest = state => ({ ...state, ...defaultRequest })
const _defaultSuccess = (state, data = null) => ({
  ...state,
  ...defaultSuccess,
  data,
})
const _defaultFailure = (state, data = null) => ({
  ...state,
  ...defaultError,
  data,
})

const companyDataGeneralReducer = (
  state = { ...defaultState, data: {} },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_DATA_GENERAL_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_DATA_GENERAL_SUCCESS:
      return {
        ..._defaultSuccess(state, action.payload.data),
        displaySuccess: !action.payload.init,
      }

    case Actions.COMPANY_DATA_GENERAL_FAILURE:
      return {
        ..._defaultFailure(state, action.payload.message),
        displayError: !action.payload.init,
      }

    default:
      return state
  }
}
const companyDataContactReducer = (
  state = { ...defaultState, data: {} },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_DATA_CONTACT_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_DATA_CONTACT_SUCCESS:
      return {
        ..._defaultSuccess(state, action.payload.data),
        displaySuccess: !action.payload.init,
      }

    case Actions.COMPANY_DATA_CONTACT_FAILURE:
      return {
        ..._defaultFailure(state, action.payload.message),
        displayError: !action.payload.init,
      }

    default:
      return state
  }
}
const companyDataInvoiceReducer = (
  state = { ...defaultState, data: {} },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_DATA_INVOICE_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_DATA_INVOICE_SUCCESS:
      return {
        ..._defaultSuccess(state, action.payload.data),
        displaySuccess: !action.payload.init,
      }

    case Actions.COMPANY_DATA_INVOICE_FAILURE:
      return {
        ..._defaultFailure(state, action.payload.message),
        displayError: !action.payload.init,
      }

    default:
      return state
  }
}
const companyDataReducer = (
  state = {
    init: false,
    general: companyDataGeneralReducer(),
    contact: companyDataContactReducer(),
    invoice: companyDataInvoiceReducer(),
  },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_DATA_INIT:
      return {
        ...state,
        init: true,
      }

    case Actions.COMPANY_DATA_GENERAL_REQUEST:
    case Actions.COMPANY_DATA_GENERAL_SUCCESS:
    case Actions.COMPANY_DATA_GENERAL_FAILURE:
      return {
        ...state,
        general: companyDataGeneralReducer(state.general, action),
      }

    case Actions.COMPANY_DATA_CONTACT_REQUEST:
    case Actions.COMPANY_DATA_CONTACT_SUCCESS:
    case Actions.COMPANY_DATA_CONTACT_FAILURE:
      return {
        ...state,
        contact: companyDataContactReducer(state.contact, action),
      }

    case Actions.COMPANY_DATA_INVOICE_REQUEST:
    case Actions.COMPANY_DATA_INVOICE_SUCCESS:
    case Actions.COMPANY_DATA_INVOICE_FAILURE:
      return {
        ...state,
        invoice: companyDataInvoiceReducer(state.invoice, action),
      }

    default:
      return state
  }
}

const companyProfileReducer = (
  state = {
    sync: false,
    loading: false,
    success: false,
    displaySuccess: false,
    displayError: false,
    data: RichTextEditor.createEmptyValue(),
  },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_PROFILE_UPDATE:
      return {
        ...state,
        data: action.payload,
      }

    case Actions.COMPANY_PROFILE_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        ...defaultSuccess,
        displaySuccess: action.payload.method === 'PUT',
        data: RichTextEditor.createValueFromString(
          action.payload.about,
          'html'
        ),
      }

    case Actions.COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        ...defaultError,
        displayError: action.payload.method === 'PUT',
        data: action.payload.message,
      }

    default:
      return state
  }
}

const companyJobsReducer = (state = { ...defaultState, data: [] }, action) => {
  switch (action && action.type) {
    case Actions.COMPANY_JOBS_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_JOBS_SUCCESS:
      return {
        ...state,
        ...defaultSuccess,
        displaySuccess: true,
        data: action.payload.jobs,
      }

    case Actions.COMPANY_JOBS_FAILURE:
      return {
        ...state,
        ...defaultError,
        displayError: true,
        data: action.payload.message,
      }

    default:
      return state
  }
}

const companyThesesReducer = (
  state = { ...defaultState, data: [] },
  action
) => {
  switch (action && action.type) {
    case Actions.COMPANY_THESES_REQUEST:
      return _defaultRequest(state)

    case Actions.COMPANY_THESES_SUCCESS:
      return {
        ...state,
        ...defaultSuccess,
        displaySuccess: true,
        data: action.payload.theses,
      }

    case Actions.COMPANY_THESES_FAILURE:
      return {
        ...state,
        ...defaultError,
        displayError: true,
        data: action.payload.message,
      }

    default:
      return state
  }
}

const userDataGeneralReducer = (
  state = { ...defaultState, data: {} },
  action
) => {
  switch (action && action.type) {
    case Actions.USER_DATA_GENERAL_REQUEST:
      return _defaultRequest(state)

    case Actions.USER_DATA_GENERAL_SUCCESS:
      return {
        ..._defaultSuccess(state, action.payload.data),
        displaySuccess: !action.payload.init,
      }

    case Actions.USER_DATA_GENERAL_FAILURE:
      return {
        ..._defaultFailure(state, action.payload.message),
        displayError: !action.payload.init,
      }

    default:
      return state
  }
}
const userDataPasswordReducer = (
  state = { ...defaultState, sync: true },
  action
) => {
  switch (action && action.type) {
    case Actions.USER_DATA_PASSWORD_REQUEST:
      return _defaultRequest(state)

    case Actions.USER_DATA_PASSWORD_SUCCESS:
      return {
        ...defaultSuccess,
        displaySuccess: !action.payload.init,
      }

    case Actions.USER_DATA_PASSWORD_FAILURE:
      return {
        ..._defaultFailure(state, action.payload.message),
        displayError: !action.payload.init,
      }

    default:
      return state
  }
}
const userDataReducer = (
  state = {
    init: false,
    general: userDataGeneralReducer(),
    password: userDataPasswordReducer(),
  },
  action
) => {
  switch (action && action.type) {
    case Actions.USER_DATA_INIT:
      return {
        ...state,
        init: true,
      }

    case Actions.USER_DATA_GENERAL_REQUEST:
    case Actions.USER_DATA_GENERAL_SUCCESS:
    case Actions.USER_DATA_GENERAL_FAILURE:
      return {
        ...state,
        general: userDataGeneralReducer(state.general, action),
      }

    case Actions.USER_DATA_PASSWORD_REQUEST:
    case Actions.USER_DATA_PASSWORD_SUCCESS:
    case Actions.USER_DATA_PASSWORD_FAILURE:
      return {
        ...state,
        password: userDataPasswordReducer(state.password, action),
      }

    default:
      return state
  }
}

const userSubscriptionsReducer = (
  state = { ...defaultState, data: [], activeSubscription: null },
  action
) => {
  switch (action && action.type) {
    case Actions.USER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
      }

    case Actions.USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...defaultSuccess,
        displaySuccess: true,
        activeSubscription: action.payload.subscription.id,
      }

    case Actions.USER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        ...defaultError,
        displayError: true,
        activeSubscription: action.payload.message,
      }

    case Actions.USER_SUBSCRIPTIONS_REQUEST:
      return _defaultRequest(state)

    case Actions.USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        ...defaultSuccess,
        data: action.payload.subscriptions,
      }

    case Actions.USER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        ...defaultError,
        data: action.payload.message,
      }

    default:
      return state
  }
}

const accountReducer = (
  state = {
    companyData: companyDataReducer(),
    companyJobs: companyJobsReducer(),
    companyProfile: companyProfileReducer(),
    companyTheses: companyThesesReducer(),
    userData: userDataReducer(),
    userSubscriptions: userSubscriptionsReducer(),
  },
  action
) => {
  switch (action.type) {
    // COMPANY DATA
    case Actions.COMPANY_DATA_INIT:
    case Actions.COMPANY_DATA_GENERAL_REQUEST:
    case Actions.COMPANY_DATA_GENERAL_SUCCESS:
    case Actions.COMPANY_DATA_GENERAL_FAILURE:
    case Actions.COMPANY_DATA_CONTACT_REQUEST:
    case Actions.COMPANY_DATA_CONTACT_SUCCESS:
    case Actions.COMPANY_DATA_CONTACT_FAILURE:
    case Actions.COMPANY_DATA_INVOICE_REQUEST:
    case Actions.COMPANY_DATA_INVOICE_SUCCESS:
    case Actions.COMPANY_DATA_INVOICE_FAILURE:
      return {
        ...state,
        companyData: companyDataReducer(state.companyData, action),
      }

    // COMPANY JOBS
    case Actions.COMPANY_JOBS_REQUEST:
    case Actions.COMPANY_JOBS_SUCCESS:
    case Actions.COMPANY_JOBS_FAILURE:
      return {
        ...state,
        companyJobs: companyJobsReducer(state.companyJobs, action),
      }

    // COMPANY PROFILE
    case Actions.COMPANY_PROFILE_UPDATE:
    case Actions.COMPANY_PROFILE_REQUEST:
    case Actions.COMPANY_PROFILE_SUCCESS:
    case Actions.COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        companyProfile: companyProfileReducer(state.companyProfile, action),
      }

    // COMPANY THESES
    case Actions.COMPANY_THESES_REQUEST:
    case Actions.COMPANY_THESES_SUCCESS:
    case Actions.COMPANY_THESES_FAILURE:
      return {
        ...state,
        companyTheses: companyThesesReducer(state.companyTheses, action),
      }

    // USER DATA
    case Actions.USER_DATA_INIT:
    case Actions.USER_DATA_GENERAL_REQUEST:
    case Actions.USER_DATA_GENERAL_SUCCESS:
    case Actions.USER_DATA_GENERAL_FAILURE:
    case Actions.USER_DATA_PASSWORD_REQUEST:
    case Actions.USER_DATA_PASSWORD_SUCCESS:
    case Actions.USER_DATA_PASSWORD_FAILURE:
      return {
        ...state,
        userData: userDataReducer(state.userData, action),
      }

    // USER SUBSCRIPTIONS
    case Actions.USER_SUBSCRIPTION_REQUEST:
    case Actions.USER_SUBSCRIPTION_SUCCESS:
    case Actions.USER_SUBSCRIPTION_FAILURE:
    case Actions.USER_SUBSCRIPTIONS_REQUEST:
    case Actions.USER_SUBSCRIPTIONS_SUCCESS:
    case Actions.USER_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        userSubscriptions: userSubscriptionsReducer(
          state.userSubscriptions,
          action
        ),
      }

    default:
      return state
  }
}

export default accountReducer
