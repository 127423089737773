import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import { compose } from 'recompose'
import { object, string } from 'yup'
import history from '../../history'
import _get from '../../../node_modules/lodash/get'
import rest from '../../settings/rest'
import { CenteredSegment } from '../../components/common/Layouts'
import { TranslatedInput } from '../../components/common/Helpers'
import { _redirect, _validateSchema } from '../../utils'
import _some from 'lodash/some'

class UserLogin extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  componentWillMount() {
    // if the user is already logged in and a redirect target is set, directly redirect
    if (this.props.login.username) {
      if (this.props.params.redirect) {
        history.push(
          _redirect(this.props.params.redirect, this.props.params.id)
        )
      } else {
        history.push('/')
      }
    }
  }

  _submit = (values, dispatch) => {
    dispatch(
      rest.actions.login(
        { redirect: this.props.params.redirect, id: this.props.params.id },
        {
          body: JSON.stringify({
            username: values.email,
            password: values.password,
          }),
        }
      )
    )
  }

  render() {
    const {
      intl,
      login,
      handleSubmit,
      submitting,
      fields: { email, password },
    } = this.props

    return (
      <CenteredSegment
        title={intl.formatMessage({ id: 'login.string.title' })}
        success={_get(login, 'success') ? 'success' : 'error'}
        message={_get(login, 'message')}
        bottomMessage={
          <Link to="/users/request">
            <FormattedMessage id="login.string.forgotPassword" />
          </Link>
        }
      >
        <form className="ui form" onSubmit={handleSubmit(this._submit)}>
          <TranslatedInput
            autoFocus
            type="email"
            form="common"
            id="email"
            error={email.error}
            {...email}
          />
          <TranslatedInput
            type="password"
            form="common"
            id="password"
            {...password}
          />
          <button
            className={classNames('ui button primary right floated', {
              loading: submitting,
            })}
            type="submit"
            disabled={_some([submitting, email.invalid, password.invalid])}
          >
            <FormattedMessage id="common.button.login" />
          </button>
        </form>
      </CenteredSegment>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: 'login',
      fields: ['email', 'password'],
      validate: _validateSchema(
        object().shape({
          email: string()
            .email()
            .required(),
          password: string().required(),
        })
      ),
    },
    state => ({
      login: state.global.login,
    })
  ),
  injectIntl
)(UserLogin)
