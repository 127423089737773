import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

// decode a token and return the content if it is valid, return false otherwise
export const _decodeToken = token => {
  let jwt

  // try to decode the token (throws if invalid format)
  try {
    jwt = jwtDecode(token)
  } catch (e) {
    // if decoding the token throws, we set it to false
    jwt = false
  }

  return jwt
}

export const _getToken = () => {
  const token = Cookies.get('JWT')
  const jwt = _decodeToken(token)

  return jwt || false
}
