import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'

import styles from './InfoBarContactElement.scss'

const InfoBarContactElement = ({ gender, name, email, phone }) => (
  <div styleName="container">
    <p>
      <i className="user icon" /> {name}
    </p>
    <p>
      <i className="mail icon" /> <a href={`mailto:${email}`}>{email}</a>
    </p>
    {phone && (
      <p>
        <i className="phone icon" /> {phone}
      </p>
    )}
  </div>
)

InfoBarContactElement.propTypes = {}

export default CSSModules(InfoBarContactElement, styles)
