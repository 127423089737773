import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames'

const Pagination = ({ currentPage, pageNumber, handlePaginate }) => {
  const pages = []
  for (let i = 1; i <= pageNumber; i++) {
    pages.push(
      <a
        className={classNames('item', { active: i === currentPage })}
        onClick={e => handlePaginate(i)}
      >
        {i}
      </a>
    )
  }

  return <div className="ui pagination menu">{pages}</div>
}

Pagination.propTypes = {}

export default Pagination
