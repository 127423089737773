import _sortBy from 'lodash/sortBy'

export const _translateOptions = (intl, type, options) =>
  _sortBy(
    options.map(option => ({
      ...option,
      label: intl.formatMessage({ id: `${type}.${option.label}.name` }),
    })),
    option => option.label
  )
