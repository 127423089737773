import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import { compose } from 'recompose'
import moment from 'moment'
import rest from '../../../settings/rest'
import CSSModules from 'react-css-modules'
import { SegmentTitle, TableList } from '../../common/Helpers'
import { Tr, Td } from 'reactable'
import { injectIntl, FormattedMessage } from 'react-intl'

import styles from './JobList.scss'

const getStatus = (from, until, active) => {
  if (active) {
    if (moment().isBefore(from)) {
      return {
        value: 3,
        name: 'coming',
      }
    }

    if (moment().isAfter(until)) {
      return {
        value: 0,
        name: 'old',
      }
    }

    return {
      value: 2,
      name: 'active',
    }
  }

  return {
    value: 4,
    name: 'inactive',
  }
}

class ThesisList extends React.Component {
  componentWillMount() {
    // if the user was not already synced, fetch the data
    if (this.props.login.companyId > 0) {
      // dispatch the get request for the user data
      this.props.dispatch(
        rest.actions.companyTheses({ id: this.props.login.companyId })
      )
    }
  }

  render() {
    const {
      theses: { sync, data },
      intl,
    } = this.props

    // translate the column names
    const status = intl.formatMessage({
        id: 'accountCompanyJobs.columnTitle.status',
      }),
      title = intl.formatMessage({
        id: 'accountCompanyJobs.columnTitle.title',
      }),
      type = intl.formatMessage({
        id: 'accountCompanyTheses.columnTitle.type',
      }),
      withJob = intl.formatMessage({
        id: 'accountCompanyTheses.columnTitle.withJob',
      }),
      from = intl.formatMessage({ id: 'accountCompanyJobs.columnTitle.from' }),
      until = intl.formatMessage({
        id: 'accountCompanyJobs.columnTitle.until',
      }),
      actions = intl.formatMessage({
        id: 'accountCompanyJobs.columnTitle.actions',
      })

    const columnTitles = [status, title, type, withJob, from, until]
    return (
      <div>
        <SegmentTitle bordered>
          <h2>
            <FormattedMessage id="userAccount.menu.theses" />
          </h2>
        </SegmentTitle>
        <TableList
          className="basic striped"
          isFetching={!sync}
          sortable={columnTitles}
          filterable={columnTitles}
          defaultSort={{ column: 'Status', direction: 'asc' }}
          button="accountCompanyTheses.button.newThesis"
          buttonLink="theses/create"
        >
          {sync &&
            data.map(item => {
              const state = getStatus(
                item.onlineFrom,
                item.onlineUntil,
                item.active
              )

              // translate the values
              const statusValue = intl.formatMessage({
                  id: `accountCompanyJobs.status.${state.name}`,
                }),
                typeValue = intl.formatMessage({
                  id: `thesisTypes.${
                    item.type === 'BA' ? 'bachelor' : 'master'
                  }.name`,
                })

              return (
                <Tr
                  key={item.id}
                  className={classNames({ disabled: state.value === 0 })}
                >
                  <Td
                    column={status}
                    className={classNames({
                      positive: state.value >= 2,
                      negative: state.value === 1,
                    })}
                    value={statusValue}
                  >
                    {statusValue}
                  </Td>
                  <Td column={title} value={item.title}>
                    <Link to={`/theses/${item.id}`}>
                      <span title={item.title}>
                        {item.title.length > 40
                          ? `${item.title.substr(0, 40)}...`
                          : item.title}
                      </span>
                    </Link>
                  </Td>
                  <Td column={type} value={typeValue}>
                    {typeValue}
                  </Td>
                  <Td column={withJob} value={item.withJob}>
                    {intl.formatMessage({
                      id: `common.string.${
                        item.withJob ? 'withJob' : 'withoutJob'
                      }`,
                    })}
                  </Td>
                  <Td column={from} value={item.onlineFrom}>
                    {moment(item.onlineFrom).format('DD.MM.YYYY')}
                  </Td>
                  <Td column={until} value={item.onlineUntil}>
                    {moment(item.onlineUntil).format('DD.MM.YYYY')}
                  </Td>
                  <Td column={actions}>
                    <span styleName="actions">
                      {state.value > 1 && (
                        <Link to={`/theses/${item.id}/edit`}>
                          <i className="configure icon" role="presentation" />
                          {intl.formatMessage({
                            id: 'accountCompanyJobs.action.edit',
                          })}
                        </Link>
                      )}
                    </span>
                  </Td>
                </Tr>
              )
            })}
        </TableList>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    login: state.global.login,
    theses: state.account2.companyTheses,
  })),
  injectIntl,
  CSSModules(styles)
)(ThesisList)
