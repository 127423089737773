import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { injectIntl, FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import {
  BasicInput,
  BasicSubmit,
  LanguageSelect,
  DividingHeader,
} from '../../../common/Helpers'
import classNames from 'classnames'
import { compose } from 'recompose'
import { _isInvalid } from '../../../../utils'

import styles from '../CompanyData/CompanyGeneral.scss'

const validation = require('validate.js')

const formName = 'accountUserGeneral'
const formFields = ['email', 'language']

const validate = values =>
  validation(values, {
    email: {
      presence: {
        message: '^formError.fieldRequired',
      },
      email: {
        message: '^formError.emailFormat',
      },
    },
    language: {
      presence: {
        message: '^formError.fieldRequired',
      },
    },
  })

class UserGeneral extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    success: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }

  render() {
    const {
      login,
      displaySuccess,
      displayError,
      message,
      success,
      loading,
      fields,
      handleSubmit,
      fields: { email, language },
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="ui clearing segment" styleName="container">
        <form
          className={classNames('ui form', {
            error: !loading && invalid,
            loading,
            success,
          })}
          onSubmit={handleSubmit}
        >
          <DividingHeader>
            <FormattedMessage id={`${formName}.string.title`} />
          </DividingHeader>
          {login.aai && (
            <div className="ui info message">
              <FormattedMessage id={`${formName}.string.aai`} />
            </div>
          )}
          {message && (
            <div className={classNames('ui message', { success })}>
              {message}
            </div>
          )}
          {!login.aai && (
            <BasicInput common required formName={formName} reduxForm={email} />
          )}
          <LanguageSelect reduxForm={language} />
          {displaySuccess && !loading && (
            <div styleName="success">
              <i className="green checkmark icon" />{' '}
              <FormattedMessage id="accountUserGeneral.string.updateSuccess" />
            </div>
          )}
          {displayError && !loading && (
            <div styleName="error">
              <i className="red flag icon" />{' '}
              <FormattedMessage id="accountUserGeneral.string.updateError" />
            </div>
          )}
          <BasicSubmit
            primary
            floated="right"
            disabled={loading || invalid}
            loading={loading}
          />
        </form>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate,
    },
    state => ({
      initialValues: {
        email: state.account2.userData.general.data.username,
        language: state.account2.userData.general.data.language,
      },
      login: state.global.login,
    })
  ),
  injectIntl,
  CSSModules(styles)
)(UserGeneral)
