import PropTypes from 'prop-types'
import React from 'react'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import GoogleMap from 'google-map-react'

import { GOOGLE_MAPS_API_KEY } from '../../../../../settings/_settings'

import styles from './InfoBarLocationElement.scss'

const InfoBarLocationElement = ({
  street,
  city,
  plz,
  country = 'CH',
  location,
}) => (
  <div styleName="container">
    {location && (
      <div styleName="map">
        <GoogleMap
          defaultCenter={[location.lat, location.lng]}
          defaultZoom={13}
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY,
            language: 'en',
          }}
        >
          <i className="pin icon" lat={location.lat} lng={location.lng} />
        </GoogleMap>
      </div>
    )}
    {street && <p>{street}</p>}
    {(city || plz) && (
      <p>
        <i
          className={classNames(
            'flag',
            country != '' ? country.toLowerCase() : 'ch'
          )}
        />
        {plz}
        {city && plz && ' '}
        {city && `${city}`}
      </p>
    )}
  </div>
)

InfoBarLocationElement.propTypes = {
  map: PropTypes.bool, // whether a map should be displayed (using provided address details)
  street: PropTypes.string,
  city: PropTypes.string,
  plz: PropTypes.integer,
  country: PropTypes.string,
}

export default CSSModules(InfoBarLocationElement, styles)
