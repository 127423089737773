import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import styles from './SingleJob.scss'

const SingleJob = ({ job }) => (
  <Link to={`/jobs/${job.id}`}>
    <div className="ui padded grid" styleName="container">
      <div className="row" styleName="top">
        <h3>{job.title}</h3>
      </div>
      <div className="row" styleName="bar">
        <div className="four wide computer four wide tablet eight wide mobile column">
          <FormattedMessage id={`education.${job.education.name}.name`} />
        </div>
        <div className="four wide computer four wide tablet eight wide mobile column">
          <FormattedMessage id={`type.${job.type.name}.name`} />
        </div>
        <div className="four wide computer four wide tablet eight wide mobile column">
          <FormattedMessage id={`category.${job.category.name}.name`} />
        </div>
        <div className="four wide computer four wide tablet eight wide mobile column">
          <FormattedMessage id="singleJob.string.onlineFrom" />{' '}
          {moment(job.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
        </div>
      </div>
    </div>
  </Link>
)

export default CSSModules(SingleJob, styles)
