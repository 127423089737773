import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import { compose } from 'recompose'
import { object, string } from 'yup'
import rest from '../../settings/rest'
import { TranslatedInput } from '../../components/common/Helpers'
import { CenteredSegment } from '../../components/common/Layouts'
import _get from 'lodash/get'
import { _validateSchema } from '../../utils'
import _some from 'lodash/some'

class UserRequest extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    requestPassword: PropTypes.shape({
      data: PropTypes.shape({
        message: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }

  componentWillMount() {
    this.props.dispatch(rest.actions.requestPassword.reset())
  }

  _submit = (values, dispatch) => {
    // submit the entered email address
    dispatch(
      rest.actions.requestPassword(
        {},
        {
          body: JSON.stringify({
            username: values.email,
          }),
        }
      )
    )
  }

  render() {
    const {
      intl,
      requestPassword,
      handleSubmit,
      submitting,
      fields: { email },
    } = this.props

    const success = _get(requestPassword, 'data.success')
    return (
      <CenteredSegment
        title={intl.formatMessage({ id: 'requestPassword.string.title' })}
        bottomMessage={
          !success && (
            <Link to="/users/login">
              <FormattedMessage id="requestPassword.string.login" />
            </Link>
          )
        }
      >
        {(!success && (
          <form className="ui form" onSubmit={handleSubmit(this._submit)}>
            <TranslatedInput
              autoFocus
              type="email"
              form="common"
              id="email"
              error={
                email.error ||
                _get(requestPassword, 'message') === 'INVALID_EMAIL'
              }
              {...email}
            />
            <button
              className={classNames('ui button primary right floated', {
                loading: submitting,
              })}
              type="submit"
              disabled={_some([submitting, email.invalid])}
            >
              <FormattedMessage id="common.button.submit" />
            </button>
          </form>
        )) || (
          <div className="ui success message">
            <FormattedMessage id="requestPassword.string.success" />
          </div>
        )}
      </CenteredSegment>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: 'requestPassword',
      fields: ['email'],
      validate: _validateSchema(
        object().shape({
          email: string()
            .email()
            .required(),
        })
      ),
    },
    state => ({
      requestPassword: state.requestPassword,
    })
  ),
  injectIntl
)(UserRequest)
