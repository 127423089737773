import { _getToken, _logException } from './'

// require the user to have one of the levels passed as params
// function returns the classic (nextState....) function
// usage => onEnter: _requireLevel(['/users/account'], 'admin'),
export const _requireLevel = (redirect = '/users/login', ...levels) => (
  nextState,
  transition,
  cb
) => {
  // get the token
  const jwt = _getToken()

  // if the role is not in the required roles, transition to login
  if (!jwt || levels.indexOf(jwt.role) === -1) {
    transition(redirect)
  }

  // return to normal routing
  cb()
}

// require the user to be logged out
export const _requireAnon = (redirect = '/users/account') => (
  nextState,
  transition,
  cb
) => {
  // get the token
  const jwt = _getToken()

  // if there was a token, redirect!
  if (jwt) {
    transition(redirect)
  }

  // return to normal routing
  cb()
}

// helper for creation of routes via import (dynamic loading)
export const _routeCreator = (
  routePath,
  component,
  onEnter = false,
  indexComponent = false,
  childRoutes = false
) => {
  // define a basic route without any additional parameters (index route, child routes, onEnter etc.)
  let result = {
    path: routePath,
    getComponent(location, cb) {
      component
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => {
          console.log(`Chunk loading failed: ${err} (${location}).`)
          _logException(err)
        })
    },
  }

  // extend the basic route with an index route (if defined)
  if (indexComponent) {
    result = Object.assign({}, result, {
      getIndexRoute(location, cb) {
        indexComponent
          .then(module => {
            cb(null, { component: module.default })
          })
          .catch(err => {
            console.log(`Chunk loading failed: ${err} (${location}).`)
            _logException(err)
          })
      },
    })
  }

  // extend the basic route with child routes (if defined)
  if (childRoutes) {
    result = Object.assign({}, result, {
      childRoutes,
    })
  }

  // extend the basic route with an onEnter action (if defined)
  if (onEnter) {
    result = Object.assign({}, result, {
      onEnter,
    })
  }

  return result
}
