import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import {
  InfoBarItem,
  LabelSelect,
  TranslatedSelect,
  DateInput,
  LabelCheckbox,
} from '../../../common/Helpers'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import _filter from 'lodash/filter'

// calculate the date of the next mailing that will be sent
const mailingDate = moment()
  .add(1, 'month')
  .format('[01.]MM.YYYY')
const InfoBarSettings = ({
  disabled,
  locale,
  thesisMode,
  editMode,
  agb,
  mailing,
  onlineFrom,
  onlineUntil,
  openFrom,
  openFromDate,
  submitting,
  invalid,
  file,
  fields,
  handleSubmit,
}) => {
  const errorFields = _filter(fields, item => item.error && item.name !== 'agb')

  return (
    <InfoBarItem loading={false} title="common.infobar.settings.title">
      <h3 className="ui dividing header">
        <FormattedMessage
          id={
            thesisMode
              ? 'thesisManage.string.whenShouldThesisBeOnline'
              : 'jobManage.string.whenShouldJobBeOnline'
          }
        />
      </h3>
      <p>
        <DateInput
          label="jobManage.field.onlineFrom.label"
          reduxForm={onlineFrom}
          locale={locale}
          disabled={editMode}
          min={moment().add(1, 'days')}
          max={moment()
            .add(2, 'months')
            .add(1, 'days')}
        />
      </p>
      <p>
        {editMode ? (
          <DateInput
            label="jobManage.field.onlineUntil.label"
            reduxForm={onlineUntil}
            locale={locale}
            min={moment()}
            max={moment(onlineFrom.value, 'YYYY-MM-DD').add(2, 'months')}
          />
        ) : (
          <DateInput
            label="jobManage.field.onlineUntil.label"
            reduxForm={onlineUntil}
            locale={locale}
            min={moment().add(8, 'days')}
            max={moment(onlineFrom.value, 'YYYY-MM-DD').add(2, 'months')}
          />
        )}
      </p>
      {!thesisMode && (
        <h3 className="ui dividing header">
          <FormattedMessage id="jobManage.string.jobAvailability" />
        </h3>
      )}
      {!thesisMode && (
        <p>
          <TranslatedSelect
            className="ui fluid dropdown"
            options={[
              { id: 'now', name: 'jobManage.select.openFrom.now' },
              {
                id: 'arrangement',
                name: 'jobManage.select.openFrom.arrangement',
              },
              { id: 'date', name: 'jobManage.select.openFrom.date' },
            ]}
            reduxForm={openFrom}
          />
        </p>
      )}
      {!thesisMode && (
        <p>
          <DateInput
            min={moment()}
            locale={locale}
            label="common.field.date.label"
            reduxForm={openFromDate}
            disabled={openFrom.value !== 'date'}
          />
        </p>
      )}
      {/* {!editMode && !thesisMode && (
        <h3 className="ui dividing header">
          <FormattedMessage id="jobManage.string.fcMailingTitle" />
        </h3>
      )}
      {!editMode && !thesisMode && (
        <p>
          <FormattedMessage
            id="jobManage.string.fcMailingDescription"
            values={{
              link: (
                <a href="http://www.financeclub.ch" target="_blank">
                  Finance Club
                </a>
              ),
              date: <strong>{mailingDate}</strong>,
            }}
          />
        </p>
      )}
      {!editMode && !thesisMode && (
        <p>
          <LabelCheckbox
            reduxForm={mailing}
            id="jobManage.field.fcMailing.label"
          />
        </p>
      )} */}
      {!editMode && !thesisMode && (
        <h3 className="ui dividing header">
          <FormattedMessage id="jobManage.string.pricingTitle" />
        </h3>
      )}
      {!editMode && !thesisMode && (
        <table className="ui very basic table">
          <tbody>
            <tr>
              <td>
                <FormattedMessage id="jobManage.string.pricing.jobPosting" />
              </td>
              <td>200.-</td>
            </tr>
            {/* {mailing && mailing.value && (
              <tr>
                <td>
                  <FormattedMessage
                    id="jobManage.string.pricing.fcMailing"
                    values={{ date: mailingDate }}
                  />
                </td>
                <td>250.-</td>
              </tr>
            )} */}
            <tr>
              <td>
                <strong>
                  <FormattedMessage id="jobManage.string.pricing.total" />
                </strong>
              </td>
              <td>
                {/* <strong>{mailing && mailing.value ? '450.-' : '200.-'}</strong> */}
                <strong>200.-</strong>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {!editMode && !thesisMode && agb && (
        <p>
          <LabelCheckbox
            reduxForm={agb}
            id="jobManage.field.acceptAGB.label"
            values={{
              agbLinkDE: (
                <Link to="about/agb" target="_blank">
                  Allgemeinen Geschäftsbedingungen (AGB)
                </Link>
              ),
              agbLinkEN: (
                <Link to="about/agb" target="_blank">
                  Terms & Conditions
                </Link>
              ),
            }}
          />
        </p>
      )}
      {!editMode && !thesisMode && agb && agb.error && (
        <div className="ui error message">
          <i className="flag icon" />
          <FormattedMessage id="jobManage.string.agbError" />
        </div>
      )}
      {(errorFields.length > 0 || (!file && !editMode)) && (
        <div className="ui error message">
          <div className="header">
            <i className="flag icon" />{' '}
            <FormattedMessage id="jobManage.string.generalError" />
          </div>
          <ul className="list">
            {errorFields.map(item => (
              <li>
                <FormattedMessage
                  id={`${thesisMode ? 'thesisManage' : 'jobManage'}.error.${
                    item.name
                  }`}
                />
              </li>
            ))}
            {!editMode && !file && (
              <li>
                <FormattedMessage
                  id={`${thesisMode ? 'thesisManage' : 'jobManage'}.error.file`}
                />
              </li>
            )}
          </ul>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <button
          className="ui secondary button"
          type="submit"
          disabled={disabled || submitting || invalid || (!file && !editMode)}
        >
          Submit
        </button>
      </form>
    </InfoBarItem>
  )
}

InfoBarSettings.propTypes = {}

export default InfoBarSettings
