import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import styles from './LabelInput.scss'

const LabelInput = ({ type = 'text', label, required, reduxForm }) => (
  <div
    className={classNames('ui labeled fluid small input', {
      error: reduxForm && reduxForm.error,
    })}
    styleName="container"
  >
    <div className="ui label">
      <FormattedMessage id={label} />
    </div>
    <input type={type} {...reduxForm} />
  </div>
)

LabelInput.propTypes = {}

export default CSSModules(LabelInput, styles)
