import * as Actions from '../actions/account'
import RichTextEditor from 'react-rte'

const accountCompanyReducer = (state = {}, action) => {
  switch (action.type) {
    case Actions.ACCOUNT_COMPANY_REQUEST:
      return {
        ...state,
        sync: {
          ...state.sync,
          [action.section]: false,
        },
      }

    case Actions.ACCOUNT_COMPANY_RECEIVE: {
      let data = action.company
      let invoice

      if (data.invoice) {
        invoice = {
          type: 'custom',
          ...data.invoice,
        }
      } else {
        invoice = {
          type: 'derived',
          ...data.contact,
          street: data.company.street,
          plz: data.company.plz,
          city: data.company.city,
        }
      }

      data = {
        ...data,
        invoice,
      }

      data.company.about = RichTextEditor.createValueFromString(
        data.company.about,
        'html'
      )

      return {
        ...state,
        sync: {
          general: true,
          contact: true,
          invoice: true,
          about: true,
        },
        data,
      }
    }

    case Actions.ACCOUNT_COMPANY_ABOUT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          company: {
            ...state.data.company,
            about: action.about,
          },
        },
      }

    case Actions.ACCOUNT_COMPANY_ABOUT_SYNC:
      return {
        ...state,
        sync: {
          ...state.sync,
          about: false,
        },
      }

    default:
      return state
  }
}

const accountReducer = (
  state = {
    company: {
      sync: {
        general: false,
        contact: false,
        invoice: false,
        about: false,
      },
      data: {
        company: {
          about: RichTextEditor.createEmptyValue(),
        },
      },
    },
  },
  action
) => {
  switch (action.type) {
    case Actions.ACCOUNT_COMPANY_ABOUT_SYNC:
    case Actions.ACCOUNT_COMPANY_ABOUT_UPDATE:
    case Actions.ACCOUNT_COMPANY_REQUEST:
    case Actions.ACCOUNT_COMPANY_RECEIVE:
      return {
        ...state,
        company: accountCompanyReducer(state.company, action),
      }

    default:
      return state
  }
}

export default accountReducer
