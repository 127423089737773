import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'

const LabelCheckbox = ({ intl, id, values, reduxForm }) => (
  <div className="ui checkbox">
    <input type="checkbox" {...reduxForm} />
    <label>
      <FormattedMessage id={id} values={values} />
    </label>
  </div>
)

LabelCheckbox.propTypes = {}

export default LabelCheckbox
