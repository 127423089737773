import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import rest from '../../../settings/rest'
import { compose } from 'recompose'
import { FILE_URL } from '../../../settings/_settings'
import {
  InfoBar,
  TranslatedSelect,
  PDFDisplay,
  SegmentTitle,
} from '../../common/Helpers'
import {
  InfoBarSettings,
  InfoBarContact,
  validateThesisDatesEdit,
} from '../../Jobs/JobManage/common'
import { _isInvalid } from '../../../utils'

import styles from './common/ThesisManage.scss'

const formName = 'thesisEdit'
const formFields = [
  'title',
  'type',
  'withJob',
  'contactGender',
  'contactName',
  'contactEmail',
  'contactPhone',
  'onlineFrom',
  'onlineUntil',
]

class ThesisEdit extends React.Component {
  static propTypes = {}

  componentWillMount() {
    this.props.dispatch(
      rest.actions.companyThesisEdit({ id: this.props.params.id })
    )
  }

  _updateJob = values => {
    const id = this.props.params.id

    if (id) {
      this.props.dispatch(
        rest.actions.thesis.put(
          { id },
          {
            body: JSON.stringify({
              title: values.title,
              type: values.type,
              with_job: values.withJob,
              contact_gender: values.contactGender,
              contact_name: values.contactName,
              contact_email: values.contactEmail,
              contact_phone: values.contactPhone,
              online_until: moment(values.onlineUntil, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ),
            }),
          }
        )
      )
    }
  }

  render() {
    const {
      intl,
      thesis,
      company,
      fields,
      fields: {
        title,
        type,
        withJob,
        contactGender,
        contactName,
        contactEmail,
        contactPhone,
        onlineFrom,
        onlineUntil,
      },
      handleSubmit,
      submitting,
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <SegmentTitle>
          <h1>
            <FormattedMessage id="thesisEdit.string.title" />
          </h1>
        </SegmentTitle>
        <div className="row ui segment" styleName="bigHeader">
          <img
            className="ui three wide computer four wide tablet sixteen wide mobile column middle aligned image"
            styleName="logo"
            src={
              company.sync
                ? `${FILE_URL}/img/logo-${this.props.global.login.companyId}.png`
                : ''
            }
          />
          <div
            className="thirteen wide computer twelve wide tablet sixteen wide mobile middle aligned column"
            styleName="mainContent"
          >
            <div>
              <h1>
                <input
                  styleName="inputTitle"
                  className={classNames({ error: title.error })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'thesisManage.field.thesisTitle.placeholder',
                  })}
                  {...title}
                />
              </h1>
              <h2>{company.sync && company.data.company.name}</h2>
              <h3 styleName="selectCategory">
                <TranslatedSelect
                  options={[
                    { id: 'BA', name: 'thesisTypes.bachelor.name' },
                    { id: 'MA', name: 'thesisTypes.master.name' },
                  ]}
                  reduxForm={type}
                />
                <TranslatedSelect
                  options={[
                    { id: 0, name: 'thesisListing.string.withoutJob' },
                    { id: 1, name: 'thesisListing.string.withJob' },
                  ]}
                  reduxForm={withJob}
                />
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="eleven wide computer eleven wide tablet only column"
            styleName="pdf"
          >
            <div className="ui info message">
              <i className="info icon" />
              <FormattedMessage id="common.string.pdfNotEditable" />
            </div>
            <PDFDisplay url={`pdf/thesis-${thesis.data.id}.pdf`} />
          </div>
          <div className="five wide computer five wide tablet sixteen wide mobile column">
            <InfoBar sync>
              <InfoBarContact
                contactGender={contactGender}
                contactName={contactName}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
              />
              <InfoBarSettings
                editMode
                thesisMode
                onlineFrom={onlineFrom}
                onlineUntil={onlineUntil}
                submitting={submitting}
                invalid={invalid}
                fields={fields}
                handleSubmit={handleSubmit(this._updateJob)}
              />
            </InfoBar>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate: validateThesisDatesEdit,
    },
    state => ({
      // connect to redux state
      company: state.company,
      global: state.global,
      thesis: state.companyThesisEdit,
    })
  ),
  injectIntl,
  CSSModules(styles)
)(ThesisEdit)
