import React from 'react'
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import Imprint from './Imprint'

import styles from './About.scss'

const ForStudents = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>
          <FormattedMessage id="footer.string.forStudents" />
        </h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="Allgemeine Informationen">
          <p>
            Jobeye.ch ist eine durch das{' '}
            <a href="http://www.bf.uzh.ch">Institut für Banking und Finance</a>{' '}
            der Universität Zürich geführte Stellenplattform. Primäres Ziel von
            Jobeye.ch ist es, hochqualifizierte Studierende und Absolvierende
            mit den führenden Unternehmen aus Finanz und Wirtschaft zu
            verbinden. Ferner vermittelt Jobeye.ch praxisnahe Bachelor- und
            Masterarbeiten in Zusammenarbeit mit führenden Unternehmen aus
            Finanz und Wirtschaft.
          </p>
          <p>
            Als eingeloggter Benutzer profitierst Du gratis von folgenden
            Vorteilen auf Jobeye.ch:
          </p>
          <ul className="ui list">
            <li>
              Detailsuche nach Teilzeitjobs, Festanstellungen und Praktikas
            </li>
            <li>
              Abonnieren der neusten Jobangebote innerhalb definierter
              Suchkriterien
            </li>
          </ul>
          <p>
            Bei Fragen stehen wir Dir jederzeit gerne telefonisch (+41 44 634 50
            30) oder per E-Mail (
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) zur Verfügung.
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(ForStudents, styles)
