import PropTypes from 'prop-types'
import React from 'react'
import CSSModules from 'react-css-modules'
import ReactGA from 'react-ga'
import classNames from 'classnames'
import { Link } from 'react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as Settings from '../../../../settings/_settings'

import history from '../../../../history'
import styles from './SubscriptionButton.scss'

const SubscriptionButton = ({
  intl,
  disabled,
  loading,
  data: { success, data },
  onClick,
}) => {
  const categories = success ? JSON.parse(data.categories) : []
  const education = success ? JSON.parse(data.education) : []
  const types = success ? JSON.parse(data.types) : []

  const mapToMessage = ({ name }) => intl.formatMessage({ id: name })

  const categoryNames = Settings.categories
    .filter(c => categories.includes(c.id))
    .map(mapToMessage)
  const educationNames = Settings.educations
    .filter(e => education.includes(e.id))
    .map(mapToMessage)
  const typeNames = Settings.types
    .filter(t => types.includes(t.id))
    .map(mapToMessage)

  return (
    <div styleName="container">
      <div styleName="description">
        <FormattedMessage id="sidebar.string.createSubscriptionDescription" />
      </div>
      <div
        className={classNames('ui bottom secondary big fluid button', {
          disabled,
          loading,
        })}
        styleName="button"
        role="button"
        tabIndex="0"
        onClick={e => {
          if (disabled) {
            // users/account/subscriptions
            history.push('/users/login/editSubs')
          } else {
            ReactGA.event({
              category: 'Subscription',
              action: 'Created from JobListing',
            })
            onClick(e)
          }
        }}
      >
        <i className="mail icon" />
        <FormattedMessage id="sidebar.button.createSubscription" />
      </div>
      {disabled && (
        <div className="ui message" styleName="info">
          <div className="content">
            <p>
              <FormattedMessage
                id="sidebar.string.accountInformation"
                values={{
                  accountLink: <Link to="/users/register">Jobeye-Account</Link>,
                  aaiLink: (
                    <a
                      href="https://www.df.uzh.ch/AAILogin/jobeye/aai.php"
                      target="_blank"
                    >
                      AAI
                    </a>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      )}
      {success && (
        <div className="ui success message" styleName="info">
          <div className="content">
            <p>
              <strong>
                <FormattedMessage id="subscription.name" /> #{data.id}
              </strong>
            </p>
            <p>
              <FormattedMessage id="sidebar.string.subscriptionSuccessHeader" />
            </p>

            <ul styleName="subList">
              {data.query.length > 0 && (
                <li>
                  <strong>Query</strong>: {data.query}
                </li>
              )}
              <li>
                <strong>
                  <FormattedMessage id="rate.name" />
                </strong>
                : {data.min_rate}-{data.max_rate}%
              </li>
              {categories.length > 0 && (
                <li>
                  <strong>
                    <FormattedMessage id="category.name.plural" />
                  </strong>
                  : {categoryNames.join(', ')}
                </li>
              )}
              {education.length > 0 && (
                <li>
                  <strong>
                    <FormattedMessage id="education.name.plural" />
                  </strong>
                  : {educationNames.join(', ')}
                </li>
              )}
              {types.length > 0 && (
                <li>
                  <strong>
                    <FormattedMessage id="type.name.plural" />
                  </strong>
                  : {typeNames.join(', ')}
                </li>
              )}
            </ul>
            <p>
              <FormattedMessage
                id="sidebar.string.subscriptionSuccessFooter"
                values={{
                  subLink: (
                    <Link to="/users/account/subscriptions">Account</Link>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

SubscriptionButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default injectIntl(CSSModules(SubscriptionButton, styles))
