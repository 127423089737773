import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import { InfoBarItem, LabelInput, CountrySelect } from '../../../common/Helpers'

const InfoBarLocation = ({
  locationStreet,
  locationPlz,
  locationCity,
  locationCountry,
}) => (
  <InfoBarItem loading={false} title="common.infobar.location.title">
    <div className="ui info message">
      <i className="info icon" />
      <FormattedMessage id="jobManage.string.publiclyVisible" />
    </div>
    <p>
      <LabelInput
        label="common.field.street.label"
        reduxForm={locationStreet}
      />
    </p>
    <p>
      <LabelInput label="common.field.plz.label" reduxForm={locationPlz} />
    </p>
    <p>
      <LabelInput label="common.field.city.label" reduxForm={locationCity} />
    </p>
    <p>
      <CountrySelect labelLeft reduxForm={locationCountry} />
    </p>
  </InfoBarItem>
)

InfoBarLocation.propTypes = {}

export default InfoBarLocation
