import PropTypes from 'prop-types'
import React from 'react'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { educations, types } from '../../../../settings/_settings'
import { TranslatedSelect } from '../../../common/Helpers'

import styles from './TagInputs.scss'

const TagInputs = ({ education, rate, type, min, max }) => (
  <div className="ui equal width padded grid" styleName="tags">
    <div className="column ui label" styleName="tag">
      <TranslatedSelect
        className={classNames('ui dropdown', {
          error: !education || education.value === 0,
        })}
        options={[
          {
            name: 'education.name',
            id: 0,
          },
          ...educations,
        ]}
        reduxForm={education}
      />
    </div>
    <div className="column ui label" styleName="tag">
      <TranslatedSelect
        className={classNames('ui dropdown', {
          error: !rate || rate.value === 0,
        })}
        options={[
          {
            name: 'rate.name',
            id: 0,
          },
          {
            name: 'rate.fulltime.name',
            id: 1,
          },
          {
            name: 'rate.parttime.name',
            id: 2,
            min,
            max,
          },
        ]}
        reduxForm={rate}
      />
    </div>
    <div className="column ui label" styleName="tag">
      <TranslatedSelect
        className={classNames('ui dropdown', {
          error: !type || type.value === 0,
        })}
        options={[
          {
            name: 'type.name',
            id: 0,
          },
          ...types,
        ]}
        reduxForm={type}
      />
    </div>
  </div>
)

TagInputs.propTypes = {}

export default CSSModules(TagInputs, styles)
