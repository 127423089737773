import PropTypes from 'prop-types';
import React from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'

import styles from './Tag.scss'

const Tag = ({ text, min, max }) => (
  <div className="column ui label" styleName="tag">
    {!min && !max && (
      <FormattedMessage id={`tag.${text}.name`} defaultMessage={text} />
    )}
    {(min || min === 0) && max && (min === max ? `${max}%` : `${min}-${max}%`)}
  </div>
)

Tag.propTypes = {
  text: PropTypes.string.isRequired, // the text of the tag
}

export default CSSModules(Tag, styles)
