/* eslint-disable prefer-destructuring */

export const BASE_URL = process.env.BASE_URL
export const API_URL = process.env.API_URL
export const FILE_URL = process.env.FILE_URL
export const GOOGLE_MAPS_API_KEY = process.env.G_MAPS_API_KEY
// export const SENTRY_DSN = process.env.SENTRY_DSN
export const VERSION = process.env.VERSION

const category = (name, id) => ({
  name: `category.${name}.name`,
  id,
})

const education = (name, id) => ({
  name: `education.${name}.name`,
  id,
})

const type = (name, id) => ({
  name: `type.${name}.name`,
  id,
})

export const categories = [
  category('admin', 7),
  category('alternativeInvestments', 3),
  category('bankingAndFinance', 1),
  category('consulting', 2),
  category('insurance', 4),
  category('it', 6),
  category('researchAndTeaching', 5),
]

export const educations = [
  education('none', 1),
  education('bachelor', 2),
  education('master', 3),
]

export const types = [
  type('permanent', 1),
  type('temporary', 2),
  type('internship', 3),
]

export const categoryItems = [
  { value: 7, label: 'admin' },
  { value: 3, label: 'alternativeInvestments' },
  { value: 1, label: 'bankingAndFinance' },
  { value: 2, label: 'consulting' },
  { value: 4, label: 'insurance' },
  { value: 6, label: 'it' },
  { value: 5, label: 'researchAndTeaching' },
]

export const educationItems = [
  { value: 1, label: 'none' },
  { value: 2, label: 'bachelor' },
  { value: 3, label: 'master' },
]

export const typeItems = [
  { value: 1, label: 'permanent' },
  { value: 2, label: 'temporary' },
  { value: 3, label: 'internship' },
]

export const thesisTypeItems = [
  { value: 'BA', label: 'bachelor' },
  { value: 'MA', label: 'master' },
]
