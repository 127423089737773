import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CSSModules from 'react-css-modules'
import rest from '../../../settings/rest'
import axios from 'axios'
import moment from 'moment'
import { compose } from 'recompose'
import { BASE_URL, FILE_URL, API_URL } from '../../../settings/_settings'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  SegmentBigHeader,
  InfoBar,
  InfoBarItem,
  InfoBarLocationElement,
  InfoBarContactElement,
  InfoBarDetailsElement,
  PDFDisplay,
  SocialButtons,
} from '../../common/Helpers'
import { InfoBarAdmin } from '../JobManage/common'
import _get from 'lodash/get'
import useCookie from '../../../utils/useCookie'
import history from '../../../history'

import styles from './JobSingle.scss'

function JobSingle(props) {
  useEffect(() => {
    if (props.params.id) {
      props.dispatch(rest.actions.job({ id: props.params.id }))
    }
  }, [])

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json', // preflight request!
  }
  const JWT = useCookie('JWT')
  if (JWT) {
    headers.Authorization = `Bearer ${JWT}`
  }

  const {
    login,
    job: {
      loading,
      data: {
        id,
        title,
        category,
        company,
        contact,
        education,
        rate,
        minRate,
        maxRate,
        type,
        openFrom,
        onlineFrom,
        onlineUntil,
        approved,
        active,
        owner,
      },
    },
    intl,
  } = props

  const openFromText =
    openFrom === 'now' || openFrom === 'arrangement'
      ? intl.formatMessage({ id: `jobSingle.string.openFromTag.${openFrom}` })
      : intl.formatMessage(
          { id: 'jobSingle.string.openFromTag.date' },
          { date: moment(openFrom, 'YYYY-MM-DD').format('DD.MM.YYYY') }
        )

  const pageUrl = `${BASE_URL}/jobs/${props.params.id}`

  const _toggleActive = isActive => {
    const id = props.params.id

    if (id) {
      props.dispatch(
        rest.actions.job.put(
          { id },
          {
            body: JSON.stringify({
              active: !isActive,
            }),
          }
        )
      )
    }
  }

  const _toggleApproval = isApproved => {
    const id = props.params.id

    if (id) {
      props.dispatch(
        rest.actions.job.put(
          { id },
          {
            body: JSON.stringify({
              approved: !isApproved,
            }),
          }
        )
      )
    }
  }

  function handleDeleteJob() {
    axios.delete(`${API_URL}/jobs/${id}`, { headers }).then(response => {
      console.log(response)
      history.push('/admin/jobs')
    })
  }

  if (!loading && !id) {
    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <div className="row" styleName="notFoundMessage">
          <div className="ui error message">
            <i className="flag icon" />
            <FormattedMessage id="jobSingle.string.notFound" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row ui horizontally padded grid" styleName="container">
      {owner && (!approved || !active) && (
        <div className="row" styleName="localMessage">
          <div className="ui error message">
            <i className="flag icon" />
            {!approved && (
              <FormattedMessage id="jobSingle.string.unapprovedJobPreview" />
            )}
            {approved && !active && (
              <FormattedMessage id="jobSingle.string.deactivatedJobPreview" />
            )}
          </div>
        </div>
      )}
      <SegmentBigHeader
        companyId={_get(company, 'id')}
        loading={loading}
        h1={title}
        h2={_get(company, 'name')}
        h2Link={`companies/${_get(company, 'id')}`}
      />
      <div className="reversed row">
        <PDFDisplay
          url={`pdf/job-${id}.pdf`}
          className="eleven wide computer only column"
          styleName="main"
        />
        <div
          className="five wide computer sixteen wide tablet sixteen wide mobile column"
          styleName="infobar"
        >
          <a href={`mailto:${_get(contact, 'email')}`}>
            <button
              className="ui fluid secondary icon button"
              styleName="applyNow"
            >
              <i className="mail icon" />
              <FormattedMessage id="jobSingle.infobar.button.apply" />
            </button>
          </a>
          <InfoBar sync>
            <InfoBarItem
              loading={loading}
              title="jobSingle.infobar.details.title"
            >
              <InfoBarDetailsElement
                thesis={false}
                category={_get(category, 'name')}
                education={_get(education, 'name')}
                type={_get(type, 'name')}
                openFrom={openFromText}
                minRate={minRate}
                maxRate={maxRate}
                downloadLink={!loading && `${FILE_URL}/pdf/job-${id}.pdf`}
              />
            </InfoBarItem>
            <InfoBarItem
              loading={loading}
              title="common.infobar.location.title"
            >
              <InfoBarLocationElement
                street={_get(contact, 'street')}
                city={_get(contact, 'city')}
                plz={_get(contact, 'plz')}
                country={_get(contact, 'country')}
                location={_get(contact, 'location')}
              />
            </InfoBarItem>
            <InfoBarItem loading={loading} title="common.infobar.contact.title">
              <InfoBarContactElement
                name={_get(contact, 'name')}
                email={_get(contact, 'email')}
                phone={_get(contact, 'phone')}
              />
            </InfoBarItem>
            {owner && (
              <InfoBarAdmin
                id={id}
                admin={login.role === 'admin'}
                onlineFrom={onlineFrom}
                onlineUntil={onlineUntil}
                approved={approved}
                active={active}
                toggleApprovalHandler={_toggleApproval}
                toggleActiveHandler={_toggleActive}
                onDeleteJob={handleDeleteJob}
              />
            )}
          </InfoBar>
          <SocialButtons
            url={pageUrl}
            title={title}
            description="Spannende Stelle auf Jobeye.ch!"
          />
        </div>
      </div>
    </div>
  )
}

export default compose(
  connect(state => ({
    login: state.global.login,
    job: state.job,
  })),
  injectIntl,
  CSSModules(styles)
)(JobSingle)
