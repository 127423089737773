import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import { Pagination, SubscriptionButton } from '../../common/Helpers'
import Sidebar from './Sidebar'
import { Listing, ListingItem } from '../../common/Layouts'
import { jobsPaginate } from '../../../actions/jobs'
import { Input } from 'semantic-ui-react'

import styles from './JobListing.scss'

class JobListing extends React.Component {
  static propTypes = {
    jobs: PropTypes.shape({
      sync: PropTypes.bool.isRequired,
      data: PropTypes.array.isRequired,
    }).isRequired,
  }

  componentWillMount() {
    this.props.dispatch(rest.actions.jobs.sync())
  }

  _paginate = pageNumber => {
    this.props.dispatch(jobsPaginate({ pageNumber }))
  }

  // _addSub = e => {
  //   e.preventDefault()

  //   if (this.props.login.role === 'admin' || this.props.login.role === 'user') {
  //     this.props.dispatch(
  //       rest.actions.subscriptionFromListing(
  //         {},
  //         {
  //           body: JSON.stringify({
  //             query: this.props.jobs.filters.query,
  //             min_rate: this.props.jobs.filters.rate.min,
  //             max_rate: this.props.jobs.filters.rate.max,
  //             categories: JSON.stringify(this.props.jobs.filters.categories),
  //             education: JSON.stringify(this.props.jobs.filters.education),
  //             types: JSON.stringify(this.props.jobs.filters.types),
  //           }),
  //         }
  //       )
  //     )
  //   }
  // }

  render() {
    const { intl, jobs, subscription } = this.props

    return (
      <div className="stackable row" styleName="container">
        <div
          id="sidebar"
          className="four wide computer five wide tablet sixteen wide mobile column"
        >
          <Sidebar />

          {jobs.paginated.length > 1 && (
            <Pagination
              currentPage={jobs.currentPage}
              pageNumber={jobs.paginated.length}
              handlePaginate={this._paginate}
            />
          )}
        </div>
        <div className="twelve wide computer eleven wide tablet column">
          {/* <SubscriptionButton
            intl={intl}
            loading={subscription.loading}
            data={subscription.data}
            onClick={e => this._addSub(e)}
          /> */}

          <Listing loading={!jobs.sync || jobs.loading}>
            {jobs.sync &&
              jobs.page.map(job => (
                <ListingItem
                  key={job.id}
                  title={job.title}
                  titleLink={`/jobs/${job.id}`}
                  subtitle={job.company.name}
                  subtitleLink={`/companies/${job.company.id}`}
                  companyId={job.company.id}
                  tags={[
                    { text: job.education.name },
                    {
                      text: job.rate === 1 ? 'fulltime' : 'parttime',
                      min: job.min_rate,
                      max: job.max_rate,
                    },
                    { text: job.type.name },
                  ]}
                  barTags={intl.formatMessage({
                    id: `category.${job.category.name}.name`,
                  })}
                  date={job.onlineFrom}
                />
              ))}
          </Listing>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    jobs: state.jobs,
    subscription: state.subscriptionFromListing,
  })),
  injectIntl,
  CSSModules(styles)
)(JobListing)
