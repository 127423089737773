import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const Steps = ({ steps: { active, items } }) => {
  const html = items.map((step, index) => (
    <div
      key={index}
      className={classNames('step', { active: index === active - 1 })}
    >
      <div className="content">
        <FormattedMessage id={step.name} />
      </div>
    </div>
  ))

  return (
    <div
      className={classNames('ui bottom attached steps', {
        two: items.length === 2,
        three: items.length === 3,
        four: items.length === 4,
      })}
    >
      {html}
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.shape({
    active: PropTypes.number.isRequired, // the number of the currently active step (1,2,3...)
    items: PropTypes.arrayOf(
      PropTypes.shape({
        // the list of all steps to be displayed
        name: PropTypes.string.isRequired, // the name/label of the step
      })
    ).isRequired,
  }).isRequired,
}

export default Steps
