import moment from 'moment'

const validation = require('validate.js')

validation.extend(validation.validators.datetime, {
  parse: value => moment(value),
  format: (value, options) => {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss'
    return moment(value).format(format)
  },
})

export const validate = values =>
  validation(values, {
    title: {
      presence: true,
    },
    education: {
      presence: true,
      numericality: {
        greaterThan: 0,
      },
    },
    rate: {
      presence: true,
      numericality: {
        greaterThan: 0,
      },
    },
    type: {
      presence: true,
      numericality: {
        greaterThan: 0,
      },
    },
    contactGender: {
      presence: true,
    },
    contactName: {
      presence: true,
    },
    contactEmail: {
      presence: true,
      email: true,
    },
    locationStreet: {
      presence: true,
    },
    locationPlz: {
      presence: true,
      numericality: {
        greaterThan: 1000,
        lessThan: 99999,
      },
    },
    locationCity: {
      presence: true,
    },
  })

export const validateThesis = values =>
  validation(values, {
    title: {
      presence: true,
    },
    type: {
      presence: true,
    },
    contactGender: {
      presence: true,
    },
    contactName: {
      presence: true,
    },
    contactEmail: {
      presence: true,
      email: true,
    },
  })

export const validateDatesEdit = values => ({
  ...validate(values),
  ...validation(values, {
    onlineUntil: {
      presence: true,
      datetime: {
        earliest: moment(values.onlineFrom),
        latest: moment(values.onlineFrom).add(2, 'months'),
      },
    },
  }),
})

export const validateDates = values => ({
  ...validateDatesEdit(values),
  ...validation(values, {
    onlineFrom: {
      presence: true,
      datetime: {
        earliest: moment(),
      },
    },
  }),
})

export const validateThesisDatesEdit = values => ({
  ...validateThesis(values),
  ...validation(values, {
    onlineUntil: {
      presence: true,
      datetime: {
        earliest: moment(values.onlineFrom),
        latest: moment(values.onlineFrom).add(2, 'months'),
      },
    },
  }),
})

export const validateThesisDates = values => ({
  ...validateThesisDatesEdit(values),
  ...validation(values, {
    onlineFrom: {
      presence: true,
      datetime: {
        earliest: moment(),
        latest: moment().add(2, 'months'),
      },
    },
  }),
})

export const validateAGB = values => {
  const errors = {
    ...validateDates(values),
  }

  if (!values.agb) {
    errors.agb = 'Please accept our Terms & Conditions.'
  }

  return errors
}
