import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import { SegmentTitle } from '../../common/Helpers'
import { FormattedMessage } from 'react-intl'
import CompanyGeneral from './CompanyData/CompanyGeneral'
import CompanyContact from './CompanyData/CompanyContact'
import CompanyInvoice from './CompanyData/CompanyInvoice'

class CompanyData extends React.Component {
  state = {
    file: null,
  }

  componentWillMount() {
    // if the user was not already synced, fetch the data
    if (this.props.login.companyId > 0 && !this.props.company.init) {
      this.props.dispatch(
        rest.actions.companyDataInit({ id: this.props.login.companyId })
      )
    }
  }

  _update = (body, type) => {
    const data = _omitBy(body, it => it === null)
    if (!_isEmpty(data)) {
      const body = { body: JSON.stringify(data) }

      switch (type) {
        case 'general':
          this.props.dispatch(
            rest.actions.companyDataGeneral(
              { id: this.props.login.companyId },
              body
            )
          )
          break

        case 'contact':
          this.props.dispatch(
            rest.actions.companyDataContact(
              { id: this.props.login.companyId },
              body
            )
          )
          break

        case 'invoice':
          this.props.dispatch(
            rest.actions.companyDataInvoice(
              { id: this.props.login.companyId },
              body
            )
          )
          break
      }
    }
  }

  _updateGeneral = values => {
    this._update(
      {
        name: values.name,
        url: values.url,
        description: values.description,
        street: values.street,
        plz: values.plz,
        city: values.city,
        country: values.country,
      },
      'general'
    )
  }

  _updateContact = values => {
    const required = {
      contact_gender: values.gender,
      contact_name: values.name,
    }

    this._update(
      values.phone && values.phone.length > 0
        ? {
            ...required,
            contact_phone: values.phone,
          }
        : required,
      'contact'
    )
  }

  _updateInvoice = values => {
    const required = {
      invoice_address: true,
      invoice_gender: values.gender,
      invoice_name: values.name,
      invoice_email: values.email,
      invoice_street: values.street,
      invoice_plz: values.plz,
      invoice_city: values.city,
      invoice_country: values.country,
    }

    this._update(
      values.phone && values.phone.length > 0
        ? {
            ...required,
            invoice_phone: values.phone,
          }
        : required,
      'invoice'
    )
  }

  render() {
    const {
      company: { general, contact, invoice },
    } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>
            <FormattedMessage id="userAccount.menu.company" />
          </h2>
        </SegmentTitle>

        {general.sync && !general.loading && (
          <CompanyGeneral
            displaySuccess={general.displaySuccess}
            displayError={general.displayError}
            onSubmit={this._updateGeneral}
          />
        )}
        {general.loading && <CompanyGeneral loading />}

        {contact.sync && !contact.loading && (
          <CompanyContact
            displaySuccess={contact.displaySuccess}
            displayError={contact.displayError}
            onSubmit={this._updateContact}
          />
        )}
        {contact.loading && <CompanyContact loading />}

        {invoice.sync && !invoice.loading && (
          <CompanyInvoice
            displaySuccess={invoice.displaySuccess}
            displayError={invoice.displayError}
            onSubmit={this._updateInvoice}
          />
        )}
        {invoice.loading && <CompanyInvoice loading />}
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    company: state.account2.companyData,
    login: state.global.login,
  }))
)(CompanyData)
