import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import CSSModules from 'react-css-modules'
import rest from '../../../settings/rest'
import { Listing, ListingItem } from '../../common/Layouts'
import Sidebar from './Sidebar'

import styles from './ThesisListing.scss'

class ThesisListing extends React.Component {
  componentWillMount() {
    this.props.dispatch(rest.actions.theses.sync())
  }

  render() {
    const { intl, theses } = this.props

    return (
      <div className="stackable row" styleName="container">
        <div
          id="sidebar"
          className="four wide computer five wide tablet sixteen wide mobile column"
        >
          <Sidebar />
        </div>
        <Listing
          className="twelve wide computer eleven wide tablet column"
          loading={!theses.sync || theses.loading}
        >
          {theses.sync &&
            theses.filtered.map(thesis => (
              <ListingItem
                key={thesis.id}
                title={thesis.title}
                titleLink={`/theses/${thesis.id}`}
                subtitle={thesis.company.name}
                subtitleLink={`/companies/${thesis.company.id}`}
                companyId={thesis.company.id}
                barTags={`${intl.formatMessage({
                  id: `thesisListing.string.${thesis.type}`,
                })} ${
                  thesis.withJob === '1'
                    ? intl.formatMessage({ id: 'thesisListing.string.withJob' })
                    : intl.formatMessage({
                        id: 'thesisListing.string.withoutJob',
                      })
                }`}
                date={thesis.onlineFrom}
              />
            ))}
        </Listing>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    theses: state.theses,
  })),
  injectIntl,
  CSSModules(styles)
)(ThesisListing)
