import React from 'react'
import { AttachedSegment } from '../../components/common/Helpers'
import Imprint from './Imprint'

const ForCompanies = () => (
  <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
    <AttachedSegment h1="Allgemeine Geschäftsbedingungen (AGB)">
      <p>
        Jobeye.ch bietet Ihnen die Möglichkeit, durch ein einzigartiges
        Dienstleistungsangebot Ihre Stelleninserate, Unternehmensprofile und
        Bachelor- oder Masterarbeiten optimal zu positionieren, um einen
        direkten Kontakt zu den Studierenden der Universität Zürich
        herzustellen. Mit der Nutzung unserer Dienstleistungen akzeptieren Sie
        die nachfolgenden Bedingungen unverändert und vollumfänglich:
      </p>

      <h2>1. Eröffnung eines Benutzerkontos</h2>
      <p>
        Die Registrierung und Eröffnung eines Benutzerkontos ist kostenlos und
        kann direkt über jobeye.ch vorgenommen werden. Jobeye.ch behält sich das
        Recht vor, die Angaben zu überprüfen und ein Konto aufgrund spezifischer
        Gründe abzulehnen resp. den Zugang des/der Auftraggebers/in für weitere
        Dienstleistungen zu sperren.
      </p>

      <h2>
        2. Aufschaltung von Stelleninseraten/wissenschaftlichen Arbeiten und
        Profilen
      </h2>
      <p>
        Sie können einfach und bequem ein Inserat über jobeye.ch platzieren und
        passende Dienstleistungsoptionen wählen. Sobald Sie Ihre Auswahl über
        jobeye.ch definitiv aufgeben, ist der Auftrag verbindlich und wird Ihren
        Angaben entsprechend ausgeführt. Änderungen am hochgeladenen PDF können
        nach Aufgabe des Inserates nicht mehr individuell vorgenommen werden. In
        besonderen Fällen kann jobeye.ch nachträglich Änderungen daran
        vornehmen. Eine vorzeitige Kündigung eines abgeschlossenen Vertrags ist
        ausgeschlossen. Insbesondere ist auch bei vorzeitiger Löschung oder
        Inaktivierung von Stelleninseraten oder Inseraten von wissenschaftlichen
        Arbeiten die volle Vergütung geschuldet.
      </p>

      <p>
        Für den Inhalt des Inserates sowie des Stellenprofils ist der/die
        Auftraggeber/in verantwortlich. Jedes Stelleninserat muss sich auf eine
        tatsächlich zu besetzende Stelle beziehen. Der/die Auftraggeber/in
        gewährleistet die Richtigkeit und Aktualität der im Inserat/Profil
        enthaltenen Informationen und verpflichtet sich, keine rechtswidrigen
        Inhalte, insbesondere Inhalte mit rassistischem, pornographischem oder
        persönlichkeitsverletzendem Charakter zu veröffentlichen. Jobeye.ch
        behält sich das Recht vor, Inserate zu löschen, falls diese gegen
        geltendes Recht verstossen oder unsittlich erscheinen.
      </p>

      <h2>3. Zahlungsabwicklung</h2>
      <p>
        Mit der definitiven Aufgabe eines Stelleninserates entsteht ein
        verbindlicher Auftrag. Ausgehend von Ihrer Dienstleistungsauswahl
        stellen wir am Ende jedes Monats, basierend auf unseren Preisen (zum
        Zeitpunkt des Auftragsabschlusses), den beanspruchten Service in
        Rechnung. Die Rechnungen sind innert 30 Tagen nach Erhalt der Rechnung
        ohne Abzüge zu bezahlen. Ab der zweiten Mahnung wird zusätzlich eine
        kostendeckende Mahngebühr in Rechnung gestellt.
      </p>

      <h2>4. Haftung von jobeye.ch</h2>
      <p>
        Jobeye.ch erbringt die Leistung mit vollster Sorgfalt und fachgerecht.
        Jedoch übernimmt jobeye.ch keine Haftung für allfällige
        Betriebsunterbrüche und/oder technische Störungen und deren
        Auswirkungen.
      </p>

      <h2>5. Datenschutz</h2>
      <p>
        Wir ermöglichen eine einfache Platzierung Ihrer Inserate und verlangen
        nur persönliche Angaben, welche für einen erfolgreichen
        Dienstleistungsservice zwingend notwendig sind. Wir garantieren für alle
        gespeicherten Angaben einen vertrauensvollen Umgang sowie eine streng
        vertrauliche Handhabung. Die von uns erhobenen Daten werden keinen
        Drittparteien zugänglich gemacht. Die Stellenplattform wird über einen
        sicheren Server in der Schweiz betrieben. Im Sinne der Datensicherheit
        wird Ihr Passwort verschlüsselt gespeichert; und durch technische sowie
        organisatorische Massnahmen wird ferner gewährleistet, dass Ihre
        gespeicherten Daten vor Verlust oder unberechtigtem Zugriff geschützt
        sind. Mit der Eröffnung eines Benutzerkontos erklären Sie sich mit
        diesen Grundsätzen einverstanden.
      </p>

      <h2>6. Diverse Bestimmungen</h2>
      <p>
        Jobeye.ch behält sich vor, diese AGB jederzeit zu ändern. Über
        Änderungen werden die Nutzer aber frühzeitig informiert. Gerichtstand
        ist die Schweiz.
      </p>
    </AttachedSegment>

    <Imprint />
  </div>
)

export default ForCompanies
