import React from 'react'
import CSSModules from 'react-css-modules'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import { FormattedMessage } from 'react-intl'
import Imprint from './Imprint'

import styles from './About.scss'

const ForCompanies = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>
          <FormattedMessage id="footer.string.forCompanies" />
        </h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="Job advertisement - Information">
          <p>
            Jobeye.ch is a job platform for part-time or fulltime jobs and
            internships maintained by the
            <a href="http://www.bf.uzh.ch">
              Department of Banking &amp; Finance
            </a>{' '}
            of the University of Zurich. Through its affiliation to the
            Department, the platform has its key advantages compared to other
            providers, such as a close and direct reference to students, PhD and
            alumni, especially in the field of banking, finance and business.
            The purpose of jobeye.ch is to bring highly qualified students or
            graduates in banking &amp; finance or business together with leading
            companies.
          </p>
          <p>
            Your potentially qualified employees can be easily reached across
            the different distribution channels:
          </p>
          <table className="ui celled table">
            <tbody>
              <tr>
                <td className="single line">
                  <strong>Online publication</strong>
                </td>
                <td>
                  The advertisement will be published on Jobeye.ch, which has
                  monthly around 1750 visitors, whereas a duration of max. 60
                  days can be selected. After your submission, the advertisement
                  will be reviewed and published within 24 hours weekdays.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Furthermore, there is the opportunity, that you can present your
            company portrait to the students, PhD and alumni. The portrait
            informs the user about career possibilities at your company and all
            vacancies at jobeye.ch are immediately linked with your portrait.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Job advertisement – Pricing">
          <p>
            Jobeye.ch offers you the following distribution channels for your
            advertisement
          </p>
          <table className="ui collapsing celled fluid table">
            <thead>
              <tr>
                <th>Online-Publication, Posting</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right aligned">CHF 200</td>
              </tr>
            </tbody>
          </table>
          <p>All prices are in CHF, excl. VAT.</p>
          <p>
            In case of questions or problems concerning the publication of your
            advertisement, we are available by phone (+41 44 634 50 30) or by
            e-mail (<a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>).
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Cooperation for theses">
          <p>
            Besides the job platform, Jobeye.ch mediates practical theses
            (bachelor - or master level) between students and companies. If your
            company is interested in a collaboration, please get in touch by
            phone (+41 44 634 50 30) or by mail (
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) with the
            jobeye.ch team. We can assist you with the elaboration of the topic,
            the mediation of highly qualified students and also the
            collaboration with the Chairs of the University of Zurich. Price on
            request.
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(ForCompanies, styles)
