import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import rest from '../../../settings/rest'
import moment from 'moment'
import { compose } from 'recompose'
import Dropzone from 'react-dropzone'
import { FILE_URL, categories } from '../../../settings/_settings'
import {
  InfoBar,
  TranslatedSelect,
  PDFDisplay,
  SegmentTitle,
} from '../../common/Helpers'
import { setFile, resetFile } from '../../../actions'
import {
  InfoBarSettings,
  InfoBarContact,
  validateThesisDates,
} from '../../Jobs/JobManage/common'
import { _isInvalid } from '../../../utils'

import styles from './common/ThesisManage.scss'

const formName = 'thesisCreate'
const formFields = [
  'title',
  'type',
  'withJob',
  'contactGender',
  'contactName',
  'contactEmail',
  'contactPhone',
  'onlineFrom',
  'onlineUntil',
]

class ThesisCreate extends React.Component {
  static propTypes = {}

  componentWillMount() {
    this.props.dispatch(
      rest.actions.companyThesisCreate({
        id: this.props.global.login.companyId,
      })
    )
  }

  _createThesis = values => {
    this.props.dispatch(
      rest.actions.thesisCreate(
        {},
        {
          body: JSON.stringify({
            company_id: this.props.global.login.companyId,
            type: values.type || 'BA',
            title: values.title,
            with_job: values.withJob || 0,
            contact_gender: values.contactGender,
            contact_name: values.contactName,
            contact_email: values.contactEmail,
            online_from: moment(values.onlineFrom, 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ),
            online_until: moment(values.onlineUntil, 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ),
          }),
        }
      )
    )
  }

  render() {
    const {
      intl,
      company,
      global,
      fields,
      fields: {
        title,
        type,
        withJob,
        contactGender,
        contactName,
        contactEmail,
        contactPhone,
        onlineFrom,
        onlineUntil,
      },
      handleSubmit,
      submitting,
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <SegmentTitle>
          <h1>
            <FormattedMessage id="thesisCreate.string.title" />
          </h1>
        </SegmentTitle>
        <div className="row ui segment" styleName="bigHeader">
          <img
            className="ui three wide computer four wide tablet sixteen wide mobile column middle aligned image"
            styleName="logo"
            src={
              company.sync
                ? `${FILE_URL}/img/logo-${this.props.global.login.companyId}.png`
                : 'https://placehold.it/135x125'
            }
          />
          <div
            className="thirteen wide computer twelve wide tablet sixteen wide mobile middle aligned column"
            styleName="mainContent"
          >
            <div>
              <h1>
                <input
                  autoFocus
                  styleName="inputTitle"
                  className={classNames({ error: title.error })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'thesisManage.field.thesisTitle.placeholder',
                  })}
                  {...title}
                />
              </h1>
              <h2>{company.sync && company.data.company.name}</h2>
              <h3 styleName="selectCategory">
                <TranslatedSelect
                  options={[
                    { id: 'BA', name: 'thesisTypes.bachelor.name' },
                    { id: 'MA', name: 'thesisTypes.master.name' },
                  ]}
                  reduxForm={type}
                />
                <TranslatedSelect
                  options={[
                    { id: 0, name: 'thesisListing.string.withoutJob' },
                    { id: 1, name: 'thesisListing.string.withJob' },
                  ]}
                  reduxForm={withJob}
                />
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="eleven wide computer eleven wide tablet only column"
            styleName="pdf"
          >
            <div className="ui icon message">
              <i className="internet explorer icon" />
              <div className="content">
                <div className="header">
                  <FormattedMessage id="common.string.internetExplorerTitle" />
                </div>
                <p>
                  <FormattedMessage id="common.string.internetExplorerDescription" />
                </p>
              </div>
            </div>
            <h1>
              {global.file ? (
                <div>
                  <FormattedMessage id="common.string.preview" />
                  <button
                    className="ui primary right floated button"
                    onClick={() => this.props.dispatch(resetFile())}
                  >
                    <FormattedMessage id="common.button.resetFile" />
                  </button>
                </div>
              ) : (
                <FormattedMessage id="common.string.pdfUpload" />
              )}
            </h1>
            <Dropzone
              onDrop={files => this.props.dispatch(setFile(files[0]))}
              className={styles.dropzone}
              activeClassName={styles.dropzoneActive}
            >
              {!global.file &&
                intl.formatMessage({ id: 'common.string.dropFileHere' })}
              {global.file && (
                <object data={global.file.preview} type="application/pdf" />
              )}
            </Dropzone>
          </div>
          <div className="five wide computer five wide tablet sixteen wide mobile column">
            <InfoBar sync>
              <InfoBarContact
                contactGender={contactGender}
                contactName={contactName}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
              />
              <InfoBarSettings
                thesisMode
                disabled={!global.file}
                onlineFrom={onlineFrom}
                onlineUntil={onlineUntil}
                submitting={submitting}
                invalid={invalid}
                fields={fields}
                file={global.file}
                handleSubmit={handleSubmit(this._createThesis)}
              />
            </InfoBar>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate: validateThesisDates,
    },
    state => ({
      // connect to redux state
      company: state.companyThesisCreate,
      global: state.global,
    })
  ),
  injectIntl,
  CSSModules(styles)
)(ThesisCreate)
