import _get from 'lodash/get'

export const _checkMessage = (
  response,
  successMessage = 'success',
  errorMessage
) => {
  // if the response contains an error message, return it
  if (_get(response, 'data.message')) {
    return { message: response.data.message }
  }
  if (errorMessage) {
    return { success: false, message: errorMessage }
  }
  return undefined
}
