import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import classNames from 'classnames'
import { compose } from 'recompose'
import moment from 'moment'
import CSSModules from 'react-css-modules'
import { SegmentTitle, TableList } from '../../common/Helpers'
import { Tr, Td } from 'reactable'
import { injectIntl, FormattedMessage } from 'react-intl'

import useAPI from '../../../utils/useAPI'
import styles from './JobList.scss'

const getStatus = (from, until, approved, active) => {
  if (approved) {
    if (active) {
      if (moment().isBefore(from)) {
        return {
          value: 3,
          name: 'coming',
        }
      }

      if (moment().isAfter(until)) {
        return {
          value: 0,
          name: 'old',
        }
      }

      return {
        value: 2,
        name: 'active',
      }
    }

    return {
      value: 4,
      name: 'inactive',
    }
  }

  return {
    value: 1,
    name: 'pending',
  }
}

function JobList({ intl, login }) {
  const [responseState, setResponseState] = useState({})

  const api = useAPI()

  useEffect(() => {
    api
      .get(`/companies/${login.companyId}`)
      .then(response => {
        setResponseState(response.data)
      })
      .catch(error => {
        setResponseState({ success: false, data: null, error })
      })
  }, [])

  // translate the column names
  const status = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.status',
  })

  const title = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.title',
  })
  const rate = intl.formatMessage({ id: 'accountCompanyJobs.columnTitle.rate' })
  const category = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.category',
  })
  const education = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.education',
  })
  const type = intl.formatMessage({ id: 'accountCompanyJobs.columnTitle.type' })
  const from = intl.formatMessage({ id: 'accountCompanyJobs.columnTitle.from' })
  const until = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.until',
  })
  const actions = intl.formatMessage({
    id: 'accountCompanyJobs.columnTitle.actions',
  })

  const columnTitles = [
    status,
    title,
    rate,
    category,
    education,
    type,
    from,
    until,
  ]

  const { success, data, error } = responseState

  return (
    <div>
      <SegmentTitle bordered>
        <h2>
          <FormattedMessage id="userAccount.menu.jobs" />
        </h2>
      </SegmentTitle>
      <TableList
        className="basic striped"
        isFetching={!success}
        sortable={columnTitles}
        filterable={columnTitles}
        defaultSort={{ column: 'Status', direction: 'asc' }}
        button="accountCompanyJobs.button.newJob"
        buttonLink="jobs/create"
      >
        {success &&
          data.jobs.map(item => {
            const state = getStatus(
              item.onlineFrom,
              item.onlineUntil,
              item.approved,
              item.active
            )

            // translate the values
            const statusValue = intl.formatMessage({
                id: `accountCompanyJobs.status.${state.name}`,
              }),
              categoryValue = intl.formatMessage({
                id: `category.${item.category.name}.name`,
              }),
              educationValue = intl.formatMessage({
                id: `education.${item.education.name}.name`,
              }),
              typeValue = intl.formatMessage({
                id: `type.${item.type.name}.name`,
              })

            return (
              <Tr
                key={item.id}
                className={classNames({ disabled: state.value === 0 })}
              >
                <Td
                  column={status}
                  className={classNames({
                    positive: state.value >= 2,
                    negative: state.value === 1,
                  })}
                  value={statusValue}
                >
                  {statusValue}
                </Td>
                <Td column={title} value={item.title}>
                  <Link to={`/jobs/${item.id}`}>
                    <span title={item.title}>
                      {item.title.length > 30
                        ? `${item.title.substr(0, 30)}...`
                        : item.title}
                    </span>
                  </Link>
                </Td>
                <Td column={category} value={categoryValue}>
                  {categoryValue}
                </Td>
                <Td column={education} value={educationValue}>
                  {educationValue}
                </Td>
                <Td column={type} value={typeValue}>
                  {typeValue}
                </Td>
                <Td column={rate}>
                  {item.minRate === item.maxRate
                    ? `${item.maxRate}%`
                    : `${item.minRate}-${item.maxRate}%`}
                </Td>
                <Td column={from} value={item.onlineFrom}>
                  {moment(item.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column={until} value={item.onlineUntil}>
                  {moment(item.onlineUntil, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column={actions}>
                  <span styleName="actions">
                    {state.value > 1 && (
                      <Link to={`/jobs/${item.id}/edit`}>
                        <i className="configure icon" role="presentation" />
                        {intl.formatMessage({
                          id: 'accountCompanyJobs.action.edit',
                        })}
                      </Link>
                    )}
                  </span>
                </Td>
              </Tr>
            )
          })}
      </TableList>
    </div>
  )
}

export default compose(
  connect(state => ({
    login: state.global.login,
    jobs: state.account2.companyJobs,
  })),
  injectIntl,
  CSSModules(styles)
)(JobList)
