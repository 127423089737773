import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import { InfoBarItem } from '../../../common/Helpers'
import { Link } from 'react-router'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'

const InfoBarAdmin = ({
  thesisMode,
  id,
  admin,
  onlineFrom,
  onlineUntil,
  approved,
  active,
  toggleActiveHandler,
  toggleApprovalHandler,
  intl,
  onDeleteJob,
}) => (
  <InfoBarItem loading={false} title="jobManage.infobar.admin.title">
    <p>
      <strong>
        <FormattedMessage id="jobManage.field.onlineFrom.label" />
      </strong>
      <br /> {moment(onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
    </p>
    <p>
      <strong>
        <FormattedMessage id="jobManage.field.onlineUntil.label" />
      </strong>
      <br /> {moment(onlineUntil, 'YYYY-MM-DD').format('DD.MM.YYYY')}
    </p>
    {(thesisMode || (!thesisMode && approved)) && (
      <Link to={thesisMode ? `theses/${id}/edit` : `jobs/${id}/edit`}>
        <Button secondary icon="white edit icon" />
      </Link>
    )}
    <Button
      secondary
      disabled={!approved}
      onClick={() => toggleActiveHandler(active)}
    >
      {active
        ? intl.formatMessage({ id: 'jobManage.button.deactivate' })
        : intl.formatMessage({ id: 'jobManage.button.activate' })}
    </Button>
    <Link to={`jobs/prolong/${id}`}>
      <Button secondary disabled={!approved}>
        <FormattedMessage id="jobManage.button.prolong" />
      </Button>
    </Link>

    {admin && !thesisMode && (
      <div style={{ marginTop: '0.5rem' }}>
        <Button icon="trash" onClick={onDeleteJob} />
        <Button onClick={() => toggleApprovalHandler(approved)}>
          {approved
            ? intl.formatMessage({ id: 'jobManage.button.unapprove' })
            : intl.formatMessage({ id: 'jobManage.button.approve' })}
        </Button>
      </div>
    )}
  </InfoBarItem>
)

InfoBarAdmin.propTypes = {}

export default injectIntl(InfoBarAdmin)
