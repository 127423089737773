import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import styles from './SingleJob.scss'

const SingleThesis = ({ thesis }) => (
  <Link to={`/theses/${thesis.id}`}>
    <div className="ui padded grid" styleName="container">
      <div className="row" styleName="top">
        <h3>{thesis.title}</h3>
      </div>
      <div className="row" styleName="bar">
        <div className="twelve wide computer twelve wide tablet sixteen wide mobile column">
          <FormattedMessage
            id={`thesisTypes.${
              thesis.type === 'BA' ? 'bachelor' : 'master'
            }.name`}
          />{' '}
          {thesis.withJob ? (
            <FormattedMessage id="thesisListing.string.withJob" />
          ) : (
            <FormattedMessage id="thesisListing.string.withoutJob" />
          )}
        </div>
        <div className="four wide computer four wide tablet sixteen wide mobile column">
          <FormattedMessage id="singleJob.string.onlineFrom" />{' '}
          {moment(thesis.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
        </div>
      </div>
    </div>
  </Link>
)

export default CSSModules(SingleThesis, styles)
