import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router'
import classNames from 'classnames'
import { Tr, Td } from 'reactable'
import { SegmentTitle, TableList } from '../../common/Helpers'
import rest from '../../../settings/rest'
import { compose } from 'recompose'

class JobList extends React.Component {
  static propTypes = {}

  componentWillMount() {
    // dynamically load content
    this.props.dispatch(rest.actions.adminJobsPending())
    this.props.dispatch(rest.actions.adminJobsApproved())
  }

  setProcessed = (jobId, processed) => {
    this.props.dispatch(
      rest.actions.jobUpdateProcessed(
        { id: jobId },
        {
          body: JSON.stringify({
            processed,
          }),
        }
      )
    )
  }

  render() {
    const { approved, pending } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>Job overview</h2>
        </SegmentTitle>
        <h3>Awaiting approval</h3>
        <TableList
          className="basic striped"
          isFetching={!pending.sync}
          sortable={[
            'ID',
            'Company',
            'Title',
            'Online until',
            'Online from',
            'Invoiced',
            'Price',
            'Created',
            'Modified',
          ]}
          filterable={['Company', 'Title', 'Online until', 'Online from']}
          defaultSort={{ column: 'ID', direction: 'desc' }}
          csvColumns={[
            'Company name',
            'Job title',
            'Online until',
            'Online from',
            'Invoiced',
            'Price',
            'Created',
            'Modified',
          ]}
          csvData={
            pending.sync &&
            pending.data.success &&
            pending.data.data.map(item => [
              item.company.name,
              item.title,
              item.onlineFrom,
              item.onlineUntil,
              item.invoiced,
              item.price,
              item.created,
              item.modified,
            ])
          }
          csvFilename="pendingJobs"
        >
          {pending.sync &&
            pending.data.success &&
            pending.data.data.map(item => (
              <Tr key={item.id}>
                <Td column="ID" value={item.id}>
                  {item.id}
                </Td>
                <Td column="Company" value={item.company.name}>
                  <Link to={`companies/${item.company.id}`}>
                    {item.company.name}
                  </Link>
                </Td>
                <Td column="Title" value={item.title}>
                  <Link to={`jobs/${item.id}`}>{item.title}</Link>
                </Td>
                <Td column="Online from" value={item.onlineFrom}>
                  {moment(item.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Online until" value={item.onlineUntil}>
                  {moment(item.onlineUntil, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Invoiced" value={item.company.invoiced}>
                  <i
                    className={classNames('ui icon', {
                      'red remove': !item.company.invoiced,
                      'green checkmark': item.company.invoiced,
                    })}
                  />
                </Td>
                <Td column="Created" value={item.created}>
                  {moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Modified" value={item.modified}>
                  {moment(item.modified, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="">
                  <Link to={`/jobs/${item.id}/edit`}>
                    <i className="configure icon" /> EDIT
                  </Link>
                </Td>
              </Tr>
            ))}
        </TableList>

        <h3>Approved jobs</h3>
        <TableList
          className="basic striped"
          isFetching={!approved.sync}
          sortable={[
            'ID',
            'Company',
            'Title',
            'Online until',
            'Online from',
            'Views',
            'Price',
            'Created',
            'Modified',
          ]}
          filterable={['Company', 'Title', 'Online until', 'Online from']}
          defaultSort={{ column: 'ID', direction: 'desc' }}
          csvColumns={[
            'Company name',
            'Job title',
            'Online until',
            'Online from',
            'Views',
            'Price',
            'Invoice name',
            'Invoice email',
            'Invoice phone',
            'Invoice street',
            'Invoice PLZ',
            'Invoice City',
            'Invoice country',
            'Created',
            'Modified',
          ]}
          csvData={
            approved.sync &&
            approved.data.success &&
            approved.data.data
              .filter(item => !item.processed)
              .map(item => [
                item.company.name,
                item.title,
                item.onlineFrom,
                item.onlineUntil,
                item.views,
                item.price,
                item.company.invoice_address
                  ? `${item.company.invoice_gender === 'M' ? 'Herr' : 'Frau'} ${
                      item.company.invoice_name
                    }`
                  : `${item.company.contact_gender === 'M' ? 'Herr' : 'Frau'} ${
                      item.company.contact_name
                    }`,
                item.company.invoice_address
                  ? item.company.invoice_email
                  : item.company.contact_email,
                item.company.invoice_address
                  ? item.company.invoice_phone || ''
                  : item.company.contact_phone || '',
                item.company.invoice_address
                  ? item.company.invoice_street
                  : item.company.street,
                item.company.invoice_address
                  ? item.company.invoice_plz
                  : item.company.plz,
                item.company.invoice_address
                  ? item.company.invoice_city
                  : item.company.city,
                item.company.invoice_address
                  ? item.company.invoice_country
                  : item.company.country,
                item.created,
                item.modified,
              ])
          }
          csvFilename="approvedJobs"
        >
          {approved.sync &&
            approved.data.success &&
            approved.data.data.map(item => (
              <Tr key={item.id}>
                <Td column="ID" value={item.id}>
                  {item.id}
                </Td>
                <Td column="Company" value={item.company.name}>
                  <Link to={`companies/${item.company.id}`}>
                    {item.company.name}
                  </Link>
                </Td>
                <Td column="Title" value={item.title}>
                  <Link to={`jobs/${item.id}`}>{item.title}</Link>
                </Td>
                <Td column="Online from" value={item.onlineFrom}>
                  {moment(item.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Online until" value={item.onlineUntil}>
                  {moment(item.onlineUntil, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Views" value={item.views}>
                  {item.views}
                </Td>
                <Td column="Price" value={item.price}>
                  {item.price}
                </Td>
                <Td column="Modified" value={item.modified}>
                  <span
                    title={`Created on ${moment(
                      item.created,
                      'YYYY-MM-DD'
                    ).format('DD.MM.YYYY')}`}
                  >
                    {moment(item.modified, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                  </span>
                </Td>
                <Td column="Inv." value={item.company.invoiced}>
                  <i
                    className={classNames('ui icon', {
                      'red remove': !item.company.invoiced,
                      'green checkmark': item.company.invoiced,
                    })}
                  />
                </Td>
                <Td column="Proc." value={item.processed}>
                  <input
                    type="checkbox"
                    className="ui checkbox"
                    checked={item.processed}
                    onChange={() => this.setProcessed(item.id, !item.processed)}
                  />
                </Td>
                <Td column="">
                  <Link to={`/jobs/${item.id}/edit`}>
                    <i className="configure icon" /> EDIT
                  </Link>
                </Td>
              </Tr>
            ))}
        </TableList>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    approved: state.adminJobsApproved,
    pending: state.adminJobsPending,
  }))
)(JobList)
