import PropTypes from 'prop-types';
import React from 'react';
import ReactSlider from 'react-slider'
import CSSModules from 'react-css-modules'
import Section from './Section'

import styles from './SliderSection.scss'

const SliderSection = ({ intlId, min, max, onChange, onAfterChange }) => (
  <Section intlId={intlId}>
    <div className="ui padded grid" styleName="container">
      <div className="row">
        <div className="two wide column" styleName="sideLeft">
          MIN
        </div>
        <div className="two wide right floated column" styleName="sideRight">
          MAX
        </div>
      </div>
      <div className="row">
        <div className="column">
          <ReactSlider
            min={0}
            max={100}
            defaultValue={[min, max]}
            minDistance={20}
            step={10}
            onChange={onChange}
            onAfterChange={onAfterChange}
          >
            <div className="handle">{min} %</div>
            <div className="handle">{max} %</div>
          </ReactSlider>
        </div>
      </div>
    </div>
  </Section>
)

SliderSection.propTypes = {
  intlId: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
}

export default CSSModules(SliderSection, styles)
