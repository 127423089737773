import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage, injectIntl } from 'react-intl'
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import rest from '../../../settings/rest'
import { compose } from 'recompose'
import moment from 'moment'
// import Dropzone from 'react-dropzone'
import { FILE_URL, categories } from '../../../settings/_settings'
import {
  InfoBar,
  TranslatedSelect,
  PDFDisplay,
  DateInput,
  SegmentTitle,
} from '../../common/Helpers'
import { setFile, resetFile } from '../../../actions'
import {
  TagInputs,
  InfoBarAdminSettings,
  InfoBarContact,
  InfoBarLocation,
  InfoBarSettings,
  validateAGB,
} from './common'
import { _isInvalid } from '../../../utils'

import styles from './common/JobManage.scss'

const formName = 'jobCreate'
const formFields = [
  'title',
  'category',
  'education',
  'rate',
  'minRate',
  'maxRate',
  'type',
  'openFrom',
  'openFromDate',
  'contactGender',
  'contactName',
  'contactEmail',
  'contactPhone',
  'locationStreet',
  'locationPlz',
  'locationCity',
  'locationCountry',
  'onlineFrom',
  'onlineUntil',
  'mailing',
  'mailingDate',
  'agb',
  'companyId',
  'publishImmediately',
]

class JobCreate extends React.Component {
  static propTypes = {}

  state = {
    preview: null,
    rate: {
      min: 40,
      max: 60,
    },
  }

  componentWillMount() {
    // check if the prolong flag was set, if yes, fetch the old data and initialize the form
    if (this.props.params.prolongId) {
      this.props.dispatch(
        rest.actions.companyJobProlong({ id: this.props.params.prolongId })
      )
    } else {
      this.props.dispatch(
        rest.actions.companyJobCreate({ id: this.props.global.login.companyId })
      )
    }

    this.props.dispatch(resetFile())
    this._updateRates([
      this.props.fields.minRate.value || 40,
      this.props.fields.maxRate.value || 60,
    ])
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.fields.minRate.value !== this.props.fields.minRate.value ||
      nextProps.fields.maxRate.value !== this.props.fields.maxRate.value
    ) {
      this._updateRates([
        nextProps.fields.minRate.value,
        nextProps.fields.maxRate.value,
      ])
    }
  }

  onChangeFile = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onload = ({ target }) => {
      this.props.dispatch(setFile(file))
      this.setState({
        preview: target.result,
      })
    }

    reader.onerror = () => {
      console.error('Failure while loading PDF.')
    }

    reader.readAsDataURL(file)
  }

  _createJob = values => {
    this.props.dispatch(
      rest.actions.jobCreate(
        {},
        {
          body: JSON.stringify({
            company_id: values.companyId || false,
            category_id: values.category,
            education_id: values.education,
            rate: values.rate,
            min_rate: values.rate == 2 ? this.state.rate.min : 100,
            max_rate: values.rate == 2 ? this.state.rate.max : 100,
            type_id: values.type,
            title: values.title,
            online_from: moment(values.onlineFrom, 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ),
            online_until: moment(values.onlineUntil, 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ),
            open_from:
              (values.openFrom === 'date'
                ? moment(values.openFromDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                : values.openFrom) || 'now',
            approved: true,
            mailing: values.mailing,
            custom_contact: true,
            contact_gender: values.contactGender,
            contact_name: values.contactName,
            contact_email: values.contactEmail,
            contact_phone: values.contactPhone,
            contact_street: values.locationStreet,
            contact_plz: values.locationPlz,
            contact_city: values.locationCity,
            contact_country: values.locationCountry,
            publish_immediately: values.publishImmediately || false,
          }),
        }
      )
    )
  }

  _updateRates = values => {
    const options = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

    const min = options.filter(option => option <= values[1])
    const max = options.filter(option => option >= values[0])

    const minOptions = min.map(option => (
      <option value={option}>{option} %</option>
    ))
    const maxOptions = max.map(option => (
      <option value={option}>{option} %</option>
    ))

    this.setState({
      rate: {
        min: values[0],
        max: values[1],
        minOptions,
        maxOptions,
      },
    })
  }

  render() {
    const {
      intl,
      job,
      global,
      fields,
      fields: {
        title,
        category,
        education,
        rate,
        minRate,
        maxRate,
        type,
        openFrom,
        openFromDate,
        contactGender,
        contactName,
        contactEmail,
        contactPhone,
        locationStreet,
        locationPlz,
        locationCity,
        locationCountry,
        onlineFrom,
        onlineUntil,
        mailing,
        mailingDate,
        agb,
        companyId,
        publishImmediately,
      },
      handleSubmit,
      submitting,
    } = this.props
    const invalid = _isInvalid(fields)

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <SegmentTitle>
          <h1>
            <FormattedMessage
              id={
                this.props.params.prolongId
                  ? 'jobProlong.string.title'
                  : 'jobCreate.string.title'
              }
            />
          </h1>
        </SegmentTitle>
        <div className="row ui segment" styleName="bigHeader">
          <img
            alt="Logo"
            className="ui three wide computer four wide tablet sixteen wide mobile column middle aligned image"
            styleName="logo"
            src={
              global &&
              global.login &&
              `${FILE_URL}/img/logo-${global.login.companyId}.png`
            }
          />
          <div
            className="thirteen wide computer twelve wide tablet sixteen wide mobile middle aligned column"
            styleName="mainContent"
          >
            <div>
              <TagInputs
                education={education}
                rate={rate}
                type={type}
                min={this.state.rate.min}
                max={this.state.rate.max}
              />
              {rate.value == 2 && (
                <div className="ui segment" styleName="selectRates">
                  <h4 className="ui dividing header">
                    <FormattedMessage id="jobManage.field.rate.title" />
                  </h4>
                  <span className="ui labeled input" styleName="selectMinRate">
                    <label className="ui label">
                      <FormattedMessage id="jobManage.field.rate.min" />
                    </label>
                    <select
                      className={classNames('ui dropdown', {
                        disabled: !(rate.value == 2),
                      })}
                      disabled={!(rate.value == 2)}
                      {...minRate}
                    >
                      {this.state.rate.minOptions}
                    </select>
                  </span>
                  <span className="ui labeled input" styleName="selectMaxRate">
                    <label className="ui label">
                      <FormattedMessage id="jobManage.field.rate.max" />
                    </label>
                    <select
                      className={classNames('ui dropdown', {
                        disabled: !(rate.value == 2),
                      })}
                      disabled={!(rate.value == 2)}
                      {...maxRate}
                    >
                      {this.state.rate.maxOptions}
                    </select>
                  </span>
                </div>
              )}
              <h1>
                <input
                  autoFocus
                  styleName="inputTitle"
                  className={classNames({ error: title.error })}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'jobManage.field.jobTitle.placeholder',
                  })}
                  {...title}
                />
              </h1>
              <h2>{job.sync && job.data.company.name}</h2>
              <h3 styleName="selectCategory">
                <TranslatedSelect options={categories} reduxForm={category} />
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="eleven wide computer eleven wide tablet only column"
            styleName="pdf"
          >
            <h1>
              {global.file ? (
                <div>
                  <FormattedMessage id="common.string.preview" />
                </div>
              ) : (
                <FormattedMessage id="common.string.pdfUpload" />
              )}
            </h1>
            {this.props.params.prolongId && (
              <a
                href={`${FILE_URL}/pdf/job-${this.props.params.prolongId}.pdf`}
                target="_blank"
              >
                <FormattedMessage id="jobProlong.string.downloadOldPDF" />
              </a>
            )}
            <input type="file" onChange={this.onChangeFile} />
            {(() => {
              // if we are in IE, special handling
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                return (
                  <div className="ui icon message">
                    <i className="internet explorer icon" />
                    <FormattedMessage
                      defaultMessage="Unable to render file previews in Internet Explorer."
                      id="jobManage.string.noIEpreview"
                    />
                  </div>
                )
              }

              // construct a message to be shown if preview is not possible
              const noPreviewMessage = (
                <FormattedMessage
                  defaultMessage="No file preview available."
                  id="jobManage.string.noPreview"
                />
              )

              // if the preview state is set, render it
              if (this.state.preview) {
                return (
                  <object data={this.state.preview} type="application/pdf">
                    <div className="ui message">{noPreviewMessage}</div>
                  </object>
                )
              }

              return <div className="ui message">{noPreviewMessage}</div>
            })()}
          </div>
          <div className="five wide computer five wide tablet sixteen wide mobile column">
            <InfoBar sync>
              {global.login && global.login.role === 'admin' && (
                <InfoBarAdminSettings
                  companyId={companyId}
                  immediately={publishImmediately}
                />
              )}
              <InfoBarContact
                contactGender={contactGender}
                contactName={contactName}
                contactEmail={contactEmail}
                contactPhone={contactPhone}
              />
              <InfoBarLocation
                locationStreet={locationStreet}
                locationPlz={locationPlz}
                locationCity={locationCity}
                locationCountry={locationCountry}
              />
              <InfoBarSettings
                locale={global.locale}
                agb={agb}
                onlineFrom={onlineFrom}
                onlineUntil={onlineUntil}
                openFrom={openFrom}
                openFromDate={openFromDate}
                mailing={mailing}
                mailingDate={mailingDate}
                submitting={submitting}
                invalid={invalid}
                fields={fields}
                disabled={!global.file}
                file={global.file}
                handleSubmit={handleSubmit(this._createJob)}
              />
            </InfoBar>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  reduxForm(
    {
      form: formName,
      fields: formFields,
      validate: validateAGB,
    },
    state => ({
      // connect to redux state
      job: state.companyJobProlong,
      global: state.global,
      initialValues: {
        minRate: 40,
        maxRate: 60,
      },
    })
  ),
  injectIntl,
  CSSModules(styles)
)(JobCreate)
