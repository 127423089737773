import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import { BASE_URL } from '../../../settings/_settings'

import styles from './UserArea.scss'

const UserArea = ({
  companyData: { sync, data },
  loginData: { role, token },
  logout,
}) => {
  const hasLogo = sync
  const hasAbout = sync && data.about !== ''

  return (
    <div className="ui padded right aligned grid" styleName="container">
      <div className="computer tablet only row" styleName="companyName">
        <div className="column">{sync && data.name}</div>
      </div>
      <div className="computer tablet only row">
        {token ? (
          <div className="column">
            <div className="ui compact text menu">
              {sync && role !== 'admin' && (!hasLogo || !hasAbout) && (
                <Link
                  className={`item ${styles.firstSteps}`}
                  activeClassName="active"
                  to="/intro/welcome"
                >
                  <span>
                    <FormattedMessage id="header.button.firstSteps" /> (
                    {hasLogo + hasAbout}/2)
                  </span>
                </Link>
              )}
              {role === 'admin' && (
                <Link
                  className="item"
                  activeClassName="active"
                  to="/admin/jobs"
                >
                  <FormattedMessage id="header.button.admin" />
                </Link>
              )}
              <Link
                className="item"
                activeClassName="active"
                to={
                  role === 'admin' ||
                  role === 'privileged' ||
                  role === 'company'
                    ? '/users/account/jobs'
                    : '/users/account/subscriptions'
                }
              >
                <FormattedMessage id="header.button.account" />
              </Link>
              {(role === 'company' ||
                role === 'privileged' ||
                role === 'admin') && (
                <Link
                  className="item"
                  activeClassName="active"
                  to="/jobs/create"
                >
                  <FormattedMessage id="header.button.createJob" />
                </Link>
              )}
              {/* (role === 'privileged' || role === 'admin') && (
                <Link className="item" activeClassName="active" to="/theses/create">
                  <FormattedMessage id="header.button.createThesis" />
                </Link>
              ) */}
              <a className="item" onClick={logout} role="button" tabIndex="0">
                <FormattedMessage id="header.button.logout" />
              </a>
            </div>
          </div>
        ) : (
          <div className="column" styleName="noLoginColumn">
            <div className="ui compact vertically fitted text menu">
              <Link className="item" activeClassName="active" to="/users/login">
                <FormattedMessage id="header.button.login" />
              </Link>
              <Link
                className="item"
                activeClassName="active"
                to="/users/register"
              >
                <FormattedMessage id="header.button.signup" />
              </Link>
              <a
                className="item"
                styleName="aaiLink"
                href="https://www.df.uzh.ch/AAILogin/jobeye/aai.php"
              >
                <img alt="AAI" src={`${BASE_URL}/aai-login-button.png`} />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

UserArea.propTypes = {
  loginData: PropTypes.shape({
    id: PropTypes.integer, // the id of the logged in user
    token: PropTypes.string, // the jwt token of the logged in user
  }).isRequired,
  logout: PropTypes.func.isRequired, // function for logging out the user
}

export default CSSModules(UserArea, styles)
