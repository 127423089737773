import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'recompose'
import { Link } from 'react-router'
import { Tr, Td } from 'reactable'
import { SegmentTitle, TableList } from '../../common/Helpers'
import rest from '../../../settings/rest'

class ThesisList extends React.Component {
  static propTypes = {}

  componentWillMount() {
    // dynamically load content
    this.props.dispatch(rest.actions.adminTheses.sync())
  }

  render() {
    const { theses } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>Thesis overview</h2>
        </SegmentTitle>
        <TableList
          className="basic striped"
          isFetching={!theses.sync}
          sortable={[
            'ID',
            'Company',
            'Title',
            'Online until',
            'Online from',
            'Views',
            'Created',
            'Modified',
          ]}
          filterable={[
            'Company',
            'Title',
            'Online until',
            'Online from',
            'Views',
            'Created',
            'Modified',
          ]}
          csvColumns={[
            'Company name',
            'Thesis title',
            'Online from',
            'Online until',
            'Views',
            'Created',
            'Modified',
          ]}
          csvData={
            theses.sync &&
            theses.data.success &&
            theses.data.data.map(item => [
              item.company.name,
              item.title,
              item.onlineFrom,
              item.onlineUntil,
              item.views,
              item.created,
              item.modified,
            ])
          }
          csvFilename="theses"
          defaultSort={{ column: 'ID', direction: 'desc' }}
        >
          {theses.sync &&
            theses.data.success &&
            theses.data.data.map(item => (
              <Tr key={item.id}>
                <Td column="ID" value={item.id}>
                  {item.id}
                </Td>
                <Td column="Company" value={item.company.name}>
                  <Link to={`companies/${item.company.id}`}>
                    {item.company.name}
                  </Link>
                </Td>
                <Td column="Title" value={item.title}>
                  <Link to={`theses/${item.id}`}>{item.title}</Link>
                </Td>
                <Td column="Online from" value={item.onlineFrom}>
                  {moment(item.onlineFrom, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Online until" value={item.onlineUntil}>
                  {moment(item.onlineUntil, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Views" value={item.views}>
                  {item.views}
                </Td>
                <Td column="Created" value={item.created}>
                  {moment(item.created, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Modified" value={item.modified}>
                  {moment(item.modified, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Td>
                <Td column="Actions">
                  <Link to={`/theses/${item.id}/edit`}>
                    <i className="configure icon" /> EDIT
                  </Link>
                </Td>
              </Tr>
            ))}
        </TableList>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    theses: state.adminTheses,
  }))
)(ThesisList)
