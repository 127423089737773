import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { TranslatedInput, CountrySelect } from '../../common/Helpers'
import CSSModules from 'react-css-modules'
import { setFile, resetFile } from '../../../actions'
import { compose } from 'recompose'
import { object, string, number } from 'yup'
import { _validateSchema } from '../../../utils'
import _some from 'lodash/some'

import styles from './Step2.scss'

class Step2 extends React.Component {
  state = {
    noLogoVerify: false,
  }

  render() {
    const {
      global,
      submitting,
      fields: {
        companyName,
        companyUrl,
        companyStreet,
        companyCity,
        companyPlz,
        companyCountry,
      },
      handleSubmit,
      _prev,
    } = this.props

    return (
      <form
        className={classNames('ui form', {
          error: !global.file && !this.state.noLogoVerify,
        })}
        onSubmit={handleSubmit}
      >
        <h2 className="ui dividing header">
          <FormattedMessage id="userRegister.step2.title" />
        </h2>
        <TranslatedInput
          autoFocus
          type="text"
          form="common"
          id="companyName"
          error={companyName.error}
          required
          {...companyName}
        />
        <TranslatedInput
          type="text"
          form="common"
          id="url"
          error={companyUrl.error}
          {...companyUrl}
        />
        <TranslatedInput
          type="text"
          form="common"
          id="street"
          error={companyStreet.error}
          required
          {...companyStreet}
        />
        <div className="fields">
          <TranslatedInput
            type="text"
            form="common"
            id="plz"
            className="six wide"
            error={companyPlz.error}
            required
            {...companyPlz}
          />
          <TranslatedInput
            type="text"
            form="common"
            id="city"
            className="ten wide"
            error={companyCity.error}
            required
            {...companyCity}
          />
        </div>
        <div className="required field">
          <CountrySelect reduxForm={companyCountry} />
        </div>

        <button
          className={classNames('ui button secondary floated', {
            loading: submitting,
          })}
          onClick={_prev}
          disabled={submitting}
        >
          <FormattedMessage id="common.button.prev" />
        </button>
        <button
          className={classNames('ui button secondary right floated', {
            loading: submitting,
          })}
          type="submit"
          disabled={_some([
            submitting,
            companyName.invalid,
            companyStreet.invalid,
            companyCity.invalid,
            companyPlz.invalid,
            companyUrl.invalid,
          ])}
        >
          <FormattedMessage id="common.button.next" />
        </button>
      </form>
    )
  }
}

export default compose(
  reduxForm(
    {
      fields: [
        'email',
        'password',
        'language',
        'companyName',
        'companyUrl',
        'companyStreet',
        'companyCity',
        'companyPlz',
        'companyCountry',
      ],
      form: 'signup',
      destroyOnUnmount: false,
      validate: _validateSchema(
        object().shape({
          companyName: string().required(),
          companyStreet: string().required(),
          companyCity: string().required(),
          companyPlz: number()
            .min(1000)
            .max(99999)
            .required(),
          companyUrl: string().url(),
          companyCountry: string(),
        })
      ),
    },
    state => ({
      global: state.global,
    })
  ),
  CSSModules(styles)
)(Step2)
