import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import rest from '../../../settings/rest'
import { compose } from 'recompose'
import CSSModules from 'react-css-modules'
import RichTextEditor from 'react-rte'
import classNames from 'classnames'
import { SegmentTitle } from '../../common/Helpers'
import { FormattedMessage } from 'react-intl'
import { companyProfileUpdate } from '../../../actions/account'

import styles from './CompanyProfile.scss'

class CompanyProfile extends React.Component {
  componentWillMount() {
    // if the user was not already synced, fetch the data
    if (this.props.login.companyId > 0) {
      // dispatch the get request for the user data
      this.props.dispatch(
        rest.actions.companyProfile.get({ id: this.props.login.companyId })
      )
    }
  }

  _update = () => {
    this.props.dispatch(
      rest.actions.companyProfile.put(
        { id: this.props.login.companyId },
        {
          body: JSON.stringify({
            about: this.props.profile.data.toString('html'),
          }),
        }
      )
    )
  }

  _updateText = value => {
    this.props.dispatch(companyProfileUpdate(value))
  }

  render() {
    const {
      profile: { sync, loading, displaySuccess, displayError, data },
    } = this.props

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'LINK_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS',
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
      ],
    }

    return (
      <div styleName="container">
        <SegmentTitle bordered>
          <h2>
            <FormattedMessage id="userAccount.menu.about" />
          </h2>
        </SegmentTitle>
        {sync && (
          <RichTextEditor
            toolbarConfig={toolbarConfig}
            value={data}
            onChange={this._updateText}
          />
        )}
        <div className="ui grid">
          <div className="row">
            <div className="six wide computer seven wide tablet sixteen wide mobile column">
              {displaySuccess && !loading && (
                <div styleName="success">
                  <i className="green checkmark icon" />{' '}
                  <FormattedMessage id="userAccount.companyProfile.updateSuccess" />
                </div>
              )}
              {displayError && !loading && (
                <div styleName="error">
                  <i className="red flag icon" />{' '}
                  <FormattedMessage id="userAccount.companyProfile.updateError" />
                </div>
              )}
            </div>
            <div
              className="two wide computer three wide tablet sixteen wide mobile right floated column"
              styleName="save"
            >
              <button
                className={classNames('ui primary fluid button', { loading })}
                onClick={this._update}
              >
                <FormattedMessage id="common.button.submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    login: state.global.login,
    profile: state.account2.companyProfile,
  })),
  CSSModules(styles)
)(CompanyProfile)
