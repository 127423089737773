import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'
import { FILE_URL } from '../../../settings/_settings'

import styles from './CompanyCard.scss'

const renderLocation = location => {
  if (location) {
    return (
      <span>
        <i className="pin icon" />
        {location}
      </span>
    )
  }

  return null
}

const renderJobCount = numJobs => {
  if (numJobs > 0) {
    return (
      <span className="right floated">
        {numJobs} <FormattedMessage id="companyCard.string.jobsOnline" />
      </span>
    )
  }

  return null
}

const CompanyCard = ({ id, header, location, numJobs }) => (
  <Link to={`/companies/${id}`} className="ui fluid card" styleName="container">
    <div className="content" styleName="header">
      <div className="header">
        <h2>{header}</h2>
      </div>
    </div>

    <div className="ui middle aligned centered image" styleName="logo">
      <img alt="Logo" src={`${FILE_URL}/img/logo-${id}.png`} />
    </div>
    {(location || numJobs > 0) && (
      <div className="extra content" styleName="extras">
        {renderLocation(location)}
        {renderJobCount(numJobs)}
      </div>
    )}
  </Link>
)

CompanyCard.propTypes = {
  id: PropTypes.number.isRequired, // the internal id of the company
  header: PropTypes.string.isRequired, // the name of the company
  sectors: PropTypes.string, // the name of the sectors the company is active in
  location: PropTypes.string, // the main location of the company (HQ..)
  numJobs: PropTypes.number, // the number of currently active jobs of the company (online and visible)
}

export default CSSModules(CompanyCard, styles)
