import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import CSSModules from 'react-css-modules'
import rest from '../../../settings/rest'
import { injectIntl } from 'react-intl'
import { compose } from 'recompose'
import { BASE_URL } from '../../../settings/_settings'
import {
  AttachedSegment,
  SegmentBigHeader,
  InfoBar,
  InfoBarItem,
  InfoBarLocationElement,
  InfoBarContactElement,
  SocialButtons,
} from '../../common/Helpers'
import SingleJob from './SingleJob'
import SingleThesis from './SingleThesis'
import _get from 'lodash/get'

import styles from './CompanySingle.scss'

class CompanySingle extends React.Component {
  static propTypes = {}

  componentWillMount() {
    const id = this.props.params.id

    if (id) {
      this.props.dispatch(rest.actions.company({ id }))
    }
  }

  render() {
    const {
      company: {
        sync,
        loading,
        data: { company, contact, jobs, theses },
      },
      intl,
    } = this.props
    const pageUrl = `${BASE_URL}/#/companies/${this.props.params.id}`

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <SegmentBigHeader
          companyId={this.props.params.id}
          loading={loading}
          h1={_get(company, 'name')}
          h2={_get(company, 'url')}
          h2Link={_get(company, 'url')}
          h2External
          description={_get(company, 'description')}
        />
        <div className="reversed row" styleName="content">
          <div
            className="eleven wide computer sixteen wide tablet sixteen wide mobile column"
            styleName="main"
          >
            {company && company.about && company.about !== '<p></p>' && (
              <AttachedSegment
                loading={loading}
                h2={intl.formatMessage({ id: 'companySingle.string.about' })}
              >
                <span
                  styleName="about"
                  dangerouslySetInnerHTML={{ __html: _get(company, 'about') }}
                />
              </AttachedSegment>
            )}
            {jobs && jobs.filter(job => job.current).length > 0 && (
              <AttachedSegment
                loading={loading}
                h2={intl.formatMessage({ id: 'companySingle.string.allJobs' })}
                className={styles.jobs}
              >
                {jobs
                  .filter(job => job.current)
                  .map(job => (
                    <SingleJob job={job} />
                  ))}
              </AttachedSegment>
            )}
            {theses && theses.filter(thesis => thesis.current).length > 0 && (
              <AttachedSegment
                loading={loading}
                h2={intl.formatMessage({
                  id: 'companySingle.string.allTheses',
                })}
                className={styles.jobs}
              >
                {theses
                  .filter(thesis => thesis.current)
                  .map(thesis => (
                    <SingleThesis thesis={thesis} />
                  ))}
              </AttachedSegment>
            )}
          </div>
          <div
            className="five wide computer sixteen wide tablet sixteen wide mobile column"
            styleName="infobar"
          >
            <InfoBar>
              <InfoBarItem
                loading={loading}
                title="common.infobar.location.title"
              >
                <InfoBarLocationElement
                  street={_get(company, 'street')}
                  city={_get(company, 'city')}
                  plz={_get(company, 'plz')}
                  country={_get(company, 'country')}
                  location={_get(company, 'location')}
                />
              </InfoBarItem>
              <InfoBarItem
                loading={loading}
                title="common.infobar.contact.title"
              >
                <InfoBarContactElement
                  name={_get(contact, 'name')}
                  email={_get(contact, 'email')}
                  phone={_get(contact, 'phone')}
                />
              </InfoBarItem>
            </InfoBar>
            <SocialButtons
              url={pageUrl}
              title={_get(company, 'name')}
              description="Interessantes Firmenprofil auf jobeye.ch!"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    company: state.company,
  })),
  injectIntl,
  CSSModules(styles)
)(CompanySingle)
