import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const BasicSubmit = ({
  primary,
  secondary,
  floated,
  disabled,
  loading,
  success,
  error,
}) => (
  <button
    className={classNames('ui button', {
      loading,
      primary,
      secondary,
      'right floated': floated === 'right',
    })}
    type="submit"
    disabled={disabled}
  >
    {success && <i className="white checkmark icon" />}
    {error && <i className="white flag icon" />}
    <FormattedMessage id="common.button.submit" />
  </button>
)

BasicSubmit.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  floated: PropTypes.string,
  disabled: PropTypes.bool,
}

export default BasicSubmit
