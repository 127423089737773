import React from 'react'
import { AttachedSegment } from '../../components/common/Helpers'
import { FormattedMessage, injectIntl } from 'react-intl'

const Imprint = ({ intl }) => (
  <AttachedSegment h2={intl.formatMessage({ id: 'common.string.imprint' })}>
    <strong>
      <FormattedMessage id="common.string.ibf" />
    </strong>
    <br />
    Plattenstrasse 14
    <br />
    8032 <FormattedMessage id="common.string.zurich" />
    <br />
    +41 44 634 50 30
    <br />
    <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>
    <br />
  </AttachedSegment>
)

export default injectIntl(Imprint)
