import React from 'react'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'
import Imprint from './Imprint'

import styles from './About.scss'

const ForCompanies = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>
          <FormattedMessage id="footer.string.forCompanies" />
        </h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="Jobinserate - Informationen">
          <p>
            Jobeye.ch ist eine durch das{' '}
            <a href="http://www.bf.uzh.ch">Institut für Banking und Finance</a>{' '}
            der Universität Zürich geführte Stellenplattform für Teilzeitjobs,
            Festanstellungen und Praktikas. Durch die Verankerung mit dem
            Institut entsteht ein einzigartiger Vorteil gegenüber anderen
            Anbietern, im Sinne eines engen und direkten Bezugs zu einer breiten
            Studierenden-, Doktorierenden- und Absolventenbasis, insbesondere im
            Bereich Banking und Finance sowie der Betriebswirtschaftslehre. Ziel
            von Jobeye.ch ist es, hochqualifizierte Studierende, Absolvierende
            und Doktorierende mit den führenden Unternehmen aus Finanz und
            Wirtschaft zu verbinden.
          </p>
          <p>
            Studierende, Absolvierende und Doktorierende können über drei
            unterschiedliche Distributionswege erreicht werden:
          </p>
          <table className="ui celled table">
            <tbody>
              <tr>
                <td className="single line">
                  <strong>Online-Publikation</strong>
                </td>
                <td>
                  Das Inserat wird auf Jobeye.ch mit monatlich rund 1‘750
                  Besuchern publiziert, wobei eine Zeitdauer von max. 60 Tagen
                  gewählt werden kann. Sobald das Inserat online durch Sie im
                  System erfasst wurde, wird dieses innerhalb eines Werktages
                  durch das Jobeye.ch-Team freigeschaltet und publiziert.
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Zudem besteht die Möglichkeit, dass Sie Ihr Unternehmen mit einem
            Unternehmensportrait bei den Studierenden, Absolvierenden und
            Doktorierenden vorstellen. Das Portrait informiert die Nutzer über
            Karrieremöglichkeiten in Ihrem Unternehmen. Vakante Stellen auf
            Jobeye.ch werden direkt mit dem Portrait verknüpft.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Jobinserate - Pricing">
          <p>
            Der Preis für die Online-Publikation und den Aushang im Banking und
            Finance Schaukasten beträgt pro Inserat CHF 200 für eine Laufzeit
            von max. 60 Tagen (Daten frei wählbar).
          </p>
          <table className="ui collapsing celled fluid table">
            <thead>
              <tr>
                <th>
                  Online-Publikation,
                  <br />
                  Aushang Banking und Finance Schaukasten
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right aligned">CHF 200</td>
              </tr>
            </tbody>
          </table>
          <p>
            Alle Preise in CHF, exkl. MwSt. Die Schaltung des
            Unternehmensportraits ist gratis.
          </p>
          <p>
            Für detaillierte Auskünfte bezüglich unserem Service stehen wir
            Ihnen jederzeit gerne telefonisch (+41 44 634 50 30) oder per E-Mail
            (<a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) zur
            Verfügung.
          </p>
        </AttachedSegment>

        <AttachedSegment h2="Kooperation für akademische Arbeiten">
          <p>
            Jobeye.ch fungiert neben der Stellenplattform auch als Brücke
            zwischen Studierenden und Unternehmen in der Vermittlung von
            praxisnahen Abschlussarbeiten (Bachelor- oder Masterarbeiten). Falls
            Sie als Unternehmen an einer Zusammenarbeit interessiert sind,
            nehmen Sie bitte ungeniert telefonisch (+41 44 634 50 30) oder per
            E-Mail (<a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>) mit
            dem Jobeye.ch-Team Kontakt auf. Wir können Ihnen bezüglich einer
            möglichen Ausarbeitung des Themas, dem Vermitteln von interessierten
            Studierenden sowie der Zusammenarbeit mit den universitären
            Lehrstühlen gerne weiterhelfen. Preise auf Anfrage.
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(ForCompanies, styles)
