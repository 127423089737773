import React from 'react'
import CSSModules from 'react-css-modules'
import { FormattedMessage } from 'react-intl'
import { SegmentTitle, AttachedSegment } from '../../components/common/Helpers'
import Imprint from './Imprint'

import styles from './About.scss'

const ForStudents = () => (
  <div className="ui horizontally padded grid">
    <div className="row" styleName="titleRow">
      <SegmentTitle>
        <h1>
          <FormattedMessage id="footer.string.forStudents" />
        </h1>
      </SegmentTitle>
    </div>
    <div className="row">
      <div className="twelve wide computer sixteen wide tablet sixteen wide mobile centered column">
        <AttachedSegment h2="General Information">
          <p>
            Jobeye.ch is a job platform maintained by the{' '}
            <a href="http://www.bf.uzh.ch">Department of Banking and Finance</a>{' '}
            of the University of Zurich. The purpose of jobeye.ch is to bring
            highly qualified students or graduates in banking &amp; finance or
            business together with leading companies, whether for a job offering
            or a practical thesis.
          </p>
          <p>
            As registered user, you can benefit free of charge from following
            advantages on Jobeye.ch:
          </p>
          <ul className="ui list">
            <li>
              Advanced search for part-time, fulltime jobs and internships
            </li>
            <li>
              Subscription to the latest job offers for defined search criteria
            </li>
          </ul>
          <p>
            If you have any further questions, do not hesitate to contact us by
            phone (+41 44 634 50 30) or by e-mail (
            <a href="mailto:jobeye@df.uzh.ch">jobeye@df.uzh.ch</a>).
          </p>
        </AttachedSegment>

        <Imprint />
      </div>
    </div>
  </div>
)

export default CSSModules(ForStudents, styles)
