import * as Actions from '../actions/theses'
import _filter from 'lodash/filter'

const initialState = {
  sync: false,
  filters: {
    query: '',
    types: [],
    withJob: null,
  },
  data: [],
  filtered: [],
}

const thesesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.THESES_FILTER:
      const filters = action.filters
      let filtered = state.data

      if (filters.types.length > 0) {
        filtered = _filter(
          filtered,
          thesis => filters.types.indexOf(thesis.type) > -1
        )
      }

      if (filters.withJob) {
        filtered = _filter(
          filtered,
          thesis => thesis.withJob === filters.withJob
        )
      }

      if (filters.query.length > 0) {
        const query = filters.query.toLowerCase()
        filtered = _filter(
          filtered,
          thesis =>
            thesis.title.toLowerCase().indexOf(query) > -1 ||
            thesis.company.name.toLowerCase().indexOf(query) > -1
        )
      }

      return {
        ...state,
        filters,
        filtered,
        sync: true,
      }

    case Actions.THESES_REQUEST:
      return {
        ...state,
        sync: false,
      }

    case Actions.THESES_RECEIVE:
      return {
        ...state,
        sync: true,
        data: action.theses,
        filtered: action.theses,
      }

    default:
      return state
  }
}

export default thesesReducer
