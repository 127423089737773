import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const BasicTextarea = ({
  formName = 'form',
  width,
  common,
  required,
  reduxForm,
}) => (
  <div
    className={classNames('field', width, { required, error: reduxForm.error })}
  >
    <label>
      <FormattedMessage
        id={`${(common && 'common') || formName}.field.${reduxForm.name}.label`}
      />
    </label>
    <textarea rows="4" {...reduxForm} />
    {reduxForm.error && (
      <div className="ui error message">{reduxForm.error}</div>
    )}
  </div>
)

BasicTextarea.propTypes = {
  formName: PropTypes.string,
  reduxForm: PropTypes.object.isRequired,
  required: PropTypes.bool,
}

export default BasicTextarea
