import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import globalReducer from './global'
import jobsReducer from './jobs'
import thesesReducer from './theses'
import accountReducer from './account'
import accountReducer2 from './accountReducer'
import rest from '../settings/rest'

const rootReducer = combineReducers({
  // custom reducers
  global: globalReducer,
  jobs: jobsReducer,
  theses: thesesReducer,
  account: accountReducer,
  account2: accountReducer2,
  // router reducer
  // needs to be available as state.routing
  routing: routerReducer,
  // form reducer
  form: formReducer,
  // generated redux-api rest reducers
  ...rest.reducers,
})

export default rootReducer
