import { _requireAnon, _requireLevel, _logException } from '../utils'
import Cookies from 'js-cookie'

const catchErr = (err, location) => {
  console.log(`Chunk loading failed: ${err} (${location}).`)
  _logException(err)
}

export const AdminJobsRoute = {
  path: 'jobs',
  getComponent(location, cb) {
    import('../components/Users/AdminArea/JobList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const AdminThesesRoute = {
  path: 'theses',
  getComponent(location, cb) {
    import('../components/Users/AdminArea/ThesisList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const AdminCompaniesRoute = {
  path: 'companies',
  getComponent(location, cb) {
    import('../components/Users/AdminArea/CompanyList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const AdminUsersRoute = {
  path: 'users',
  getComponent(location, cb) {
    import('../components/Users/AdminArea/UserList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const AdminRoute = {
  path: 'admin',
  getComponent(location, cb) {
    import('../components/Users/AdminArea/AdminArea')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('users/login/admin', 'admin'),
  childRoutes: [
    AdminJobsRoute,
    AdminThesesRoute,
    AdminCompaniesRoute,
    AdminUsersRoute,
  ],
}

export const CompanyData = {
  path: 'company',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/CompanyData')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    'users/login/editCompanyData',
    'company',
    'privileged',
    'admin'
  ),
}
export const CompanyProfile = {
  path: 'profile',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/CompanyProfile')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    'users/login/editCompanyProfile',
    'company',
    'privileged',
    'admin'
  ),
}
export const JobList = {
  path: 'jobs',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/JobList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    'users/login/listJobs',
    'company',
    'privileged',
    'admin'
  ),
}
export const ThesesList = {
  path: 'theses',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/ThesisList')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('users/login/listTheses', 'privileged', 'admin'),
}
export const Subscriptions = {
  path: 'subscriptions',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/Subscriptions')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    'users/login/editSubs',
    'user',
    'company',
    'privileged',
    'admin'
  ),
}
export const UserData = {
  path: 'personal',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/UserData')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    'users/login/editUserData',
    'user',
    'company',
    'privileged',
    'admin'
  ),
}
export const AccountRoute = {
  path: 'users/account',
  getComponent(location, cb) {
    import('../components/Users/UserAccount/UserAccount')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  childRoutes: [
    CompanyData,
    CompanyProfile,
    JobList,
    ThesesList,
    Subscriptions,
    UserData,
  ],
}

export const AGBRoute = {
  path: 'agb',
  getComponent(location, cb) {
    import('../components/About/AGB')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const ForStudentsRoute = {
  path: 'for-students',
  getComponent(location, cb) {
    if (Cookies.get('locale') === 'DE') {
      import('../components/About/ForStudents')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    } else {
      import('../components/About/enForStudents')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    }
  },
}
export const ForCompaniesRoute = {
  path: 'for-companies',
  getComponent(location, cb) {
    if (Cookies.get('locale') === 'DE') {
      import('../components/About/ForCompanies')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    } else {
      import('../components/About/enForCompanies')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    }
  },
}
export const LandingRoute = {
  path: 'landing',
  getComponent(location, cb) {
    if (Cookies.get('locale') === 'DE') {
      import('../components/About/Landing')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    } else {
      import('../components/About/Landing')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    }
  },
}
export const FAQRoute = {
  path: 'faq',
  getComponent(location, cb) {
    if (Cookies.get('locale') === 'DE') {
      import('../components/About/FAQ')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    } else {
      import('../components/About/enFAQ')
        .then(module => {
          cb(null, module.default)
        })
        .catch(err => catchErr(err, location))
    }
  },
}
export const ContactRoute = {
  path: 'contact',
  getComponent(location, cb) {
    import('../components/About/Contact')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const AboutRoute = {
  path: 'about',
  getComponent(location, cb) {
    import('../components/About/About')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  childRoutes: [
    AGBRoute,
    ForStudentsRoute,
    ForCompaniesRoute,
    ContactRoute,
    FAQRoute,
  ],
}

export const LoginRoute = {
  path: 'users/login(/:redirect)(/:id)',
  getComponent(location, cb) {
    import('../components/Users/UserLogin')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const RequestPasswordRoute = {
  path: 'users/request',
  getComponent(location, cb) {
    import('../components/Users/UserRequest')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireAnon(),
}
export const RegisterAccountRoute = {
  path: 'users/register',
  getComponent(location, cb) {
    import('../components/Users/UserRegister/UserRegister')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireAnon(),
}
export const ResetPasswordRoute = {
  path: 'users/reset',
  getComponent(location, cb) {
    import('../components/Users/UserReset')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireAnon(),
}
export const ConfirmAccountRoute = {
  path: 'users/confirm',
  getComponent(location, cb) {
    import('../components/Users/UserConfirm')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireAnon(),
}

export const CompanyRoute = {
  path: 'companies/:id',
  getComponent(location, cb) {
    import('../components/Companies/CompanySingle/CompanySingle.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const CompaniesRoute = {
  path: 'companies',
  getComponent(location, cb) {
    import('../components/Companies/CompanyListing/CompanyListing.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}

export const JobRoute = {
  path: 'jobs/:id',
  getComponent(location, cb) {
    import('../components/Jobs/JobSingle/JobSingle.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const JobCreateRoute = {
  path: 'jobs/create',
  getComponent(location, cb) {
    import('../components/Jobs/JobManage/JobCreate.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel(
    '/users/login/createJob',
    'company',
    'privileged',
    'admin'
  ),
}
export const JobEditRoute = {
  path: 'jobs/:id/edit',
  getComponent(location, cb) {
    import('../components/Jobs/JobManage/JobEdit.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('/users/login', 'company', 'privileged', 'admin'),
}
export const JobProlongRoute = {
  path: 'jobs/prolong/:prolongId',
  getComponent(location, cb) {
    import('../components/Jobs/JobManage/JobCreate.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('/users/login', 'company', 'privileged', 'admin'),
}

export const ThesesRoute = {
  path: 'theses',
  getComponent(location, cb) {
    import('../components/Theses/ThesisListing/ThesisListing.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const ThesisRoute = {
  path: 'theses/:id',
  getComponent(location, cb) {
    import('../components/Theses/ThesisSingle/ThesisSingle.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const ThesisCreateRoute = {
  path: 'theses/create',
  getComponent(location, cb) {
    import('../components/Theses/ThesisManage/ThesisCreate.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('/users/login/createThesis', 'privileged', 'admin'),
}
export const ThesisEditRoute = {
  path: 'theses/:id/edit',
  getComponent(location, cb) {
    import('../components/Theses/ThesisManage/ThesisEdit.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
  onEnter: _requireLevel('/users/login', 'privileged', 'admin'),
}

const Welcome = {
  path: 'welcome',
  getComponent(location, cb) {
    import('../components/Users/FirstSteps/Welcome.js')
      .then(module => {
        cb(null, module.default)
      })
      .catch(err => catchErr(err, location))
  },
}
export const FirstStepsRoute = {
  path: 'intro',
  onEnter: _requireLevel(
    '/users/login/intro',
    'user',
    'company',
    'privileged',
    'admin'
  ),
  childRoutes: [Welcome],
}
