export const ACCOUNT_COMPANY_REQUEST = 'ACCOUNT_COMPANY_REQUEST'
export const accountCompanyRequest = section => ({
  type: ACCOUNT_COMPANY_REQUEST,
  section,
})

export const ACCOUNT_COMPANY_RECEIVE = 'ACCOUNT_COMPANY_RECEIVE'
export const accountCompanyReceive = company => ({
  type: ACCOUNT_COMPANY_RECEIVE,
  company,
})

export const ACCOUNT_COMPANY_ABOUT_UPDATE = 'ACCOUNT_COMPANY_ABOUT_UPDATE'
export const accountCompanyAboutUpdate = about => ({
  type: ACCOUNT_COMPANY_ABOUT_UPDATE,
  about,
})

export const ACCOUNT_COMPANY_ABOUT_SYNC = 'ACCOUNT_COMPANY_ABOUT_SYNC'
export const accountCompanyAboutSync = () => ({
  type: ACCOUNT_COMPANY_ABOUT_SYNC,
})

/* COMPANY PROFILE */
export const COMPANY_PROFILE_UPDATE = 'COMPANY_PROFILE_UPDATE'
export const COMPANY_PROFILE_REQUEST = 'COMPANY_PROFILE_REQUEST'
export const COMPANY_PROFILE_SUCCESS = 'COMPANY_PROFILE_SUCCESS'
export const COMPANY_PROFILE_FAILURE = 'COMPANY_PROFILE_FAILURE'
export const companyProfileUpdate = payload => ({
  type: COMPANY_PROFILE_UPDATE,
  payload,
})
export const companyProfileRequest = () => ({ type: COMPANY_PROFILE_REQUEST })
export const companyProfileSuccess = payload => ({
  type: COMPANY_PROFILE_SUCCESS,
  payload,
})
export const companyProfileFailure = payload => ({
  type: COMPANY_PROFILE_FAILURE,
  payload,
})

/* COMPANY JOBS */
export const COMPANY_JOBS_REQUEST = 'COMPANY_JOBS_REQUEST'
export const COMPANY_JOBS_SUCCESS = 'COMPANY_JOBS_SUCCESS'
export const COMPANY_JOBS_FAILURE = 'COMPANY_JOBS_FAILURE'
export const companyJobsRequest = () => ({ type: COMPANY_JOBS_REQUEST })
export const companyJobsSuccess = payload => ({
  type: COMPANY_JOBS_SUCCESS,
  payload,
})
export const companyJobsFailure = payload => ({
  type: COMPANY_JOBS_FAILURE,
  payload,
})

/* COMPANY THESES */
export const COMPANY_THESES_REQUEST = 'COMPANY_THESES_REQUEST'
export const COMPANY_THESES_SUCCESS = 'COMPANY_THESES_SUCCESS'
export const COMPANY_THESES_FAILURE = 'COMPANY_THESES_FAILURE'
export const companyThesesRequest = () => ({ type: COMPANY_THESES_REQUEST })
export const companyThesesSuccess = payload => ({
  type: COMPANY_THESES_SUCCESS,
  payload,
})
export const companyThesesFailure = payload => ({
  type: COMPANY_THESES_FAILURE,
  payload,
})

/* COMPANY DATA */
export const COMPANY_DATA_INIT = 'COMPANY_DATA_INIT'
export const companyDataInit = payload => ({ type: COMPANY_DATA_INIT, payload })
export const COMPANY_DATA_GENERAL_REQUEST = 'COMPANY_DATA_GENERAL_REQUEST'
export const COMPANY_DATA_GENERAL_SUCCESS = 'COMPANY_DATA_GENERAL_SUCCESS'
export const COMPANY_DATA_GENERAL_FAILURE = 'COMPANY_DATA_GENERAL_FAILURE'
export const companyDataGeneralRequest = () => ({
  type: COMPANY_DATA_GENERAL_REQUEST,
})
export const companyDataGeneralSuccess = payload => ({
  type: COMPANY_DATA_GENERAL_SUCCESS,
  payload,
})
export const companyDataGeneralFailure = payload => ({
  type: COMPANY_DATA_GENERAL_FAILURE,
  payload,
})
export const COMPANY_DATA_CONTACT_REQUEST = 'COMPANY_DATA_CONTACT_REQUEST'
export const COMPANY_DATA_CONTACT_SUCCESS = 'COMPANY_DATA_CONTACT_SUCCESS'
export const COMPANY_DATA_CONTACT_FAILURE = 'COMPANY_DATA_CONTACT_FAILURE'
export const companyDataContactRequest = () => ({
  type: COMPANY_DATA_CONTACT_REQUEST,
})
export const companyDataContactSuccess = payload => ({
  type: COMPANY_DATA_CONTACT_SUCCESS,
  payload,
})
export const companyDataContactFailure = payload => ({
  type: COMPANY_DATA_CONTACT_FAILURE,
  payload,
})
export const COMPANY_DATA_INVOICE_REQUEST = 'COMPANY_DATA_INVOICE_REQUEST'
export const COMPANY_DATA_INVOICE_SUCCESS = 'COMPANY_DATA_INVOICE_SUCCESS'
export const COMPANY_DATA_INVOICE_FAILURE = 'COMPANY_DATA_INVOICE_FAILURE'
export const companyDataInvoiceRequest = () => ({
  type: COMPANY_DATA_INVOICE_REQUEST,
})
export const companyDataInvoiceSuccess = payload => ({
  type: COMPANY_DATA_INVOICE_SUCCESS,
  payload,
})
export const companyDataInvoiceFailure = payload => ({
  type: COMPANY_DATA_INVOICE_FAILURE,
  payload,
})

/* USER DATA */
export const USER_DATA_INIT = 'USER_DATA_INIT'
export const userDataInit = payload => ({ type: USER_DATA_INIT, payload })
export const USER_DATA_GENERAL_REQUEST = 'USER_DATA_GENERAL_REQUEST'
export const USER_DATA_GENERAL_SUCCESS = 'USER_DATA_GENERAL_SUCCESS'
export const USER_DATA_GENERAL_FAILURE = 'USER_DATA_GENERAL_FAILURE'
export const userDataGeneralRequest = () => ({
  type: USER_DATA_GENERAL_REQUEST,
})
export const userDataGeneralSuccess = payload => ({
  type: USER_DATA_GENERAL_SUCCESS,
  payload,
})
export const userDataGeneralFailure = payload => ({
  type: USER_DATA_GENERAL_FAILURE,
  payload,
})
export const USER_DATA_PASSWORD_REQUEST = 'USER_DATA_PASSWORD_REQUEST'
export const USER_DATA_PASSWORD_SUCCESS = 'USER_DATA_PASSWORD_SUCCESS'
export const USER_DATA_PASSWORD_FAILURE = 'USER_DATA_PASSWORD_FAILURE'
export const userDataPasswordRequest = () => ({
  type: USER_DATA_PASSWORD_REQUEST,
})
export const userDataPasswordSuccess = payload => ({
  type: USER_DATA_PASSWORD_SUCCESS,
  payload,
})
export const userDataPasswordFailure = payload => ({
  type: USER_DATA_PASSWORD_FAILURE,
  payload,
})

/* SUBSCRIPTIONS */
export const USER_SUBSCRIPTION_REQUEST = 'USER_SUBSCRIPTION_REQUEST'
export const USER_SUBSCRIPTION_SUCCESS = 'USER_SUBSCRIPTION_SUCCESS'
export const USER_SUBSCRIPTION_FAILURE = 'USER_SUBSCRIPTION_FAILURE'
export const userSubscriptionRequest = payload => ({
  type: USER_SUBSCRIPTION_REQUEST,
  payload,
})
export const userSubscriptionSuccess = payload => ({
  type: USER_SUBSCRIPTION_SUCCESS,
  payload,
})
export const userSubscriptionFailure = payload => ({
  type: USER_SUBSCRIPTION_FAILURE,
  payload,
})
export const USER_SUBSCRIPTIONS_REQUEST = 'USER_SUBSCRIPTIONS_REQUEST'
export const USER_SUBSCRIPTIONS_SUCCESS = 'USER_SUBSCRIPTIONS_SUCCESS'
export const USER_SUBSCRIPTIONS_FAILURE = 'USER_SUBSCRIPTIONS_FAILURE'
export const userSubscriptionsRequest = () => ({
  type: USER_SUBSCRIPTIONS_REQUEST,
})
export const userSubscriptionsSuccess = payload => ({
  type: USER_SUBSCRIPTIONS_SUCCESS,
  payload,
})
export const userSubscriptionsFailure = payload => ({
  type: USER_SUBSCRIPTIONS_FAILURE,
  payload,
})
