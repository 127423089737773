import PropTypes from 'prop-types';
import React from 'react';
import TranslatedSelect from './TranslatedSelect'
import LabelSelect from './LabelSelect'
import { FormattedMessage } from 'react-intl'

const CountrySelect = ({ reduxForm, labelLeft }) => {
  const countries = [
    { id: 'CH', name: 'country.ch.name' },
    { id: 'DE', name: 'country.de.name' },
    { id: 'AT', name: 'country.at.name' },
    { id: 'NL', name: 'country.nl.name' },
    { id: 'SG', name: 'country.sg.name' },
  ]

  const select = (
    <TranslatedSelect
      className="ui dropdown"
      options={countries}
      reduxForm={reduxForm}
    />
  )

  if (labelLeft) {
    return <LabelSelect label="country.name">{select}</LabelSelect>
  }
  return (
    <div className="field">
      <label>
        <FormattedMessage id="country.name" />
      </label>
      {select}
    </div>
  )
}

CountrySelect.propTypes = {}

export default CountrySelect
