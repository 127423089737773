import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import CSSModules from 'react-css-modules'
import rest from '../../../settings/rest'
import { compose } from 'recompose'
import CompanyCard from './CompanyCard'
import { SegmentTitle } from '../../common/Helpers'
import { FormattedMessage } from 'react-intl'

import styles from './CompanyListing.scss'

class CompanyListing extends React.Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
  }

  componentWillMount() {
    this.props.dispatch(rest.actions.companies({}))
  }

  render() {
    const { companies } = this.props

    return (
      <div className="row ui horizontally padded grid" styleName="container">
        <div className="row" styleName="title">
          <SegmentTitle>
            <h1>
              <FormattedMessage id="companyListing.string.title" />
            </h1>
          </SegmentTitle>
        </div>
        <div className="doubling row" styleName="companies">
          {companies.map((company, index) => (
            <div
              key={index}
              className="stretched four wide computer four wide tablet eight wide mobile column"
              styleName="company"
            >
              <CompanyCard
                id={company.id}
                header={company.name}
                location={`${company.plz} ${company.city}`}
                numJobs={company.jobCount}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    companies: state.companies.data,
  })),
  CSSModules(styles)
)(CompanyListing)
