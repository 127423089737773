import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.min.css'
import styles from './DateInput.scss'

const DateInput = ({
  locale = 'DE',
  disabled,
  label,
  defaultValue,
  reduxForm,
  min,
  max,
}) => (
  <div
    className={classNames('ui labeled fluid small input', {
      error: reduxForm && reduxForm.error,
    })}
    styleName="container"
  >
    <div className="ui label">
      <FormattedMessage id={label} />
    </div>
    <DatePicker
      disabled={disabled}
      minDate={min}
      maxDate={max}
      selected={
        reduxForm.value ? moment(reduxForm.value, 'YYYY-MM-DD') : moment()
      }
      onChange={reduxForm.onChange}
      dateFormat="DD.MM.YYYY"
    />
  </div>
)

DateInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  reduxForm: PropTypes.object.isRequired,
  min: PropTypes.string,
  max: PropTypes.string,
}

export default CSSModules(DateInput, styles)
