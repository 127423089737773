import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm } from 'redux-form'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { object, string, ref } from 'yup'
import { compose } from 'recompose'
import _some from 'lodash/some'
import { _validateSchema } from '../../../utils'
import { TranslatedInput, LanguageSelect, TranslatedSelect } from '../../common/Helpers'

const Step1 = ({
  submitting,
  fields: {
    email, password, passwordRepeat, language, isCompanyAccount,
  },
  handleSubmit,
  _next,
}) => (
  <form
    className="ui form"
    onSubmit={isCompanyAccount.value === 'yes' ? _next : handleSubmit}
  >
    <h2 className="ui dividing header">
      <FormattedMessage id="userRegister.step1.title" />
    </h2>
    <TranslatedInput
      autoFocus
      type="email"
      form="common"
      id="email"
      error={email.error}
      required
      {...email}
    />
    <TranslatedInput
      type="password"
      form="common"
      id="password"
      required
      {...password}
    />
    <TranslatedInput
      type="password"
      form="common"
      id="passwordRepeat"
      required
      {...passwordRepeat}
    />
    <LanguageSelect reduxForm={language} />
    <div className="ui required field">
      <label>
        <FormattedMessage id="userRegister.step1.publishJobOpenings" />
      </label>
      <TranslatedSelect
        className="ui dropdown required"
        options={[
          { id: 'x', name: 'common.string.pleaseChoose' },
          { id: 'no', name: 'userRegister.step1.publishJobOpenings.no' },
          { id: 'yes', name: 'userRegister.step1.publishJobOpenings.yes' },
        ]}
        reduxForm={isCompanyAccount}
      />
    </div>

    {isCompanyAccount.value === 'yes' ? (
      <button
        className={classNames('ui button secondary right floated', {
          loading: submitting,
        })}
        type="submit"
        disabled={
          submitting
          || email.invalid
          || password.invalid
          || passwordRepeat.invalid
        }
      >
        <FormattedMessage id="common.button.next" />
      </button>
    ) : (
      <button
        className={classNames('ui button primary right floated', {
          loading: submitting,
        })}
        type="submit"
        disabled={_some([
          submitting,
          email.invalid,
          password.invalid,
          passwordRepeat.invalid,
          isCompanyAccount.value === 'x',
          password.value !== passwordRepeat.value,
        ])}
      >
        <FormattedMessage id="common.button.submit" />
      </button>
    )}
  </form>
)

Step1.propTypes = {}

export default compose(
  reduxForm(
    {
      fields: [
        'email',
        'password',
        'passwordRepeat',
        'language',
        'isCompanyAccount',
        'companyCountry',
      ],
      form: 'signup',
      destroyOnUnmount: false,
      validate: _validateSchema(
        object().shape({
          email: string()
            .email()
            .required(),
          password: string().required(),
          passwordRepeat: string().required(),
        }),
        ({ isCompanyAccount, password, passwordRepeat }) => ({
          passwordRepeat: password !== passwordRepeat ? 'invalid' : undefined,
          isCompanyAccount: isCompanyAccount === 'x' ? 'invalid' : undefined,
        }),
      ),
    },
    (state) => ({
      initialValues: {
        contactTitle: 'x',
        isCompanyAccount: 'x',
        language: state.global.locale,
        companyCountry: 'CH',
      },
    }),
  ),
)(Step1)
