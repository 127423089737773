import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { reset } from 'redux-form'
import { compose } from 'recompose'
import _get from 'lodash/get'
import rest from '../../../settings/rest'
import { resetFile } from '../../../actions'
import { InfoBar, InfoBarItem } from '../../common/Helpers'
import { CenteredSegment } from '../../common/Layouts'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

class UserRegister extends React.Component {
  static propTypes = {
    register: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    step: 1,
  }

  componentWillMount = () => {
    this.props.dispatch(rest.actions.register.reset())
    this.props.dispatch(reset('signup'))
    this.props.dispatch(resetFile())
  }

  _next = event => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    this.setState({
      step: this.state.step + 1,
    })
  }

  _prev = event => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    this.setState({
      step: this.state.step - 1,
    })
  }

  _submit = (values, dispatch) => {
    const normal = {
      username: values.email,
      language: values.language,
      password: values.password,
    }

    const company = {
      ...normal,
      company: {
        name: values.companyName,
        url: values.companyUrl,
        street: values.companyStreet,
        plz: values.companyPlz,
        city: values.companyCity,
        country: values.companyCountry || 'CH',
        contact_gender: values.contactTitle,
        contact_name: values.contactName,
        contact_email: values.email,
        contact_phone: values.contactPhone,
        invoice_address: false,
      },
      role: 'company',
    }

    const invoice = {
      ...company,
      company: {
        ...company.company,
        invoice_address: true,
        invoice_gender: values.invoiceTitle || values.contactTitle,
        invoice_name: values.invoiceName || values.contactName,
        invoice_email: values.invoiceEmail || values.email,
        invoice_phone: values.invoicePhone || values.contactPhone,
        invoice_street: values.invoiceStreet || values.companyStreet,
        invoice_city: values.invoiceCity || values.companyCity,
        invoice_plz: values.invoicePlz || values.companyPlz,
        invoice_country: values.invoiceCountry || values.companyCountry,
      },
    }

    dispatch(
      rest.actions.register(
        {},
        {
          // company registrations will always be submitted from page 3 or 4!
          body:
            this.state.step === 1
              ? JSON.stringify(normal)
              : values.customInvoice
              ? JSON.stringify(invoice)
              : JSON.stringify(company),
        }
      )
    )
  }

  render() {
    const { intl, form, register } = this.props

    const stepData = {
      active: this.state.step,
      items: [
        { name: 'userRegister.step1.short', disabled: false },
        { name: 'userRegister.step2.short', disabled: false },
        { name: 'userRegister.step3.short', disabled: false },
        { name: 'userRegister.step4.short', disabled: false },
      ],
    }

    const steps = {
      1: <Step1 onSubmit={this._submit} _next={this._next} />,
      2: <Step2 onSubmit={this._next} _prev={this._prev} />,
      3: (
        <Step3 onSubmit={this._submit} _next={this._next} _prev={this._prev} />
      ),
      4: <Step4 onSubmit={this._submit} _prev={this._prev} />,
    }

    const right = (
      <InfoBar>
        {this.state.step > 1 && (
          <InfoBarItem
            title="userRegister.infobar.accountTitle"
            loading={false}
          >
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.email" />
              </strong>
              <br />
              {form.signup.email.value}
            </p>
          </InfoBarItem>
        )}
        {this.state.step > 2 && (
          <InfoBarItem
            title="userRegister.infobar.companyTitle"
            loading={false}
          >
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.companyName" />
              </strong>
              <br />
              {form.signup.companyName.value}
            </p>
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.companyStreet" />
              </strong>
              <br />
              {form.signup.companyStreet.value}
            </p>
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.companyPlzCity" />
              </strong>
              <br />
              {form.signup.companyPlz.value} {form.signup.companyCity.value}
            </p>
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.companyCountry" />
              </strong>
              <br />
              {form.signup.companyCountry.value}
            </p>
          </InfoBarItem>
        )}
        {this.state.step > 3 && (
          <InfoBarItem
            title="userRegister.infobar.contactTitle"
            loading={false}
          >
            <p>
              <strong>
                <FormattedMessage id="userRegister.infobar.contactGenderName" />
              </strong>
              <br />
              {intl.formatMessage({
                id: `common.select.gender.${form.signup.contactTitle.value}`,
              })}{' '}
              {form.signup.contactName.value}
            </p>
            {form.signup.contactPhone && form.signup.contactPhone.value && (
              <p>
                <strong>
                  <FormattedMessage id="userRegister.infobar.contactPhone" />
                </strong>
                <br />
                {form.signup.contactPhone.value}
              </p>
            )}
          </InfoBarItem>
        )}
      </InfoBar>
    )

    return (
      <CenteredSegment
        title={intl.formatMessage({ id: 'userRegister.string.title' })}
        success={_get(register, 'data.success')}
        message={_get(register, 'data.message')}
        steps={stepData}
        right={right}
        bottomMessage={
          <span>
            <FormattedMessage id="userRegister.string.alreadyRegistered" />{' '}
            <Link to="users/login">Login</Link>
          </span>
        }
      >
        {steps[this.state.step]}
      </CenteredSegment>
    )
  }
}

export default compose(
  connect(state => ({
    form: state.form,
    register: state.register,
  })),
  injectIntl
)(UserRegister)
