import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'

const BasicSelect = ({
  intl,
  formName = 'form',
  reduxForm,
  common,
  required,
  options,
  width,
}) => (
  <div
    className={classNames('field', width, { required, error: reduxForm.error })}
  >
    <label>
      <FormattedMessage
        id={`${(common && 'common') || formName}.select.${
          reduxForm.name
        }.label`}
      />
    </label>
    <select className="ui dropdown" required={required} {...reduxForm}>
      {options.map(option => (
        <option value={option}>
          {intl.formatMessage({
            id: `${(common && 'common') || formName}.select.${
              reduxForm.name
            }.${option}`,
          })}
        </option>
      ))}
    </select>
    {reduxForm.error && (
      <div className="ui error message">{reduxForm.error}</div>
    )}
  </div>
)

BasicSelect.propTypes = {
  required: PropTypes.bool,
  formName: PropTypes.string,
  reduxForm: PropTypes.object.isRequired,
  width: PropTypes.string,
  options: PropTypes.array.isRequired,
}

export default injectIntl(BasicSelect)
