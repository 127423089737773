import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import rest from '../../../settings/rest'
import { FormattedMessage } from 'react-intl'
import Dropzone from 'react-dropzone'
import CSSModules from 'react-css-modules'
import { SegmentTitle } from '../../common/Helpers'
import { FILE_URL } from '../../../settings/_settings'

import styles from './Welcome.scss'

class Welcome extends React.Component {
  state = {
    file: null,
  }

  componentWillMount() {
    // this.props.dispatch(rest.actions.companyDataInit({ id: this.props.global.login.companyId }));
  }

  _uploadLogo = files => {
    const body = new FormData()
    body.append('file', files[0])

    this.setState({
      file: files[0].preview,
    })
    this.props.dispatch(
      rest.actions.uploadLogo(
        {},
        {
          body,
        }
      )
    )
    this.props.dispatch(
      rest.actions.companyDataInit({ id: this.props.global.login.companyId })
    )
  }

  _resetLogo = () => {
    this.setState({
      file: null,
    })
  }

  render() {
    const {
      company: { sync, data },
    } = this.props

    const hasLogo = sync
    const hasAbout = sync && data.about !== ''

    return (
      <div className="row" styleName="container">
        <div className="column">
          <div className="ui horizontally padded grid">
            <div className="row">
              <SegmentTitle>
                <h1>
                  <FormattedMessage id="welcome.string.title" />
                </h1>
              </SegmentTitle>
            </div>
            <div className="centered stackable row">
              <div
                className="five wide column"
                styleName={hasLogo ? 'finished' : 'fresh'}
              >
                <h2 className="ui top attached header">
                  <span>
                    {hasLogo && <i className="checkmark icon" />}{' '}
                    <FormattedMessage id="welcome.step1.title" />
                  </span>
                </h2>
                <div className="ui attached segment" styleName="step">
                  <p>
                    <FormattedMessage id="welcome.step1.description" />
                  </p>
                  <Dropzone
                    onDrop={this._uploadLogo}
                    className={styles.dropzone}
                    activeClassName={styles.dropzoneActive}
                  >
                    {!this.state.file && !hasLogo && (
                      <FormattedMessage id="welcome.step1.dropzone" />
                    )}
                    {(this.state.file || hasLogo) && (
                      <img
                        className="ui fluid image"
                        src={
                          (sync && `${FILE_URL}/img/logo-${data.id}.png`) ||
                          this.state.file
                        }
                      />
                    )}
                  </Dropzone>
                  <button
                    className="ui fluid secondary icon button"
                    disabled={!this.state.file}
                    onClick={() => this._resetLogo()}
                  >
                    <i className="remove icon" />{' '}
                    <FormattedMessage id="welcome.step1.button" />
                  </button>
                </div>
              </div>
              <div
                className="five wide column"
                styleName={hasAbout ? 'finished' : 'fresh'}
              >
                <h2 className="ui top attached header">
                  <span>
                    {sync && data.about && <i className="checkmark icon" />}{' '}
                    <FormattedMessage id="welcome.step2.title" />
                  </span>
                </h2>
                <div className="ui attached segment" styleName="step">
                  <p>
                    <FormattedMessage id="welcome.step2.description" />
                  </p>
                  <Link
                    className="ui fluid secondary icon button"
                    to="users/account/profile"
                    target="_blank"
                  >
                    <i className="paint brush icon" />{' '}
                    <FormattedMessage id="welcome.step2.button" />
                  </Link>
                </div>
              </div>
              <div className="five wide column">
                <h2 className="ui top attached header">
                  <FormattedMessage id="welcome.step3.title" />
                </h2>
                <div className="ui attached segment" styleName="step">
                  <p>
                    <FormattedMessage id="welcome.step3.description" />
                  </p>
                  <Link
                    className="ui fluid secondary icon button"
                    to="jobs/create"
                    target="_blank"
                  >
                    <i className="plus icon" />{' '}
                    <FormattedMessage id="welcome.step3.button" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default compose(
  connect(state => ({
    company: state.account2.companyData.general,
    global: state.global,
  })),
  CSSModules(styles)
)(Welcome)
