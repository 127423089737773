import PropTypes from 'prop-types';
import React from 'react';
import TranslatedSelect from './TranslatedSelect'
import { FormattedMessage } from 'react-intl'

const LanguageSelect = ({ reduxForm }) => {
  const languages = [
    { id: 'DE', name: 'language.de.name' },
    { id: 'EN', name: 'language.en.name' },
  ]

  return (
    <div className="field">
      <label>
        <FormattedMessage id="language.name" />
      </label>
      <TranslatedSelect
        className="ui dropdown"
        options={languages}
        reduxForm={reduxForm}
      />
    </div>
  )
}

LanguageSelect.propTypes = {}

export default LanguageSelect
