import { useState } from 'react'
import axios from 'axios'

import { API_URL } from '../settings/_settings'
import useCookie from './useCookie'

function useAPI() {
  // get the authorization cookie if existing
  const JWT = useCookie('JWT')

  // initialize basic headers for the JSON api
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json', // preflight request!
  }

  // if an authorization cookie is present, add it to the headers
  if (JWT) {
    headers.Authorization = `Bearer ${JWT}`
  }

  // setup a new instance of axios with the prepared headers
  try {
    return axios.create({ baseURL: `${API_URL}/`, headers })
  } catch (e) {
    return null
  }
}

export default useAPI
