import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import rest from '../../../settings/rest'
import UserGeneral from './UserData/UserGeneral'
import UserPassword from './UserData/UserPassword'
import { SegmentTitle } from '../../common/Helpers'
import { FormattedMessage } from 'react-intl'
import _omitBy from 'lodash/omitBy'
import { compose } from 'recompose'

class UserData extends React.Component {
  componentWillMount() {
    if (!this.props.user.init) {
      // dispatch the get request for the user data
      this.props.dispatch(
        rest.actions.userDataInit({ id: this.props.login.id })
      )
    }
  }

  _updateGeneral = values => {
    const { id } = this.props.login

    const data = _omitBy(
      {
        username: values.email,
        language: values.language,
      },
      it => it === null
    )

    this.props.dispatch(
      rest.actions.userDataGeneral(
        { id },
        {
          body: JSON.stringify(data),
        }
      )
    )

    // if the email of the user account is changed, also change the contact_email of the company (yes, redundancy)
    // TODO: migrate to accountReducer
    if (data.email) {
      this.props.dispatch(
        rest.actions.company.put(
          { id },
          {
            body: JSON.stringify({
              contact_email: data.email,
            }),
          }
        )
      )
    }
  }

  _updatePassword = values => {
    if (
      values.password &&
      values.passwordRepeat &&
      values.password === values.passwordRepeat
    ) {
      this.props.dispatch(
        rest.actions.userDataPassword(
          { id: this.props.login.id },
          {
            body: JSON.stringify({
              password: values.password,
            }),
          }
        )
      )
    }
  }

  render() {
    const {
      login,
      user: { general, password },
    } = this.props

    return (
      <div>
        <SegmentTitle bordered>
          <h2>
            <FormattedMessage id="userAccount.menu.personal" />
          </h2>
        </SegmentTitle>

        {general.sync && !general.loading && (
          <UserGeneral
            displaySuccess={general.displaySuccess}
            displayError={general.displayError}
            onSubmit={this._updateGeneral}
          />
        )}
        {general.loading && <UserGeneral loading />}
        {!login.aai && password.sync && !password.loading && (
          <UserPassword
            displaySuccess={password.displaySuccess}
            displayError={password.displayError}
            onSubmit={this._updatePassword}
          />
        )}
        {!login.aai && password.loading && <UserPassword loading />}
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    login: state.global.login,
    user: state.account2.userData,
  }))
)(UserData)
