import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux'
import { IndexLink, Link } from 'react-router'
import { VerticalMenu } from '../../common/Layouts'

const AdminArea = props => {
  const links = [
    <IndexLink activeClassName="active" className="item" to="/admin/jobs">
      Jobs
    </IndexLink>,
    <Link activeClassName="active" className="item" to="/admin/theses">
      Theses
    </Link>,
    <Link activeClassName="active" className="item" to="/admin/companies">
      Companies
    </Link>,
    <Link activeClassName="active" className="item" to="/admin/users">
      Users
    </Link>,
  ]

  return (
    <VerticalMenu title="Admin area" links={links}>
      {props.children}
    </VerticalMenu>
  )
}

export default connect(state => ({
  global: state.global,
}))(AdminArea)
