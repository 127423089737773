import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl'

const Search = ({ intl, value, updateQuery, intlId }) => (
  <div className="ui fluid icon input">
    <input
      onChange={updateQuery}
      type="text"
      value={value}
      placeholder={intl.formatMessage({ id: intlId })}
    />
    <i className="search icon" />
  </div>
)

Search.propTypes = {
  value: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  intlId: PropTypes.string.isRequired,
}

export default injectIntl(Search)
