import React from 'react'
import { Link } from 'react-router'
import { FormattedMessage } from 'react-intl'

import CSSModules from 'react-css-modules'
import styles from './Footer.scss'

const Footer = () => (
  <footer className="stackable padded two column row">
    <div className="column ui horizontal list">
      <Link className="item" to="/companies">
        <FormattedMessage id="footer.string.companyProfiles" />
      </Link>
      <Link className="item" to="/about/for-companies">
        <FormattedMessage id="footer.string.forCompanies" />
      </Link>
      <Link className="item" to="/about/for-students">
        <FormattedMessage id="footer.string.forStudents" />
      </Link>
      <Link className="item" to="/about/faq">
        <FormattedMessage id="footer.string.faq" />
      </Link>
      <Link className="item" to="/about/contact">
        <FormattedMessage id="footer.string.contact" />
      </Link>
      <Link className="item" to="/about/agb">
        <FormattedMessage id="footer.string.agb" />
      </Link>
    </div>
    <p className="column">
      <FormattedMessage id="footer.string.address1" />
      <br />
      <FormattedMessage id="footer.string.address2" />
    </p>
  </footer>
)

export default CSSModules(Footer, styles)
