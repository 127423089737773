import Cookies from 'js-cookie'

function useCookie(cookieName) {
  try {
    return Cookies.get(cookieName)
  } catch (e) {
    return null
  }
}

export default useCookie
