import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl'
import CSSModules from 'react-css-modules'

import styles from './InfoBarDetailsElement.scss'

const InfoBarDetailsElement = ({
  thesis,
  category,
  education,
  type,
  openFrom,
  minRate,
  maxRate,
  withJob,
  downloadLink,
}) => (
  <div className="ui grid" styleName="container">
    {thesis ? (
      <div className="one column row">
        <div className="column">
          <p>
            <strong>
              <FormattedMessage id="thesisTypes.name.plural" />
            </strong>
            <br />
            <FormattedMessage id={`thesisTypes.${type}.name`} />
          </p>
          <p>
            <strong>
              <FormattedMessage id="withJob.name.plural" />
            </strong>
            <br />
            <FormattedMessage id={`withJob.option.${withJob}`} />
          </p>
          <p styleName="downloadButton">
            <a href={downloadLink} target="_blank">
              <button className="ui secondary fluid icon button">
                <i className="download icon" />{' '}
                <FormattedMessage id="common.button.downloadPDF" />
              </button>
            </a>
          </p>
        </div>
      </div>
    ) : (
      <div className="two column row">
        <div className="column">
          <p>
            <strong>
              <FormattedMessage id="type.name" />
            </strong>
            <br />
            <FormattedMessage id={`type.${type}.name`} />
          </p>
          <p>
            <strong>
              <FormattedMessage id="category.name" />
            </strong>
            <br />
            <FormattedMessage id={`category.${category}.name`} />
          </p>
          <p>
            <strong>
              <FormattedMessage id="education.name" />
            </strong>
            <br />
            <FormattedMessage id={`education.${education}.name`} />
          </p>
        </div>
        <div className="column" styleName="right">
          <p>
            <strong>
              <FormattedMessage id="rate.name" />
            </strong>
            <br />
            {minRate === maxRate ? `${minRate} %` : `${minRate}-${maxRate} %`}
          </p>
          <p>
            <strong>
              <FormattedMessage id="common.string.openFrom.name" />
            </strong>
            <br />
            {openFrom}
          </p>
          <p styleName="downloadButton">
            <a href={downloadLink} target="_blank">
              <button className="ui secondary fluid icon button">
                <i className="download icon" />{' '}
                <FormattedMessage id="common.button.downloadPDF" />
              </button>
            </a>
          </p>
        </div>
      </div>
    )}
  </div>
)

InfoBarDetailsElement.propTypes = {}

export default CSSModules(InfoBarDetailsElement, styles)
